html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1.5;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body * {
    border-style: solid;
}

body {
    direction: rtl;
}

body,
.Toastify *,
pre {
    font-family: "IRANSansX";
    -moz-font-feature-settings: "ss01";
    -webkit-font-feature-settings: "ss01";
    font-feature-settings: "ss01";
}

*::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

input,
textarea,
select,
svg {
    outline: none !important;
}

input {
    min-width: 0;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.ui-clamp-1,
.ui-clamp-2,
.ui-clamp-3,
.ui-clamp-4,
.ui-clamp-5 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ui-clamp-1 {
    line-clamp: 1;
    -webkit-line-clamp: 1;
}

.ui-clamp-2 {
    line-clamp: 2;
    -webkit-line-clamp: 2;
}

.ui-clamp-3 {
    line-clamp: 3;
    -webkit-line-clamp: 3;
}

.ui-clamp-4 {
    line-clamp: 4;
    -webkit-line-clamp: 4;
}

.ui-clamp-5 {
    line-clamp: 5;
    -webkit-line-clamp: 5;
}

body {
    width: 100vw;
    overflow-x: hidden;
}

.ui-iransansX,
.ui-iransansX * {
    font-family: IRANSansX !important;
}

.skeleton {
    background: #eee;
    background: linear-gradient(110deg, #d6d7fd 8%, #ededfa 25%, #d6d7fd 50%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    animation-delay: 2000ms;
}

@keyframes shine {
    to {
        background-position: -200% 100%;
    }
}
