@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?n56jt6');
  src:  url('fonts/icomoon.eot?n56jt6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?n56jt6') format('truetype'),
    url('fonts/icomoon.woff?n56jt6') format('woff'),
    url('fonts/icomoon.svg?n56jt6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-album-collection:before {
  content: "\e900";
}
.icon-arrow-up-from-arc:before {
  content: "\e901";
}
.icon-arrow-up-right-from-square:before {
  content: "\e902";
}
.icon-arrow-up-square-triangle:before {
  content: "\e903";
}
.icon-atom:before {
  content: "\e904";
}
.icon-ban-parking:before {
  content: "\e905";
}
.icon-basket-shopping-minus:before {
  content: "\e906";
}
.icon-basket-shopping-simple:before {
  content: "\e907";
}
.icon-battery-full:before {
  content: "\e908";
}
.icon-bong:before {
  content: "\e909";
}
.icon-bookmark-slash:before {
  content: "\e90a";
}
.icon-book-section:before {
  content: "\e90b";
}
.icon-book-sparkles:before {
  content: "\e90c";
}
.icon-boot:before {
  content: "\e90d";
}
.icon-border-right:before {
  content: "\e90e";
}
.icon-bowl-food:before {
  content: "\e90f";
}
.icon-bowl-rice:before {
  content: "\e910";
}
.icon-bracket-round:before {
  content: "\e911";
}
.icon-braille:before {
  content: "\e912";
}
.icon-brain:before {
  content: "\e913";
}
.icon-brain-circuit:before {
  content: "\e914";
}
.icon-building-shield:before {
  content: "\e915";
}
.icon-buoy-mooring:before {
  content: "\e916";
}
.icon-burger-cheese:before {
  content: "\e917";
}
.icon-bus-school:before {
  content: "\e918";
}
.icon-c:before {
  content: "\e919";
}
.icon-calendar-days:before {
  content: "\e91a";
}
.icon-camera-retro:before {
  content: "\e91b";
}
.icon-capsules:before {
  content: "\e91c";
}
.icon-car-side-bolt:before {
  content: "\e91d";
}
.icon-chart-scatter-3d:before {
  content: "\e91e";
}
.icon-cherries:before {
  content: "\e91f";
}
.icon-child-dress:before {
  content: "\e920";
}
.icon-children:before {
  content: "\e921";
}
.icon-circle-chevron-left:before {
  content: "\e922";
}
.icon-circle-divide:before {
  content: "\e923";
}
.icon-circle-parking:before {
  content: "\e924";
}
.icon-circle-quarters:before {
  content: "\e925";
}
.icon-circle-sort-down:before {
  content: "\e926";
}
.icon-circle-stop:before {
  content: "\e927";
}
.icon-circle-wifi-circle-wifi:before {
  content: "\e928";
}
.icon-circle-z:before {
  content: "\e929";
}
.icon-clock-eight-thirty:before {
  content: "\e92a";
}
.icon-cloud-bolt-sun:before {
  content: "\e92b";
}
.icon-coffee-beans:before {
  content: "\e92c";
}
.icon-coin-blank:before {
  content: "\e92d";
}
.icon-comet:before {
  content: "\e92e";
}
.icon-comment-arrow-up:before {
  content: "\e92f";
}
.icon-comment-sms:before {
  content: "\e930";
}
.icon-diagram-lean-canvas:before {
  content: "\e931";
}
.icon-diagram-project:before {
  content: "\e932";
}
.icon-dial-off:before {
  content: "\e933";
}
.icon-dinosaur:before {
  content: "\e934";
}
.icon-down-left:before {
  content: "\e935";
}
.icon-draw-square:before {
  content: "\e936";
}
.icon-dumpster-fire:before {
  content: "\e937";
}
.icon-eject:before {
  content: "\e938";
}
.icon-ellipsis-vertical:before {
  content: "\e939";
}
.icon-engine:before {
  content: "\e93a";
}
.icon-euro-sign:before {
  content: "\e93b";
}
.icon-eye:before {
  content: "\e93c";
}
.icon-eyes:before {
  content: "\e93d";
}
.icon-face-hand-peeking:before {
  content: "\e93e";
}
.icon-face-meh-blank:before {
  content: "\e93f";
}
.icon-face-thermometer:before {
  content: "\e940";
}
.icon-face-worried:before {
  content: "\e941";
}
.icon-file-jpg:before {
  content: "\e942";
}
.icon-file-plus-minus:before {
  content: "\e943";
}
.icon-file-powerpoint:before {
  content: "\e944";
}
.icon-file-signature:before {
  content: "\e945";
}
.icon-filters:before {
  content: "\e946";
}
.icon-fish-fins:before {
  content: "\e947";
}
.icon-folder-image:before {
  content: "\e948";
}
.icon-folder-music:before {
  content: "\e949";
}
.icon-frame:before {
  content: "\e94a";
}
.icon-gears:before {
  content: "\e94b";
}
.icon-gift:before {
  content: "\e94c";
}
.icon-guitar-electric:before {
  content: "\e94d";
}
.icon-gun-squirt:before {
  content: "\e94e";
}
.icon-h5:before {
  content: "\e94f";
}
.icon-hand-horns:before {
  content: "\e950";
}
.icon-hand-point-left:before {
  content: "\e951";
}
.icon-hands:before {
  content: "\e952";
}
.icon-hand-spock:before {
  content: "\e953";
}
.icon-hat-chef:before {
  content: "\e954";
}
.icon-hat-winter:before {
  content: "\e955";
}
.icon-helmet-battle:before {
  content: "\e956";
}
.icon-house:before {
  content: "\e957";
}
.icon-house-chimney-heart:before {
  content: "\e958";
}
.icon-house-chimney-medical:before {
  content: "\e959";
}
.icon-image-polaroid:before {
  content: "\e95a";
}
.icon-industry:before {
  content: "\e95b";
}
.icon-info:before {
  content: "\e95c";
}
.icon-jug:before {
  content: "\e95d";
}
.icon-key:before {
  content: "\e95e";
}
.icon-keyboard:before {
  content: "\e95f";
}
.icon-landmark-dome:before {
  content: "\e960";
}
.icon-left-from-bracket:before {
  content: "\e961";
}
.icon-lobster:before {
  content: "\e962";
}
.icon-magnifying-glass-music:before {
  content: "\e963";
}
.icon-medal:before {
  content: "\e964";
}
.icon-melon-slice:before {
  content: "\e965";
}
.icon-mill-sign:before {
  content: "\e966";
}
.icon-mug-tea-saucer:before {
  content: "\e967";
}
.icon-music-note:before {
  content: "\e968";
}
.icon-network-wired:before {
  content: "\e969";
}
.icon-paintbrush-fine:before {
  content: "\e96a";
}
.icon-peanuts:before {
  content: "\e96b";
}
.icon-people-group:before {
  content: "\e96c";
}
.icon-person-dolly:before {
  content: "\e96d";
}
.icon-person-dress-simple:before {
  content: "\e96e";
}
.icon-person-harassing:before {
  content: "\e96f";
}
.icon-person-running:before {
  content: "\e970";
}
.icon-person-running-fast:before {
  content: "\e971";
}
.icon-piano:before {
  content: "\e972";
}
.icon-plane-arrival:before {
  content: "\e973";
}
.icon-plane-slash:before {
  content: "\e974";
}
.icon-plate-utensils:before {
  content: "\e975";
}
.icon-plug-circle-minus:before {
  content: "\e976";
}
.icon-plus:before {
  content: "\e977";
}
.icon-poo:before {
  content: "\e978";
}
.icon-pot-food:before {
  content: "\e979";
}
.icon-ram:before {
  content: "\e97a";
}
.icon-reel:before {
  content: "\e97b";
}
.icon-road-bridge:before {
  content: "\e97c";
}
.icon-rss:before {
  content: "\e97d";
}
.icon-school-circle-xmark:before {
  content: "\e97e";
}
.icon-scroll:before {
  content: "\e97f";
}
.icon-share-all:before {
  content: "\e980";
}
.icon-shield-cat:before {
  content: "\e981";
}
.icon-shield-cross:before {
  content: "\e982";
}
.icon-shuttlecock:before {
  content: "\e983";
}
.icon-snowflake:before {
  content: "\e984";
}
.icon-soft-serve:before {
  content: "\e985";
}
.icon-square-caret-down:before {
  content: "\e986";
}
.icon-square-chevron-up:before {
  content: "\e987";
}
.icon-square-code:before {
  content: "\e988";
}
.icon-square-full:before {
  content: "\e989";
}
.icon-square-root-variable:before {
  content: "\e98a";
}
.icon-square-small:before {
  content: "\e98b";
}
.icon-square-t:before {
  content: "\e98c";
}
.icon-stethoscope:before {
  content: "\e98d";
}
.icon-sun:before {
  content: "\e98e";
}
.icon-sword-laser:before {
  content: "\e98f";
}
.icon-tablet-button:before {
  content: "\e990";
}
.icon-tape:before {
  content: "\e991";
}
.icon-tent:before {
  content: "\e992";
}
.icon-torii-gate:before {
  content: "\e993";
}
.icon-traffic-cone:before {
  content: "\e994";
}
.icon-transporter-2:before {
  content: "\e995";
}
.icon-trash-list:before {
  content: "\e996";
}
.icon-tricycle-adult:before {
  content: "\e997";
}
.icon-truck-droplet:before {
  content: "\e998";
}
.icon-user-group:before {
  content: "\e999";
}
.icon-user-group-crown:before {
  content: "\e99a";
}
.icon-user-hair-long:before {
  content: "\e99b";
}
.icon-user-minus:before {
  content: "\e99c";
}
.icon-user-pen:before {
  content: "\e99d";
}
.icon-user-slash:before {
  content: "\e99e";
}
.icon-user-unlock:before {
  content: "\e99f";
}
.icon-value-absolute:before {
  content: "\e9a0";
}
.icon-vector-square:before {
  content: "\e9a1";
}
.icon-video-arrow-down-left:before {
  content: "\e9a2";
}
.icon-vihara:before {
  content: "\e9a3";
}
.icon-volume-high:before {
  content: "\e9a4";
}
.icon-wand-magic:before {
  content: "\e9a5";
}
.icon-wreath-laurel:before {
  content: "\e9a6";
}
.icon-8:before {
  content: "\e9a7";
}
.icon-anchor-circle-check:before {
  content: "\e9a8";
}
.icon-anchor-circle-xmark:before {
  content: "\e9a9";
}
.icon-angles-down:before {
  content: "\e9aa";
}
.icon-angles-left:before {
  content: "\e9ab";
}
.icon-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "\e9ac";
}
.icon-arrow-down-short-wide:before {
  content: "\e9ad";
}
.icon-arrow-down-small-big:before {
  content: "\e9ae";
}
.icon-arrows-repeat-1:before {
  content: "\e9af";
}
.icon-arrow-turn-up:before {
  content: "\e9b0";
}
.icon-arrow-up-from-dotted-line:before {
  content: "\e9b1";
}
.icon-atom-simple:before {
  content: "\e9b2";
}
.icon-avocado:before {
  content: "\e9b3";
}
.icon-baby:before {
  content: "\e9b4";
}
.icon-bacterium:before {
  content: "\e9b5";
}
.icon-bag-shopping:before {
  content: "\e9b6";
}
.icon-barcode-read:before {
  content: "\e9b7";
}
.icon-bars-filter:before {
  content: "\e9b8";
}
.icon-basketball:before {
  content: "\e9b9";
}
.icon-bath:before {
  content: "\e9ba";
}
.icon-battery-empty:before {
  content: "\e9bb";
}
.icon-bell-school:before {
  content: "\e9bc";
}
.icon-bezier-curve:before {
  content: "\e9bd";
}
.icon-book-journal-whills:before {
  content: "\e9be";
}
.icon-boombox:before {
  content: "\e9bf";
}
.icon-border-left:before {
  content: "\e9c0";
}
.icon-bottle-water:before {
  content: "\e9c1";
}
.icon-bowling-ball:before {
  content: "\e9c2";
}
.icon-box-heart:before {
  content: "\e9c3";
}
.icon-brackets-round:before {
  content: "\e9c4";
}
.icon-bread-slice:before {
  content: "\e9c5";
}
.icon-briefcase:before {
  content: "\e9c6";
}
.icon-browsers:before {
  content: "\e9c7";
}
.icon-building-ngo:before {
  content: "\e9c8";
}
.icon-burger-glass:before {
  content: "\e9c9";
}
.icon-bus:before {
  content: "\e9ca";
}
.icon-butter:before {
  content: "\e9cb";
}
.icon-camera-web-slash:before {
  content: "\e9cc";
}
.icon-cannabis:before {
  content: "\e9cd";
}
.icon-car:before {
  content: "\e9ce";
}
.icon-car-building:before {
  content: "\e9cf";
}
.icon-cart-flatbed-suitcase:before {
  content: "\e9d0";
}
.icon-car-tilt:before {
  content: "\e9d1";
}
.icon-cart-shopping:before {
  content: "\e9d2";
}
.icon-chair:before {
  content: "\e9d3";
}
.icon-chess-clock-flip:before {
  content: "\e9d4";
}
.icon-chf-sign:before {
  content: "\e9d5";
}
.icon-circle-book-open:before {
  content: "\e9d6";
}
.icon-circle-caret-left:before {
  content: "\e9d7";
}
.icon-circle-check:before {
  content: "\e9d8";
}
.icon-circle-dot:before {
  content: "\e9d9";
}
.icon-circle-euro:before {
  content: "\e9da";
}
.icon-circle-f:before {
  content: "\e9db";
}
.icon-circle-plus:before {
  content: "\e9dc";
}
.icon-circle-quarter-stroke:before {
  content: "\e9dd";
}
.icon-circle-three-quarters:before {
  content: "\e9de";
}
.icon-circle-x:before {
  content: "\e9df";
}
.icon-clipboard-list:before {
  content: "\e9e0";
}
.icon-clock-nine:before {
  content: "\e9e1";
}
.icon-clock-ten:before {
  content: "\e9e2";
}
.icon-clock-three:before {
  content: "\e9e3";
}
.icon-closed-captioning-slash:before {
  content: "\e9e4";
}
.icon-code:before {
  content: "\e9e5";
}
.icon-code-pull-request:before {
  content: "\e9e6";
}
.icon-comma:before {
  content: "\e9e7";
}
.icon-comment-pen:before {
  content: "\e9e8";
}
.icon-comment-plus:before {
  content: "\e9e9";
}
.icon-crosshairs:before {
  content: "\e9ea";
}
.icon-cup-togo:before {
  content: "\e9eb";
}
.icon-down-left-and-up-right-to-center:before {
  content: "\e9ec";
}
.icon-droplet-degree:before {
  content: "\e9ed";
}
.icon-drumstick-bite:before {
  content: "\e9ee";
}
.icon-dryer-heat:before {
  content: "\e9ef";
}
.icon-dungeon:before {
  content: "\e9f0";
}
.icon-e:before {
  content: "\e9f1";
}
.icon-earth-europe:before {
  content: "\e9f2";
}
.icon-engine-warning:before {
  content: "\e9f3";
}
.icon-envelope:before {
  content: "\e9f4";
}
.icon-envelope-open:before {
  content: "\e9f5";
}
.icon-envelope-open-text:before {
  content: "\e9f6";
}
.icon-expand:before {
  content: "\e9f7";
}
.icon-face-anxious-sweat:before {
  content: "\e9f8";
}
.icon-face-cowboy-hat:before {
  content: "\e9f9";
}
.icon-face-diagonal-mouth:before {
  content: "\e9fa";
}
.icon-face-dotted:before {
  content: "\e9fb";
}
.icon-face-explode:before {
  content: "\e9fc";
}
.icon-face-expressionless:before {
  content: "\e9fd";
}
.icon-face-eyes-xmarks:before {
  content: "\e9fe";
}
.icon-face-lying:before {
  content: "\e9ff";
}
.icon-face-scream:before {
  content: "\ea00";
}
.icon-face-swear:before {
  content: "\ea01";
}
.icon-feather:before {
  content: "\ea02";
}
.icon-file-arrow-down:before {
  content: "\ea03";
}
.icon-file-circle-check:before {
  content: "\ea04";
}
.icon-file-exclamation:before {
  content: "\ea05";
}
.icon-file-png:before {
  content: "\ea06";
}
.icon-files-medical:before {
  content: "\ea07";
}
.icon-file-waveform:before {
  content: "\ea08";
}
.icon-fill-drip:before {
  content: "\ea09";
}
.icon-filter:before {
  content: "\ea0a";
}
.icon-fire-flame-curved:before {
  content: "\ea0b";
}
.icon-flask-gear:before {
  content: "\ea0c";
}
.icon-flask-round-poison:before {
  content: "\ea0d";
}
.icon-flux-capacitor:before {
  content: "\ea0e";
}
.icon-folder-arrow-down:before {
  content: "\ea0f";
}
.icon-folder-heart:before {
  content: "\ea10";
}
.icon-fork:before {
  content: "\ea11";
}
.icon-function:before {
  content: "\ea12";
}
.icon-galaxy:before {
  content: "\ea13";
}
.icon-gift-card:before {
  content: "\ea14";
}
.icon-gifts:before {
  content: "\ea15";
}
.icon-globe-pointer:before {
  content: "\ea16";
}
.icon-goal-net:before {
  content: "\ea17";
}
.icon-golf-ball-tee:before {
  content: "\ea18";
}
.icon-grip-vertical:before {
  content: "\ea19";
}
.icon-hammer:before {
  content: "\ea1a";
}
.icon-hammer-brush:before {
  content: "\ea1b";
}
.icon-handshake-simple:before {
  content: "\ea1c";
}
.icon-hands-holding-heart:before {
  content: "\ea1d";
}
.icon-headset:before {
  content: "\ea1e";
}
.icon-head-side:before {
  content: "\ea1f";
}
.icon-heart-circle-bolt:before {
  content: "\ea20";
}
.icon-hose-reel:before {
  content: "\ea21";
}
.icon-hospital-user:before {
  content: "\ea22";
}
.icon-house-blank:before {
  content: "\ea23";
}
.icon-house-chimney-window:before {
  content: "\ea24";
}
.icon-house-flood-water:before {
  content: "\ea25";
}
.icon-house-flood-water-circle-arrow-right:before {
  content: "\ea26";
}
.icon-hryvnia-sign:before {
  content: "\ea27";
}
.icon-ice-skate:before {
  content: "\ea28";
}
.icon-id-badge:before {
  content: "\ea29";
}
.icon-interrobang:before {
  content: "\ea2a";
}
.icon-j:before {
  content: "\ea2b";
}
.icon-jack-o-lantern:before {
  content: "\ea2c";
}
.icon-jedi:before {
  content: "\ea2d";
}
.icon-kidneys:before {
  content: "\ea2e";
}
.icon-kiwi-fruit:before {
  content: "\ea2f";
}
.icon-l:before {
  content: "\ea30";
}
.icon-lamp-desk:before {
  content: "\ea31";
}
.icon-layer-group:before {
  content: "\ea32";
}
.icon-leaf-heart:before {
  content: "\ea33";
}
.icon-leaf-oak:before {
  content: "\ea34";
}
.icon-less-than:before {
  content: "\ea35";
}
.icon-link-simple-slash:before {
  content: "\ea36";
}
.icon-list-ul:before {
  content: "\ea37";
}
.icon-location-pen:before {
  content: "\ea38";
}
.icon-lock-keyhole-open:before {
  content: "\ea39";
}
.icon-magnifying-glass-minus:before {
  content: "\ea3a";
}
.icon-magnifying-glass-play:before {
  content: "\ea3b";
}
.icon-mailbox-flag-up:before {
  content: "\ea3c";
}
.icon-martini-glass-citrus:before {
  content: "\ea3d";
}
.icon-melon:before {
  content: "\ea3e";
}
.icon-memory:before {
  content: "\ea3f";
}
.icon-message-music:before {
  content: "\ea40";
}
.icon-message-quote:before {
  content: "\ea41";
}
.icon-meter-droplet:before {
  content: "\ea42";
}
.icon-mistletoe:before {
  content: "\ea43";
}
.icon-mobile-button:before {
  content: "\ea44";
}
.icon-mobile-signal:before {
  content: "\ea45";
}
.icon-money-bill-wave:before {
  content: "\ea46";
}
.icon-monument:before {
  content: "\ea47";
}
.icon-moon-over-sun:before {
  content: "\ea48";
}
.icon-n:before {
  content: "\ea49";
}
.icon-nesting-dolls:before {
  content: "\ea4a";
}
.icon-nfc-slash:before {
  content: "\ea4b";
}
.icon-object-group:before {
  content: "\ea4c";
}
.icon-objects-align-top:before {
  content: "\ea4d";
}
.icon-object-subtract:before {
  content: "\ea4e";
}
.icon-octagon-xmark:before {
  content: "\ea4f";
}
.icon-outdent:before {
  content: "\ea50";
}
.icon-pager:before {
  content: "\ea51";
}
.icon-pause:before {
  content: "\ea52";
}
.icon-pencil:before {
  content: "\ea53";
}
.icon-pen-nib:before {
  content: "\ea54";
}
.icon-pen-paintbrush:before {
  content: "\ea55";
}
.icon-pen-ruler:before {
  content: "\ea56";
}
.icon-pen-slash:before {
  content: "\ea57";
}
.icon-people-carry-box:before {
  content: "\ea58";
}
.icon-period:before {
  content: "\ea59";
}
.icon-person-circle-check:before {
  content: "\ea5a";
}
.icon-person-circle-exclamation:before {
  content: "\ea5b";
}
.icon-person-circle-minus:before {
  content: "\ea5c";
}
.icon-person-falling-burst:before {
  content: "\ea5d";
}
.icon-person-military-rifle:before {
  content: "\ea5e";
}
.icon-person-pinball:before {
  content: "\ea5f";
}
.icon-person-shelter:before {
  content: "\ea60";
}
.icon-person-snowmobiling:before {
  content: "\ea61";
}
.icon-person-through-window:before {
  content: "\ea62";
}
.icon-phone-hangup:before {
  content: "\ea63";
}
.icon-pills:before {
  content: "\ea64";
}
.icon-pipe-circle-check:before {
  content: "\ea65";
}
.icon-rectangle-barcode:before {
  content: "\ea66";
}
.icon-rectangle-history:before {
  content: "\ea67";
}
.icon-rectangle-pro:before {
  content: "\ea68";
}
.icon-rectangles-mixed:before {
  content: "\ea69";
}
.icon-right-long-to-line:before {
  content: "\ea6a";
}
.icon-route-interstate:before {
  content: "\ea6b";
}
.icon-scalpel:before {
  content: "\ea6c";
}
.icon-scissors:before {
  content: "\ea6d";
}
.icon-scroll-torah:before {
  content: "\ea6e";
}
.icon-sd-card:before {
  content: "\ea6f";
}
.icon-semicolon:before {
  content: "\ea70";
}
.icon-sensor:before {
  content: "\ea71";
}
.icon-sensor-triangle-exclamation:before {
  content: "\ea72";
}
.icon-shelves:before {
  content: "\ea73";
}
.icon-sigma:before {
  content: "\ea74";
}
.icon-signal-good:before {
  content: "\ea75";
}
.icon-sign-posts:before {
  content: "\ea76";
}
.icon-sim-cards:before {
  content: "\ea77";
}
.icon-sink:before {
  content: "\ea78";
}
.icon-skeleton-ribs:before {
  content: "\ea79";
}
.icon-sliders-simple:before {
  content: "\ea7a";
}
.icon-sliders-up:before {
  content: "\ea7b";
}
.icon-snow-blowing:before {
  content: "\ea7c";
}
.icon-spaghetti-monster-flying:before {
  content: "\ea7d";
}
.icon-spell-check:before {
  content: "\ea7e";
}
.icon-spider-web:before {
  content: "\ea7f";
}
.icon-spinner-third:before {
  content: "\ea80";
}
.icon-square:before {
  content: "\ea81";
}
.icon-square-2:before {
  content: "\ea82";
}
.icon-square-a:before {
  content: "\ea83";
}
.icon-square-arrow-up-left:before {
  content: "\ea84";
}
.icon-square-chevron-left:before {
  content: "\ea85";
}
.icon-square-dashed-circle-plus:before {
  content: "\ea86";
}
.icon-square-dollar:before {
  content: "\ea87";
}
.icon-square-phone-hangup:before {
  content: "\ea88";
}
.icon-square-r:before {
  content: "\ea89";
}
.icon-square-right:before {
  content: "\ea8a";
}
.icon-square-u:before {
  content: "\ea8b";
}
.icon-star-sharp-half-stroke:before {
  content: "\ea8c";
}
.icon-stopwatch-20:before {
  content: "\ea8d";
}
.icon-store-lock:before {
  content: "\ea8e";
}
.icon-street-view:before {
  content: "\ea8f";
}
.icon-sun-bright:before {
  content: "\ea90";
}
.icon-table-rows:before {
  content: "\ea91";
}
.icon-tachograph-digital:before {
  content: "\ea92";
}
.icon-temperature-snow:before {
  content: "\ea93";
}
.icon-tilde:before {
  content: "\ea94";
}
.icon-tire:before {
  content: "\ea95";
}
.icon-toilet-paper-under:before {
  content: "\ea96";
}
.icon-toilets-portable:before {
  content: "\ea97";
}
.icon-toothbrush:before {
  content: "\ea98";
}
.icon-transgender:before {
  content: "\ea99";
}
.icon-trash:before {
  content: "\ea9a";
}
.icon-trash-can-arrow-up:before {
  content: "\ea9b";
}
.icon-trash-clock:before {
  content: "\ea9c";
}
.icon-trash-slash:before {
  content: "\ea9d";
}
.icon-tree-palm:before {
  content: "\ea9e";
}
.icon-truck-fast:before {
  content: "\ea9f";
}
.icon-truck-monster:before {
  content: "\eaa0";
}
.icon-union:before {
  content: "\eaa1";
}
.icon-up-long:before {
  content: "\eaa2";
}
.icon-user-clock:before {
  content: "\eaa3";
}
.icon-user-graduate:before {
  content: "\eaa4";
}
.icon-user-ninja:before {
  content: "\eaa5";
}
.icon-users:before {
  content: "\eaa6";
}
.icon-user-tie-hair:before {
  content: "\eaa7";
}
.icon-vector-circle:before {
  content: "\eaa8";
}
.icon-virus-covid:before {
  content: "\eaa9";
}
.icon-voicemail:before {
  content: "\eaaa";
}
.icon-volume-off:before {
  content: "\eaab";
}
.icon-vr-cardboard:before {
  content: "\eaac";
}
.icon-warehouse-full:before {
  content: "\eaad";
}
.icon-water-arrow-down:before {
  content: "\eaae";
}
.icon-weight-scale:before {
  content: "\eaaf";
}
.icon-whiskey-glass:before {
  content: "\eab0";
}
.icon-whistle:before {
  content: "\eab1";
}
.icon-wifi:before {
  content: "\eab2";
}
.icon-wind:before {
  content: "\eab3";
}
.icon-window-flip:before {
  content: "\eab4";
}
.icon-won-sign:before {
  content: "\eab5";
}
.icon-wreath:before {
  content: "\eab6";
}
.icon-yin-yang:before {
  content: "\eab7";
}
.icon-album:before {
  content: "\eab8";
}
.icon-align-center:before {
  content: "\eab9";
}
.icon-arrow-down-from-arc:before {
  content: "\eaba";
}
.icon-arrow-down-z-a:before {
  content: "\eabb";
}
.icon-arrow-right-long:before {
  content: "\eabc";
}
.icon-arrow-up-arrow-down:before {
  content: "\eabd";
}
.icon-arrow-up-from-square:before {
  content: "\eabe";
}
.icon-arrow-up-right-dots:before {
  content: "\eabf";
}
.icon-austral-sign:before {
  content: "\eac0";
}
.icon-banana:before {
  content: "\eac1";
}
.icon-bangladeshi-taka-sign:before {
  content: "\eac2";
}
.icon-battery-half:before {
  content: "\eac3";
}
.icon-blanket-fire:before {
  content: "\eac4";
}
.icon-block-brick-fire:before {
  content: "\eac5";
}
.icon-bolt-auto:before {
  content: "\eac6";
}
.icon-book-arrow-right:before {
  content: "\eac7";
}
.icon-book-open-cover:before {
  content: "\eac8";
}
.icon-book-user:before {
  content: "\eac9";
}
.icon-border-all:before {
  content: "\eaca";
}
.icon-bow-arrow:before {
  content: "\eacb";
}
.icon-bowl-spoon:before {
  content: "\eacc";
}
.icon-calendar-clock:before {
  content: "\eacd";
}
.icon-calendar-image:before {
  content: "\eace";
}
.icon-camera-cctv:before {
  content: "\eacf";
}
.icon-camera-security:before {
  content: "\ead0";
}
.icon-car-garage:before {
  content: "\ead1";
}
.icon-cart-arrow-down:before {
  content: "\ead2";
}
.icon-car-wrench:before {
  content: "\ead3";
}
.icon-chart-column:before {
  content: "\ead4";
}
.icon-chart-line-up:before {
  content: "\ead5";
}
.icon-chart-pie-simple-circle-dollar:before {
  content: "\ead6";
}
.icon-chess:before {
  content: "\ead7";
}
.icon-chess-clock:before {
  content: "\ead8";
}
.icon-chess-pawn:before {
  content: "\ead9";
}
.icon-circle-ampersand:before {
  content: "\eada";
}
.icon-circle-arrow-up-right:before {
  content: "\eadb";
}
.icon-circle-caret-up:before {
  content: "\eadc";
}
.icon-circle-down-right:before {
  content: "\eadd";
}
.icon-circle-m:before {
  content: "\eade";
}
.icon-circle-microphone:before {
  content: "\eadf";
}
.icon-circle-question:before {
  content: "\eae0";
}
.icon-circle-sort-up:before {
  content: "\eae1";
}
.icon-circle-star:before {
  content: "\eae2";
}
.icon-circle-up-right:before {
  content: "\eae3";
}
.icon-circle-yen:before {
  content: "\eae4";
}
.icon-clapperboard:before {
  content: "\eae5";
}
.icon-clone:before {
  content: "\eae6";
}
.icon-cloud-meatball:before {
  content: "\eae7";
}
.icon-cloud-moon-rain:before {
  content: "\eae8";
}
.icon-cloud-sun:before {
  content: "\eae9";
}
.icon-club:before {
  content: "\eaea";
}
.icon-comment-arrow-down:before {
  content: "\eaeb";
}
.icon-comments-question:before {
  content: "\eaec";
}
.icon-computer-mouse:before {
  content: "\eaed";
}
.icon-computer-mouse-scrollwheel:before {
  content: "\eaee";
}
.icon-couch:before {
  content: "\eaef";
}
.icon-cup-straw-swoosh:before {
  content: "\eaf0";
}
.icon-deer-rudolph:before {
  content: "\eaf1";
}
.icon-democrat:before {
  content: "\eaf2";
}
.icon-diagram-cells:before {
  content: "\eaf3";
}
.icon-dice-two:before {
  content: "\eaf4";
}
.icon-display-chart-up-circle-currency:before {
  content: "\eaf5";
}
.icon-dolly:before {
  content: "\eaf6";
}
.icon-door-closed:before {
  content: "\eaf7";
}
.icon-dreidel:before {
  content: "\eaf8";
}
.icon-drone:before {
  content: "\eaf9";
}
.icon-ear-listen:before {
  content: "\eafa";
}
.icon-ear-muffs:before {
  content: "\eafb";
}
.icon-envelopes:before {
  content: "\eafc";
}
.icon-face-grin-beam-sweat:before {
  content: "\eafd";
}
.icon-face-hushed:before {
  content: "\eafe";
}
.icon-face-meh:before {
  content: "\eaff";
}
.icon-face-nauseated:before {
  content: "\eb00";
}
.icon-face-shush:before {
  content: "\eb01";
}
.icon-face-sunglasses:before {
  content: "\eb02";
}
.icon-ferris-wheel:before {
  content: "\eb03";
}
.icon-file-cad:before {
  content: "\eb04";
}
.icon-file-excel:before {
  content: "\eb05";
}
.icon-file-word:before {
  content: "\eb06";
}
.icon-floppy-disk-circle-xmark:before {
  content: "\eb07";
}
.icon-florin-sign:before {
  content: "\eb08";
}
.icon-folder-check:before {
  content: "\eb09";
}
.icon-football-helmet:before {
  content: "\eb0a";
}
.icon-g:before {
  content: "\eb0b";
}
.icon-glass:before {
  content: "\eb0c";
}
.icon-globe-stand:before {
  content: "\eb0d";
}
.icon-grid-dividers:before {
  content: "\eb0e";
}
.icon-grill-fire:before {
  content: "\eb0f";
}
.icon-hand-back-point-up:before {
  content: "\eb10";
}
.icon-hands-asl-interpreting:before {
  content: "\eb11";
}
.icon-hand-wave:before {
  content: "\eb12";
}
.icon-hat-beach:before {
  content: "\eb13";
}
.icon-headphones-simple:before {
  content: "\eb14";
}
.icon-hexagon-divide:before {
  content: "\eb15";
}
.icon-hexagon-vertical-nft-slanted:before {
  content: "\eb16";
}
.icon-hippo:before {
  content: "\eb17";
}
.icon-hood-cloak:before {
  content: "\eb18";
}
.icon-horse-saddle:before {
  content: "\eb19";
}
.icon-hot-tub-person:before {
  content: "\eb1a";
}
.icon-hourglass:before {
  content: "\eb1b";
}
.icon-house-medical-circle-exclamation:before {
  content: "\eb1c";
}
.icon-hurricane:before {
  content: "\eb1d";
}
.icon-kaaba:before {
  content: "\eb1e";
}
.icon-keyboard-left:before {
  content: "\eb1f";
}
.icon-landmark-magnifying-glass:before {
  content: "\eb20";
}
.icon-laptop-arrow-down:before {
  content: "\eb21";
}
.icon-light-switch:before {
  content: "\eb22";
}
.icon-list-dropdown:before {
  content: "\eb23";
}
.icon-litecoin-sign:before {
  content: "\eb24";
}
.icon-location-xmark:before {
  content: "\eb25";
}
.icon-lungs-virus:before {
  content: "\eb26";
}
.icon-magnifying-glass-arrows-rotate:before {
  content: "\eb27";
}
.icon-magnifying-glass-dollar:before {
  content: "\eb28";
}
.icon-mask-face:before {
  content: "\eb29";
}
.icon-message-arrow-up:before {
  content: "\eb2a";
}
.icon-message-bot:before {
  content: "\eb2b";
}
.icon-message-middle:before {
  content: "\eb2c";
}
.icon-money-bill-transfer:before {
  content: "\eb2d";
}
.icon-nfc-lock:before {
  content: "\eb2e";
}
.icon-object-ungroup:before {
  content: "\eb2f";
}
.icon-onion:before {
  content: "\eb30";
}
.icon-ornament:before {
  content: "\eb31";
}
.icon-pallet:before {
  content: "\eb32";
}
.icon-paw-claws:before {
  content: "\eb33";
}
.icon-pen-fancy-slash:before {
  content: "\eb34";
}
.icon-pepper-hot:before {
  content: "\eb35";
}
.icon-person:before {
  content: "\eb36";
}
.icon-person-digging:before {
  content: "\eb37";
}
.icon-person-fairy:before {
  content: "\eb38";
}
.icon-person-hiking:before {
  content: "\eb39";
}
.icon-person-military-pointing:before {
  content: "\eb3a";
}
.icon-peseta-sign:before {
  content: "\eb3b";
}
.icon-pi:before {
  content: "\eb3c";
}
.icon-pipe:before {
  content: "\eb3d";
}
.icon-plus-large:before {
  content: "\eb3e";
}
.icon-podium:before {
  content: "\eb3f";
}
.icon-pretzel:before {
  content: "\eb40";
}
.icon-quote-left:before {
  content: "\eb41";
}
.icon-raccoon:before {
  content: "\eb42";
}
.icon-record-vinyl:before {
  content: "\eb43";
}
.icon-rectangle-vertical:before {
  content: "\eb44";
}
.icon-reflect-horizontal:before {
  content: "\eb45";
}
.icon-refrigerator:before {
  content: "\eb46";
}
.icon-right-from-bracket:before {
  content: "\eb47";
}
.icon-right-left:before {
  content: "\eb48";
}
.icon-route-highway:before {
  content: "\eb49";
}
.icon-sack-xmark:before {
  content: "\eb4a";
}
.icon-sd-cards:before {
  content: "\eb4b";
}
.icon-shoe-prints:before {
  content: "\eb4c";
}
.icon-shuttle-space:before {
  content: "\eb4d";
}
.icon-signal-weak:before {
  content: "\eb4e";
}
.icon-signature:before {
  content: "\eb4f";
}
.icon-sign-post:before {
  content: "\eb50";
}
.icon-sleigh:before {
  content: "\eb51";
}
.icon-smoking:before {
  content: "\eb52";
}
.icon-sort:before {
  content: "\eb53";
}
.icon-sort-down:before {
  content: "\eb54";
}
.icon-space-station-moon:before {
  content: "\eb55";
}
.icon-speaker:before {
  content: "\eb56";
}
.icon-square-arrow-down:before {
  content: "\eb57";
}
.icon-square-arrow-up:before {
  content: "\eb58";
}
.icon-square-arrow-up-right:before {
  content: "\eb59";
}
.icon-square-b:before {
  content: "\eb5a";
}
.icon-square-down-right:before {
  content: "\eb5b";
}
.icon-square-ellipsis:before {
  content: "\eb5c";
}
.icon-square-exclamation:before {
  content: "\eb5d";
}
.icon-square-person-confined:before {
  content: "\eb5e";
}
.icon-square-quarters:before {
  content: "\eb5f";
}
.icon-square-z:before {
  content: "\eb60";
}
.icon-squirrel:before {
  content: "\eb61";
}
.icon-star-and-crescent:before {
  content: "\eb62";
}
.icon-swords:before {
  content: "\eb63";
}
.icon-table-list:before {
  content: "\eb64";
}
.icon-tablet-rugged:before {
  content: "\eb65";
}
.icon-tally-2:before {
  content: "\eb66";
}
.icon-tent-arrows-down:before {
  content: "\eb67";
}
.icon-tickets-airline:before {
  content: "\eb68";
}
.icon-tombstone-blank:before {
  content: "\eb69";
}
.icon-toolbox:before {
  content: "\eb6a";
}
.icon-trillium:before {
  content: "\eb6b";
}
.icon-truck-field-un:before {
  content: "\eb6c";
}
.icon-trumpet:before {
  content: "\eb6d";
}
.icon-user-doctor-hair:before {
  content: "\eb6e";
}
.icon-user-doctor-hair-long:before {
  content: "\eb6f";
}
.icon-user-large:before {
  content: "\eb70";
}
.icon-user-magnifying-glass:before {
  content: "\eb71";
}
.icon-users-viewfinder:before {
  content: "\eb72";
}
.icon-user-vneck-hair:before {
  content: "\eb73";
}
.icon-user-vneck-hair-long:before {
  content: "\eb74";
}
.icon-video-arrow-up-right:before {
  content: "\eb75";
}
.icon-waves-sine:before {
  content: "\eb76";
}
.icon-whiskey-glass-ice:before {
  content: "\eb77";
}
.icon-wind-turbine:before {
  content: "\eb78";
}
.icon-xmarks-lines:before {
  content: "\eb79";
}
.icon-yen-sign:before {
  content: "\eb7a";
}
.icon-4:before {
  content: "\eb7b";
}
.icon-5:before {
  content: "\eb7c";
}
.icon-air-conditioner:before {
  content: "\eb7d";
}
.icon-alarm-exclamation:before {
  content: "\eb7e";
}
.icon-alt:before {
  content: "\eb7f";
}
.icon-anchor:before {
  content: "\eb80";
}
.icon-angle-right:before {
  content: "\eb81";
}
.icon-arrow-down-big-small:before {
  content: "\eb82";
}
.icon-arrow-down-square-triangle:before {
  content: "\eb83";
}
.icon-arrow-down-up-across-line:before {
  content: "\eb84";
}
.icon-arrow-left:before {
  content: "\eb85";
}
.icon-arrow-right-from-line:before {
  content: "\eb86";
}
.icon-arrows-left-right:before {
  content: "\eb87";
}
.icon-arrows-maximize:before {
  content: "\eb88";
}
.icon-arrows-to-eye:before {
  content: "\eb89";
}
.icon-arrow-turn-right:before {
  content: "\eb8a";
}
.icon-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "\eb8b";
}
.icon-award-simple:before {
  content: "\eb8c";
}
.icon-axe-battle:before {
  content: "\eb8d";
}
.icon-backward-step:before {
  content: "\eb8e";
}
.icon-badge-sheriff:before {
  content: "\eb8f";
}
.icon-bag-shopping-plus:before {
  content: "\eb90";
}
.icon-bat:before {
  content: "\eb91";
}
.icon-battery-three-quarters:before {
  content: "\eb92";
}
.icon-bed-empty:before {
  content: "\eb93";
}
.icon-blinds-raised:before {
  content: "\eb94";
}
.icon-blog:before {
  content: "\eb95";
}
.icon-bolt-slash:before {
  content: "\eb96";
}
.icon-book-bookmark:before {
  content: "\eb97";
}
.icon-book-copy:before {
  content: "\eb98";
}
.icon-book-heart:before {
  content: "\eb99";
}
.icon-books:before {
  content: "\eb9a";
}
.icon-boot-heeled:before {
  content: "\eb9b";
}
.icon-boxing-glove:before {
  content: "\eb9c";
}
.icon-box-tissue:before {
  content: "\eb9d";
}
.icon-bracket-square-right:before {
  content: "\eb9e";
}
.icon-bread-loaf:before {
  content: "\eb9f";
}
.icon-brightness:before {
  content: "\eba0";
}
.icon-broom:before {
  content: "\eba1";
}
.icon-browser:before {
  content: "\eba2";
}
.icon-bug-slash:before {
  content: "\eba3";
}
.icon-building-circle-xmark:before {
  content: "\eba4";
}
.icon-calendar-exclamation:before {
  content: "\eba5";
}
.icon-calendar-pen:before {
  content: "\eba6";
}
.icon-calendar-range:before {
  content: "\eba7";
}
.icon-calendars:before {
  content: "\eba8";
}
.icon-camera:before {
  content: "\eba9";
}
.icon-candle-holder:before {
  content: "\ebaa";
}
.icon-carrot:before {
  content: "\ebab";
}
.icon-cart-shopping-fast:before {
  content: "\ebac";
}
.icon-cassette-betamax:before {
  content: "\ebad";
}
.icon-castle:before {
  content: "\ebae";
}
.icon-chart-line:before {
  content: "\ebaf";
}
.icon-chart-pie:before {
  content: "\ebb0";
}
.icon-chart-pie-simple-circle-currency:before {
  content: "\ebb1";
}
.icon-chart-radar:before {
  content: "\ebb2";
}
.icon-chart-scatter-bubble:before {
  content: "\ebb3";
}
.icon-chevron-down:before {
  content: "\ebb4";
}
.icon-chevrons-down:before {
  content: "\ebb5";
}
.icon-circle-2:before {
  content: "\ebb6";
}
.icon-circle-6:before {
  content: "\ebb7";
}
.icon-circle-arrow-left:before {
  content: "\ebb8";
}
.icon-circle-caret-down:before {
  content: "\ebb9";
}
.icon-circle-down:before {
  content: "\ebba";
}
.icon-circle-n:before {
  content: "\ebbb";
}
.icon-circle-o:before {
  content: "\ebbc";
}
.icon-circle-radiation:before {
  content: "\ebbd";
}
.icon-circle-three-quarters-stroke:before {
  content: "\ebbe";
}
.icon-circle-v:before {
  content: "\ebbf";
}
.icon-circle-wifi:before {
  content: "\ebc0";
}
.icon-citrus-slice:before {
  content: "\ebc1";
}
.icon-clock-four-thirty:before {
  content: "\ebc2";
}
.icon-clock-ten-thirty:before {
  content: "\ebc3";
}
.icon-cloud-drizzle:before {
  content: "\ebc4";
}
.icon-cloud-fog:before {
  content: "\ebc5";
}
.icon-cloud-rainbow:before {
  content: "\ebc6";
}
.icon-comment:before {
  content: "\ebc7";
}
.icon-compass-slash:before {
  content: "\ebc8";
}
.icon-compress:before {
  content: "\ebc9";
}
.icon-cowbell-circle-plus:before {
  content: "\ebca";
}
.icon-crate-apple:before {
  content: "\ebcb";
}
.icon-cruzeiro-sign:before {
  content: "\ebcc";
}
.icon-curling-stone:before {
  content: "\ebcd";
}
.icon-custard:before {
  content: "\ebce";
}
.icon-dagger:before {
  content: "\ebcf";
}
.icon-dash:before {
  content: "\ebd0";
}
.icon-dial-max:before {
  content: "\ebd1";
}
.icon-dice:before {
  content: "\ebd2";
}
.icon-dice-d12:before {
  content: "\ebd3";
}
.icon-display-chart-up-circle-dollar:before {
  content: "\ebd4";
}
.icon-display-code:before {
  content: "\ebd5";
}
.icon-donut:before {
  content: "\ebd6";
}
.icon-down-to-dotted-line:before {
  content: "\ebd7";
}
.icon-droplet-percent:before {
  content: "\ebd8";
}
.icon-dumbbell:before {
  content: "\ebd9";
}
.icon-egg-fried:before {
  content: "\ebda";
}
.icon-ellipsis:before {
  content: "\ebdb";
}
.icon-face-astonished:before {
  content: "\ebdc";
}
.icon-face-clouds:before {
  content: "\ebdd";
}
.icon-face-laugh-wink:before {
  content: "\ebde";
}
.icon-face-monocle:before {
  content: "\ebdf";
}
.icon-face-rolling-eyes:before {
  content: "\ebe0";
}
.icon-face-smile:before {
  content: "\ebe1";
}
.icon-face-smile-relaxed:before {
  content: "\ebe2";
}
.icon-face-smiling-hands:before {
  content: "\ebe3";
}
.icon-face-surprise:before {
  content: "\ebe4";
}
.icon-face-tired:before {
  content: "\ebe5";
}
.icon-file-lock:before {
  content: "\ebe6";
}
.icon-file-medical:before {
  content: "\ebe7";
}
.icon-file-ppt:before {
  content: "\ebe8";
}
.icon-files:before {
  content: "\ebe9";
}
.icon-file-xls:before {
  content: "\ebea";
}
.icon-fireplace:before {
  content: "\ebeb";
}
.icon-fire-smoke:before {
  content: "\ebec";
}
.icon-flag-usa:before {
  content: "\ebed";
}
.icon-flask:before {
  content: "\ebee";
}
.icon-folder-gear:before {
  content: "\ebef";
}
.icon-folders:before {
  content: "\ebf0";
}
.icon-fondue-pot:before {
  content: "\ebf1";
}
.icon-franc-sign:before {
  content: "\ebf2";
}
.icon-gallery-thumbnails:before {
  content: "\ebf3";
}
.icon-garage-open:before {
  content: "\ebf4";
}
.icon-gas-pump-slash:before {
  content: "\ebf5";
}
.icon-gif:before {
  content: "\ebf6";
}
.icon-grate-droplet:before {
  content: "\ebf7";
}
.icon-greater-than:before {
  content: "\ebf8";
}
.icon-grid-round:before {
  content: "\ebf9";
}
.icon-hand:before {
  content: "\ebfa";
}
.icon-handcuffs:before {
  content: "\ebfb";
}
.icon-hand-fingers-crossed:before {
  content: "\ebfc";
}
.icon-hand-fist:before {
  content: "\ebfd";
}
.icon-hand-holding-seedling:before {
  content: "\ebfe";
}
.icon-hand-pointer:before {
  content: "\ebff";
}
.icon-hands-holding-dollar:before {
  content: "\ec00";
}
.icon-heart-circle-xmark:before {
  content: "\ec01";
}
.icon-helmet-un:before {
  content: "\ec02";
}
.icon-hexagon-minus:before {
  content: "\ec03";
}
.icon-high-definition:before {
  content: "\ec04";
}
.icon-hockey-stick-puck:before {
  content: "\ec05";
}
.icon-honey-pot:before {
  content: "\ec06";
}
.icon-hotdog:before {
  content: "\ec07";
}
.icon-hourglass-start:before {
  content: "\ec08";
}
.icon-house-circle-xmark:before {
  content: "\ec09";
}
.icon-house-crack:before {
  content: "\ec0a";
}
.icon-house-day:before {
  content: "\ec0b";
}
.icon-house-lock:before {
  content: "\ec0c";
}
.icon-hundred-points:before {
  content: "\ec0d";
}
.icon-industry-windows:before {
  content: "\ec0e";
}
.icon-input-numeric:before {
  content: "\ec0f";
}
.icon-integral:before {
  content: "\ec10";
}
.icon-jar-wheat:before {
  content: "\ec11";
}
.icon-keyboard-down:before {
  content: "\ec12";
}
.icon-kite:before {
  content: "\ec13";
}
.icon-lamp:before {
  content: "\ec14";
}
.icon-lasso-sparkles:before {
  content: "\ec15";
}
.icon-layer-minus:before {
  content: "\ec16";
}
.icon-left:before {
  content: "\ec17";
}
.icon-left-right:before {
  content: "\ec18";
}
.icon-life-ring:before {
  content: "\ec19";
}
.icon-line-columns:before {
  content: "\ec1a";
}
.icon-lines-leaning:before {
  content: "\ec1b";
}
.icon-list-music:before {
  content: "\ec1c";
}
.icon-list-radio:before {
  content: "\ec1d";
}
.icon-location-dot:before {
  content: "\ec1e";
}
.icon-loveseat:before {
  content: "\ec1f";
}
.icon-magnifying-glass-location:before {
  content: "\ec20";
}
.icon-mandolin:before {
  content: "\ec21";
}
.icon-mars:before {
  content: "\ec22";
}
.icon-mars-and-venus:before {
  content: "\ec23";
}
.icon-mars-double:before {
  content: "\ec24";
}
.icon-mask-ventilator:before {
  content: "\ec25";
}
.icon-memo-pad:before {
  content: "\ec26";
}
.icon-message-lines:before {
  content: "\ec27";
}
.icon-message-pen:before {
  content: "\ec28";
}
.icon-message-text:before {
  content: "\ec29";
}
.icon-meter:before {
  content: "\ec2a";
}
.icon-microphone-slash:before {
  content: "\ec2b";
}
.icon-microphone-stand:before {
  content: "\ec2c";
}
.icon-minimize:before {
  content: "\ec2d";
}
.icon-money-bills:before {
  content: "\ec2e";
}
.icon-monkey:before {
  content: "\ec2f";
}
.icon-mosque:before {
  content: "\ec30";
}
.icon-motorcycle:before {
  content: "\ec31";
}
.icon-mug-marshmallows:before {
  content: "\ec32";
}
.icon-mug-saucer:before {
  content: "\ec33";
}
.icon-nfc-pen:before {
  content: "\ec34";
}
.icon-notdef:before {
  content: "\ec35";
}
.icon-notebook:before {
  content: "\ec36";
}
.icon-octagon-plus:before {
  content: "\ec37";
}
.icon-oil-temperature:before {
  content: "\ec38";
}
.icon-panel-fire:before {
  content: "\ec39";
}
.icon-paragraph-left:before {
  content: "\ec3a";
}
.icon-peace:before {
  content: "\ec3b";
}
.icon-pencil-mechanical:before {
  content: "\ec3c";
}
.icon-pen-circle:before {
  content: "\ec3d";
}
.icon-pen-clip-slash:before {
  content: "\ec3e";
}
.icon-people-pants-simple:before {
  content: "\ec3f";
}
.icon-people-roof:before {
  content: "\ec40";
}
.icon-pepper:before {
  content: "\ec41";
}
.icon-person-booth:before {
  content: "\ec42";
}
.icon-person-dots-from-line:before {
  content: "\ec43";
}
.icon-person-half-dress:before {
  content: "\ec44";
}
.icon-person-rays:before {
  content: "\ec45";
}
.icon-person-rifle:before {
  content: "\ec46";
}
.icon-person-seat:before {
  content: "\ec47";
}
.icon-peso-sign:before {
  content: "\ec48";
}
.icon-phone:before {
  content: "\ec49";
}
.icon-pickaxe:before {
  content: "\ec4a";
}
.icon-planet-moon:before {
  content: "\ec4b";
}
.icon-potato:before {
  content: "\ec4c";
}
.icon-power-off:before {
  content: "\ec4d";
}
.icon-prescription:before {
  content: "\ec4e";
}
.icon-pumpkin:before {
  content: "\ec4f";
}
.icon-puzzle-piece-simple:before {
  content: "\ec50";
}
.icon-rainbow:before {
  content: "\ec51";
}
.icon-ranking-star:before {
  content: "\ec52";
}
.icon-rectangle-list:before {
  content: "\ec53";
}
.icon-reflect-both:before {
  content: "\ec54";
}
.icon-ribbon:before {
  content: "\ec55";
}
.icon-ring-diamond:before {
  content: "\ec56";
}
.icon-road:before {
  content: "\ec57";
}
.icon-robot:before {
  content: "\ec58";
}
.icon-robot-astromech:before {
  content: "\ec59";
}
.icon-router:before {
  content: "\ec5a";
}
.icon-rugby-ball:before {
  content: "\ec5b";
}
.icon-s:before {
  content: "\ec5c";
}
.icon-satellite-dish:before {
  content: "\ec5d";
}
.icon-scale-unbalanced-flip:before {
  content: "\ec5e";
}
.icon-scarecrow:before {
  content: "\ec5f";
}
.icon-school-circle-check:before {
  content: "\ec60";
}
.icon-school-circle-exclamation:before {
  content: "\ec61";
}
.icon-school-flag:before {
  content: "\ec62";
}
.icon-screen-users:before {
  content: "\ec63";
}
.icon-scrubber:before {
  content: "\ec64";
}
.icon-send-back:before {
  content: "\ec65";
}
.icon-shield:before {
  content: "\ec66";
}
.icon-shield-xmark:before {
  content: "\ec67";
}
.icon-signal-bars:before {
  content: "\ec68";
}
.icon-signal-fair:before {
  content: "\ec69";
}
.icon-signal-stream:before {
  content: "\ec6a";
}
.icon-signs-post:before {
  content: "\ec6b";
}
.icon-siren:before {
  content: "\ec6c";
}
.icon-snooze:before {
  content: "\ec6d";
}
.icon-snowflake-droplets:before {
  content: "\ec6e";
}
.icon-spa:before {
  content: "\ec6f";
}
.icon-square-4:before {
  content: "\ec70";
}
.icon-square-arrow-down-right:before {
  content: "\ec71";
}
.icon-square-arrow-left:before {
  content: "\ec72";
}
.icon-square-c:before {
  content: "\ec73";
}
.icon-square-check:before {
  content: "\ec74";
}
.icon-square-plus:before {
  content: "\ec75";
}
.icon-square-up:before {
  content: "\ec76";
}
.icon-square-v:before {
  content: "\ec77";
}
.icon-square-virus:before {
  content: "\ec78";
}
.icon-star:before {
  content: "\ec79";
}
.icon-star-exclamation:before {
  content: "\ec7a";
}
.icon-star-of-life:before {
  content: "\ec7b";
}
.icon-stomach:before {
  content: "\ec7c";
}
.icon-strawberry:before {
  content: "\ec7d";
}
.icon-stretcher:before {
  content: "\ec7e";
}
.icon-sword:before {
  content: "\ec7f";
}
.icon-sword-laser-alt:before {
  content: "\ec80";
}
.icon-table-cells-large:before {
  content: "\ec81";
}
.icon-table-tree:before {
  content: "\ec82";
}
.icon-taxi:before {
  content: "\ec83";
}
.icon-temperature-three-quarters:before {
  content: "\ec84";
}
.icon-tennis-ball:before {
  content: "\ec85";
}
.icon-tents:before {
  content: "\ec86";
}
.icon-text:before {
  content: "\ec87";
}
.icon-theta:before {
  content: "\ec88";
}
.icon-toggle-large-off:before {
  content: "\ec89";
}
.icon-toilet-paper-under-slash:before {
  content: "\ec8a";
}
.icon-traffic-light-go:before {
  content: "\ec8b";
}
.icon-trees:before {
  content: "\ec8c";
}
.icon-triangle-person-digging:before {
  content: "\ec8d";
}
.icon-truck-arrow-right:before {
  content: "\ec8e";
}
.icon-truck-bolt:before {
  content: "\ec8f";
}
.icon-truck-moving:before {
  content: "\ec90";
}
.icon-truck-pickup:before {
  content: "\ec91";
}
.icon-truck-ramp-couch:before {
  content: "\ec92";
}
.icon-turkey:before {
  content: "\ec93";
}
.icon-turn-down-left:before {
  content: "\ec94";
}
.icon-turn-left:before {
  content: "\ec95";
}
.icon-turn-right:before {
  content: "\ec96";
}
.icon-turn-up:before {
  content: "\ec97";
}
.icon-tv:before {
  content: "\ec98";
}
.icon-user-gear:before {
  content: "\ec99";
}
.icon-user-hair:before {
  content: "\ec9a";
}
.icon-user-headset:before {
  content: "\ec9b";
}
.icon-user-robot:before {
  content: "\ec9c";
}
.icon-user-robot-xmarks:before {
  content: "\ec9d";
}
.icon-users-gear:before {
  content: "\ec9e";
}
.icon-users-medical:before {
  content: "\ec9f";
}
.icon-users-rectangle:before {
  content: "\eca0";
}
.icon-user-visor:before {
  content: "\eca1";
}
.icon-utility-pole:before {
  content: "\eca2";
}
.icon-vent-damper:before {
  content: "\eca3";
}
.icon-vial-circle-check:before {
  content: "\eca4";
}
.icon-violin:before {
  content: "\eca5";
}
.icon-volcano:before {
  content: "\eca6";
}
.icon-waffle:before {
  content: "\eca7";
}
.icon-watch-apple:before {
  content: "\eca8";
}
.icon-watch-calculator:before {
  content: "\eca9";
}
.icon-wine-glass-crack:before {
  content: "\ecaa";
}
.icon-wine-glass-empty:before {
  content: "\ecab";
}
.icon-worm:before {
  content: "\ecac";
}
.icon-1:before {
  content: "\ecad";
}
.icon-9:before {
  content: "\ecae";
}
.icon-abacus:before {
  content: "\ecaf";
}
.icon-album-collection-circle-plus:before {
  content: "\ecb0";
}
.icon-alicorn:before {
  content: "\ecb1";
}
.icon-arrow-down-1-9:before {
  content: "\ecb2";
}
.icon-arrow-down-up-lock:before {
  content: "\ecb3";
}
.icon-arrow-left-to-line:before {
  content: "\ecb4";
}
.icon-arrow-pointer:before {
  content: "\ecb5";
}
.icon-arrow-progress:before {
  content: "\ecb6";
}
.icon-arrows-down-to-line:before {
  content: "\ecb7";
}
.icon-arrow-up:before {
  content: "\ecb8";
}
.icon-arrow-up-from-water-pump:before {
  content: "\ecb9";
}
.icon-arrow-up-short-wide:before {
  content: "\ecba";
}
.icon-arrow-up-to-arc:before {
  content: "\ecbb";
}
.icon-audio-description:before {
  content: "\ecbc";
}
.icon-bag-seedling:before {
  content: "\ecbd";
}
.icon-bahai:before {
  content: "\ecbe";
}
.icon-ban:before {
  content: "\ecbf";
}
.icon-bandage:before {
  content: "\ecc0";
}
.icon-bed:before {
  content: "\ecc1";
}
.icon-bells:before {
  content: "\ecc2";
}
.icon-bin-bottles:before {
  content: "\ecc3";
}
.icon-bird:before {
  content: "\ecc4";
}
.icon-blinds:before {
  content: "\ecc5";
}
.icon-block-question:before {
  content: "\ecc6";
}
.icon-blueberries:before {
  content: "\ecc7";
}
.icon-bluetooth:before {
  content: "\ecc8";
}
.icon-book-atlas:before {
  content: "\ecc9";
}
.icon-bookmark:before {
  content: "\ecca";
}
.icon-book-quran:before {
  content: "\eccb";
}
.icon-books-medical:before {
  content: "\eccc";
}
.icon-booth-curtain:before {
  content: "\eccd";
}
.icon-border-bottom:before {
  content: "\ecce";
}
.icon-border-bottom-right:before {
  content: "\eccf";
}
.icon-border-center-h:before {
  content: "\ecd0";
}
.icon-border-top:before {
  content: "\ecd1";
}
.icon-bowling-pins:before {
  content: "\ecd2";
}
.icon-box-check:before {
  content: "\ecd3";
}
.icon-broom-ball:before {
  content: "\ecd4";
}
.icon-building-un:before {
  content: "\ecd5";
}
.icon-bullseye-pointer:before {
  content: "\ecd6";
}
.icon-burrito:before {
  content: "\ecd7";
}
.icon-cactus:before {
  content: "\ecd8";
}
.icon-calculator-simple:before {
  content: "\ecd9";
}
.icon-candy-cane:before {
  content: "\ecda";
}
.icon-car-burst:before {
  content: "\ecdb";
}
.icon-card-heart:before {
  content: "\ecdc";
}
.icon-card-spade:before {
  content: "\ecdd";
}
.icon-car-side:before {
  content: "\ecde";
}
.icon-cart-minus:before {
  content: "\ecdf";
}
.icon-car-wash:before {
  content: "\ece0";
}
.icon-cash-register:before {
  content: "\ece1";
}
.icon-cat:before {
  content: "\ece2";
}
.icon-chalkboard:before {
  content: "\ece3";
}
.icon-chart-mixed:before {
  content: "\ece4";
}
.icon-chart-pie-simple:before {
  content: "\ece5";
}
.icon-cheese-swiss:before {
  content: "\ece6";
}
.icon-chestnut:before {
  content: "\ece7";
}
.icon-chevron-right:before {
  content: "\ece8";
}
.icon-child-combatant:before {
  content: "\ece9";
}
.icon-child-reaching:before {
  content: "\ecea";
}
.icon-circle-0:before {
  content: "\eceb";
}
.icon-circle-9:before {
  content: "\ecec";
}
.icon-circle-arrow-down:before {
  content: "\eced";
}
.icon-circle-chevron-down:before {
  content: "\ecee";
}
.icon-circle-exclamation-check:before {
  content: "\ecef";
}
.icon-circle-location-arrow:before {
  content: "\ecf0";
}
.icon-circle-notch:before {
  content: "\ecf1";
}
.icon-circle-phone:before {
  content: "\ecf2";
}
.icon-circle-r:before {
  content: "\ecf3";
}
.icon-circle-right:before {
  content: "\ecf4";
}
.icon-circle-up-left:before {
  content: "\ecf5";
}
.icon-circle-waveform-lines:before {
  content: "\ecf6";
}
.icon-clock-eleven:before {
  content: "\ecf7";
}
.icon-clock-eleven-thirty:before {
  content: "\ecf8";
}
.icon-cloud-bolt:before {
  content: "\ecf9";
}
.icon-cloud-hail-mixed:before {
  content: "\ecfa";
}
.icon-cloud-minus:before {
  content: "\ecfb";
}
.icon-cloud-plus:before {
  content: "\ecfc";
}
.icon-cloud-showers-heavy:before {
  content: "\ecfd";
}
.icon-cloud-sleet:before {
  content: "\ecfe";
}
.icon-cloud-sun-rain:before {
  content: "\ecff";
}
.icon-code-commit:before {
  content: "\ed00";
}
.icon-code-compare:before {
  content: "\ed01";
}
.icon-colon-sign:before {
  content: "\ed02";
}
.icon-comment-minus:before {
  content: "\ed03";
}
.icon-comment-smile:before {
  content: "\ed04";
}
.icon-computer-speaker:before {
  content: "\ed05";
}
.icon-copyright:before {
  content: "\ed06";
}
.icon-credit-card-blank:before {
  content: "\ed07";
}
.icon-crosshairs-simple:before {
  content: "\ed08";
}
.icon-diagram-subtask:before {
  content: "\ed09";
}
.icon-dial:before {
  content: "\ed0a";
}
.icon-dial-min:before {
  content: "\ed0b";
}
.icon-diamond-half-stroke:before {
  content: "\ed0c";
}
.icon-diamond-turn-right:before {
  content: "\ed0d";
}
.icon-dice-d8:before {
  content: "\ed0e";
}
.icon-dollar-sign:before {
  content: "\ed0f";
}
.icon-down-from-bracket:before {
  content: "\ed10";
}
.icon-down-from-line:before {
  content: "\ed11";
}
.icon-down-long:before {
  content: "\ed12";
}
.icon-draw-polygon:before {
  content: "\ed13";
}
.icon-droplet:before {
  content: "\ed14";
}
.icon-ellipsis-stroke-vertical:before {
  content: "\ed15";
}
.icon-envelopes-bulk:before {
  content: "\ed16";
}
.icon-exclamation:before {
  content: "\ed17";
}
.icon-eye-dropper:before {
  content: "\ed18";
}
.icon-eye-dropper-full:before {
  content: "\ed19";
}
.icon-face-confused:before {
  content: "\ed1a";
}
.icon-face-dizzy:before {
  content: "\ed1b";
}
.icon-face-fearful:before {
  content: "\ed1c";
}
.icon-face-grin-squint-tears:before {
  content: "\ed1d";
}
.icon-face-grin-tongue-wink:before {
  content: "\ed1e";
}
.icon-face-mask:before {
  content: "\ed1f";
}
.icon-face-pleading:before {
  content: "\ed20";
}
.icon-face-sad-cry:before {
  content: "\ed21";
}
.icon-face-sleeping:before {
  content: "\ed22";
}
.icon-face-smile-hearts:before {
  content: "\ed23";
}
.icon-face-spiral-eyes:before {
  content: "\ed24";
}
.icon-fax:before {
  content: "\ed25";
}
.icon-file-binary:before {
  content: "\ed26";
}
.icon-file-certificate:before {
  content: "\ed27";
}
.icon-file-circle-xmark:before {
  content: "\ed28";
}
.icon-file-image:before {
  content: "\ed29";
}
.icon-file-music:before {
  content: "\ed2a";
}
.icon-file-pdf:before {
  content: "\ed2b";
}
.icon-file-prescription:before {
  content: "\ed2c";
}
.icon-file-spreadsheet:before {
  content: "\ed2d";
}
.icon-file-svg:before {
  content: "\ed2e";
}
.icon-file-video:before {
  content: "\ed2f";
}
.icon-file-zip:before {
  content: "\ed30";
}
.icon-filter-slash:before {
  content: "\ed31";
}
.icon-fire-flame:before {
  content: "\ed32";
}
.icon-flag-pennant:before {
  content: "\ed33";
}
.icon-flashlight:before {
  content: "\ed34";
}
.icon-flower:before {
  content: "\ed35";
}
.icon-flute:before {
  content: "\ed36";
}
.icon-folder-magnifying-glass:before {
  content: "\ed37";
}
.icon-folder-open:before {
  content: "\ed38";
}
.icon-folder-tree:before {
  content: "\ed39";
}
.icon-font:before {
  content: "\ed3a";
}
.icon-forklift:before {
  content: "\ed3b";
}
.icon-forward:before {
  content: "\ed3c";
}
.icon-game-console-handheld:before {
  content: "\ed3d";
}
.icon-gas-pump:before {
  content: "\ed3e";
}
.icon-gauge-circle-plus:before {
  content: "\ed3f";
}
.icon-gauge-simple:before {
  content: "\ed40";
}
.icon-gingerbread-man:before {
  content: "\ed41";
}
.icon-glasses-round:before {
  content: "\ed42";
}
.icon-glass-half:before {
  content: "\ed43";
}
.icon-globe-snow:before {
  content: "\ed44";
}
.icon-grapes:before {
  content: "\ed45";
}
.icon-greater-than-equal:before {
  content: "\ed46";
}
.icon-h1:before {
  content: "\ed47";
}
.icon-h2:before {
  content: "\ed48";
}
.icon-hand-dots:before {
  content: "\ed49";
}
.icon-hand-holding-droplet:before {
  content: "\ed4a";
}
.icon-hands-bubbles:before {
  content: "\ed4b";
}
.icon-hat-cowboy-side:before {
  content: "\ed4c";
}
.icon-heading:before {
  content: "\ed4d";
}
.icon-head-side-mask:before {
  content: "\ed4e";
}
.icon-head-side-medical:before {
  content: "\ed4f";
}
.icon-heart-crack:before {
  content: "\ed50";
}
.icon-heart-half-stroke:before {
  content: "\ed51";
}
.icon-helicopter:before {
  content: "\ed52";
}
.icon-helmet-safety:before {
  content: "\ed53";
}
.icon-hexagon-plus:before {
  content: "\ed54";
}
.icon-horse:before {
  content: "\ed55";
}
.icon-horse-head:before {
  content: "\ed56";
}
.icon-hospital:before {
  content: "\ed57";
}
.icon-hotel:before {
  content: "\ed58";
}
.icon-hourglass-half:before {
  content: "\ed59";
}
.icon-house-chimney-user:before {
  content: "\ed5a";
}
.icon-house-medical-circle-xmark:before {
  content: "\ed5b";
}
.icon-house-person-leave:before {
  content: "\ed5c";
}
.icon-house-person-return:before {
  content: "\ed5d";
}
.icon-i:before {
  content: "\ed5e";
}
.icon-id-card:before {
  content: "\ed5f";
}
.icon-inboxes:before {
  content: "\ed60";
}
.icon-indian-rupee-sign:before {
  content: "\ed61";
}
.icon-intersection:before {
  content: "\ed62";
}
.icon-jar:before {
  content: "\ed63";
}
.icon-jet-fighter-up:before {
  content: "\ed64";
}
.icon-jug-bottle:before {
  content: "\ed65";
}
.icon-k:before {
  content: "\ed66";
}
.icon-kerning:before {
  content: "\ed67";
}
.icon-keyboard-brightness-low:before {
  content: "\ed68";
}
.icon-kitchen-set:before {
  content: "\ed69";
}
.icon-lamp-floor:before {
  content: "\ed6a";
}
.icon-lamp-street:before {
  content: "\ed6b";
}
.icon-landmark-flag:before {
  content: "\ed6c";
}
.icon-laptop-code:before {
  content: "\ed6d";
}
.icon-laptop-slash:before {
  content: "\ed6e";
}
.icon-left-to-line:before {
  content: "\ed6f";
}
.icon-lightbulb-exclamation-on:before {
  content: "\ed70";
}
.icon-lightbulb-gear:before {
  content: "\ed71";
}
.icon-light-emergency-on:before {
  content: "\ed72";
}
.icon-link:before {
  content: "\ed73";
}
.icon-list:before {
  content: "\ed74";
}
.icon-location-arrow-up:before {
  content: "\ed75";
}
.icon-location-exclamation:before {
  content: "\ed76";
}
.icon-location-pin:before {
  content: "\ed77";
}
.icon-mailbox:before {
  content: "\ed78";
}
.icon-mango:before {
  content: "\ed79";
}
.icon-marker:before {
  content: "\ed7a";
}
.icon-martini-glass-empty:before {
  content: "\ed7b";
}
.icon-message-captions:before {
  content: "\ed7c";
}
.icon-message-dots:before {
  content: "\ed7d";
}
.icon-message-medical:before {
  content: "\ed7e";
}
.icon-messages:before {
  content: "\ed7f";
}
.icon-messages-dollar:before {
  content: "\ed80";
}
.icon-message-sms:before {
  content: "\ed81";
}
.icon-meter-bolt:before {
  content: "\ed82";
}
.icon-microphone-lines:before {
  content: "\ed83";
}
.icon-mobile:before {
  content: "\ed84";
}
.icon-notes:before {
  content: "\ed85";
}
.icon-pallet-boxes:before {
  content: "\ed86";
}
.icon-paper-plane:before {
  content: "\ed87";
}
.icon-paragraph:before {
  content: "\ed88";
}
.icon-party-horn:before {
  content: "\ed89";
}
.icon-pen-field:before {
  content: "\ed8a";
}
.icon-pen-swirl:before {
  content: "\ed8b";
}
.icon-people-pants:before {
  content: "\ed8c";
}
.icon-person-dress:before {
  content: "\ed8d";
}
.icon-person-praying:before {
  content: "\ed8e";
}
.icon-person-skiing:before {
  content: "\ed8f";
}
.icon-person-ski-lift:before {
  content: "\ed90";
}
.icon-person-walking-arrow-right:before {
  content: "\ed91";
}
.icon-phone-plus:before {
  content: "\ed92";
}
.icon-photo-film-music:before {
  content: "\ed93";
}
.icon-pizza:before {
  content: "\ed94";
}
.icon-plane-circle-xmark:before {
  content: "\ed95";
}
.icon-plant-wilt:before {
  content: "\ed96";
}
.icon-plug-circle-check:before {
  content: "\ed97";
}
.icon-poo-storm:before {
  content: "\ed98";
}
.icon-radio-tuner:before {
  content: "\ed99";
}
.icon-rectangle-xmark:before {
  content: "\ed9a";
}
.icon-repeat:before {
  content: "\ed9b";
}
.icon-republican:before {
  content: "\ed9c";
}
.icon-restroom-simple:before {
  content: "\ed9d";
}
.icon-ruler-triangle:before {
  content: "\ed9e";
}
.icon-ruler-vertical:before {
  content: "\ed9f";
}
.icon-sandwich:before {
  content: "\eda0";
}
.icon-sausage:before {
  content: "\eda1";
}
.icon-saxophone:before {
  content: "\eda2";
}
.icon-saxophone-fire:before {
  content: "\eda3";
}
.icon-screencast:before {
  content: "\eda4";
}
.icon-seal-exclamation:before {
  content: "\eda5";
}
.icon-section:before {
  content: "\eda6";
}
.icon-seedling:before {
  content: "\eda7";
}
.icon-sheet-plastic:before {
  content: "\eda8";
}
.icon-shield-halved:before {
  content: "\eda9";
}
.icon-shirt:before {
  content: "\edaa";
}
.icon-shish-kebab:before {
  content: "\edab";
}
.icon-shovel-snow:before {
  content: "\edac";
}
.icon-sim-card:before {
  content: "\edad";
}
.icon-skull-cow:before {
  content: "\edae";
}
.icon-square-0:before {
  content: "\edaf";
}
.icon-square-1:before {
  content: "\edb0";
}
.icon-square-bolt:before {
  content: "\edb1";
}
.icon-square-d:before {
  content: "\edb2";
}
.icon-square-h:before {
  content: "\edb3";
}
.icon-square-j:before {
  content: "\edb4";
}
.icon-square-k:before {
  content: "\edb5";
}
.icon-square-parking:before {
  content: "\edb6";
}
.icon-square-parking-slash:before {
  content: "\edb7";
}
.icon-square-share-nodes:before {
  content: "\edb8";
}
.icon-square-y:before {
  content: "\edb9";
}
.icon-star-christmas:before {
  content: "\edba";
}
.icon-starfighter-twin-ion-engine-advanced:before {
  content: "\edbb";
}
.icon-star-half-stroke:before {
  content: "\edbc";
}
.icon-starship-freighter:before {
  content: "\edbd";
}
.icon-star-shooting:before {
  content: "\edbe";
}
.icon-stocking:before {
  content: "\edbf";
}
.icon-suitcase-medical:before {
  content: "\edc0";
}
.icon-sunglasses:before {
  content: "\edc1";
}
.icon-swap-arrows:before {
  content: "\edc2";
}
.icon-symbols:before {
  content: "\edc3";
}
.icon-table-cells-column-lock:before {
  content: "\edc4";
}
.icon-table-picnic:before {
  content: "\edc5";
}
.icon-tablet:before {
  content: "\edc6";
}
.icon-tarp-droplet:before {
  content: "\edc7";
}
.icon-taxi-bus:before {
  content: "\edc8";
}
.icon-telescope:before {
  content: "\edc9";
}
.icon-temperature-full:before {
  content: "\edca";
}
.icon-temperature-high:before {
  content: "\edcb";
}
.icon-temperature-sun:before {
  content: "\edcc";
}
.icon-terminal:before {
  content: "\edcd";
}
.icon-text-slash:before {
  content: "\edce";
}
.icon-tick:before {
  content: "\edcf";
}
.icon-ticket-airline:before {
  content: "\edd0";
}
.icon-ticket-simple:before {
  content: "\edd1";
}
.icon-toggle-on:before {
  content: "\edd2";
}
.icon-toilet-paper-blank-under:before {
  content: "\edd3";
}
.icon-traffic-light-slow:before {
  content: "\edd4";
}
.icon-trailer:before {
  content: "\edd5";
}
.icon-train-tunnel:before {
  content: "\edd6";
}
.icon-transporter-6:before {
  content: "\edd7";
}
.icon-trash-can-clock:before {
  content: "\edd8";
}
.icon-tricycle:before {
  content: "\edd9";
}
.icon-truck-clock:before {
  content: "\edda";
}
.icon-truck-container-empty:before {
  content: "\eddb";
}
.icon-truck-ramp-box:before {
  content: "\eddc";
}
.icon-truck-tow:before {
  content: "\eddd";
}
.icon-tty:before {
  content: "\edde";
}
.icon-tv-music:before {
  content: "\eddf";
}
.icon-up-from-line:before {
  content: "\ede0";
}
.icon-user:before {
  content: "\ede1";
}
.icon-user-group-simple:before {
  content: "\ede2";
}
.icon-user-injured:before {
  content: "\ede3";
}
.icon-user-nurse-hair:before {
  content: "\ede4";
}
.icon-user-pilot:before {
  content: "\ede5";
}
.icon-v:before {
  content: "\ede6";
}
.icon-vault:before {
  content: "\ede7";
}
.icon-vest-patches:before {
  content: "\ede8";
}
.icon-video-slash:before {
  content: "\ede9";
}
.icon-volume:before {
  content: "\edea";
}
.icon-walkie-talkie:before {
  content: "\edeb";
}
.icon-watch-fitness:before {
  content: "\edec";
}
.icon-water-ladder:before {
  content: "\eded";
}
.icon-waveform:before {
  content: "\edee";
}
.icon-wave-square:before {
  content: "\edef";
}
.icon-wheat-awn-slash:before {
  content: "\edf0";
}
.icon-wifi-fair:before {
  content: "\edf1";
}
.icon-wifi-slash:before {
  content: "\edf2";
}
.icon-windsock:before {
  content: "\edf3";
}
.icon-y:before {
  content: "\edf4";
}
.icon-apartment:before {
  content: "\edf5";
}
.icon-apple-core:before {
  content: "\edf6";
}
.icon-arrow-down-a-z:before {
  content: "\edf7";
}
.icon-arrow-down-left:before {
  content: "\edf8";
}
.icon-arrow-down-to-line:before {
  content: "\edf9";
}
.icon-arrow-down-triangle-square:before {
  content: "\edfa";
}
.icon-arrow-right-from-arc:before {
  content: "\edfb";
}
.icon-arrows-rotate-reverse:before {
  content: "\edfc";
}
.icon-arrow-up-from-bracket:before {
  content: "\edfd";
}
.icon-arrow-up-right:before {
  content: "\edfe";
}
.icon-arrow-up-to-dotted-line:before {
  content: "\edff";
}
.icon-b:before {
  content: "\ee00";
}
.icon-baguette:before {
  content: "\ee01";
}
.icon-bed-bunk:before {
  content: "\ee02";
}
.icon-bench-tree:before {
  content: "\ee03";
}
.icon-bicycle:before {
  content: "\ee04";
}
.icon-billboard:before {
  content: "\ee05";
}
.icon-binoculars:before {
  content: "\ee06";
}
.icon-book-bible:before {
  content: "\ee07";
}
.icon-book-blank:before {
  content: "\ee08";
}
.icon-bowl-soft-serve:before {
  content: "\ee09";
}
.icon-boxes-packing:before {
  content: "\ee0a";
}
.icon-bracket-curly-right:before {
  content: "\ee0b";
}
.icon-brackets-curly:before {
  content: "\ee0c";
}
.icon-buildings:before {
  content: "\ee0d";
}
.icon-cake-candles:before {
  content: "\ee0e";
}
.icon-camera-movie:before {
  content: "\ee0f";
}
.icon-campground:before {
  content: "\ee10";
}
.icon-caret-up:before {
  content: "\ee11";
}
.icon-car-on:before {
  content: "\ee12";
}
.icon-cart-circle-check:before {
  content: "\ee13";
}
.icon-car-tunnel:before {
  content: "\ee14";
}
.icon-champagne-glasses:before {
  content: "\ee15";
}
.icon-chart-bullet:before {
  content: "\ee16";
}
.icon-chart-line-down:before {
  content: "\ee17";
}
.icon-chess-king-piece:before {
  content: "\ee18";
}
.icon-circle-5:before {
  content: "\ee19";
}
.icon-circle-dashed:before {
  content: "\ee1a";
}
.icon-circle-heart:before {
  content: "\ee1b";
}
.icon-circle-nodes:before {
  content: "\ee1c";
}
.icon-circle-play:before {
  content: "\ee1d";
}
.icon-clock-desk:before {
  content: "\ee1e";
}
.icon-cloud-showers-water:before {
  content: "\ee1f";
}
.icon-cloud-word:before {
  content: "\ee20";
}
.icon-comment-lines:before {
  content: "\ee21";
}
.icon-comment-middle:before {
  content: "\ee22";
}
.icon-comment-quote:before {
  content: "\ee23";
}
.icon-comment-slash:before {
  content: "\ee24";
}
.icon-compass:before {
  content: "\ee25";
}
.icon-compress-wide:before {
  content: "\ee26";
}
.icon-delete-right:before {
  content: "\ee27";
}
.icon-dharmachakra:before {
  content: "\ee28";
}
.icon-diagram-predecessor:before {
  content: "\ee29";
}
.icon-diamond:before {
  content: "\ee2a";
}
.icon-disease:before {
  content: "\ee2b";
}
.icon-dolly-empty:before {
  content: "\ee2c";
}
.icon-eraser:before {
  content: "\ee2d";
}
.icon-face-grin-wide:before {
  content: "\ee2e";
}
.icon-face-laugh:before {
  content: "\ee2f";
}
.icon-face-tissue:before {
  content: "\ee30";
}
.icon-face-unamused:before {
  content: "\ee31";
}
.icon-face-zipper:before {
  content: "\ee32";
}
.icon-ferry:before {
  content: "\ee33";
}
.icon-file:before {
  content: "\ee34";
}
.icon-file-contract:before {
  content: "\ee35";
}
.icon-file-invoice-dollar:before {
  content: "\ee36";
}
.icon-file-user:before {
  content: "\ee37";
}
.icon-flatbread-stuffed:before {
  content: "\ee38";
}
.icon-floppy-disks:before {
  content: "\ee39";
}
.icon-garage:before {
  content: "\ee3a";
}
.icon-gun:before {
  content: "\ee3b";
}
.icon-hamsa:before {
  content: "\ee3c";
}
.icon-hand-love:before {
  content: "\ee3d";
}
.icon-hand-middle-finger:before {
  content: "\ee3e";
}
.icon-hands-praying:before {
  content: "\ee3f";
}
.icon-hard-drive:before {
  content: "\ee40";
}
.icon-head-side-cough:before {
  content: "\ee41";
}
.icon-head-side-virus:before {
  content: "\ee42";
}
.icon-heart:before {
  content: "\ee43";
}
.icon-heart-circle-exclamation:before {
  content: "\ee44";
}
.icon-house-heart:before {
  content: "\ee45";
}
.icon-house-water:before {
  content: "\ee46";
}
.icon-inbox:before {
  content: "\ee47";
}
.icon-indent:before {
  content: "\ee48";
}
.icon-input-pipe:before {
  content: "\ee49";
}
.icon-island-tropical:before {
  content: "\ee4a";
}
.icon-jug-detergent:before {
  content: "\ee4b";
}
.icon-lacrosse-stick-ball:before {
  content: "\ee4c";
}
.icon-language:before {
  content: "\ee4d";
}
.icon-laptop-file:before {
  content: "\ee4e";
}
.icon-lightbulb-cfl:before {
  content: "\ee4f";
}
.icon-location-minus:before {
  content: "\ee50";
}
.icon-lock-a:before {
  content: "\ee51";
}
.icon-meat:before {
  content: "\ee52";
}
.icon-message-exclamation:before {
  content: "\ee53";
}
.icon-messages-question:before {
  content: "\ee54";
}
.icon-nfc-trash:before {
  content: "\ee55";
}
.icon-octagon-check:before {
  content: "\ee56";
}
.icon-outlet:before {
  content: "\ee57";
}
.icon-pan-food:before {
  content: "\ee58";
}
.icon-pencil-slash:before {
  content: "\ee59";
}
.icon-person-biking:before {
  content: "\ee5a";
}
.icon-person-dress-burst:before {
  content: "\ee5b";
}
.icon-person-skiing-nordic:before {
  content: "\ee5c";
}
.icon-phone-flip:before {
  content: "\ee5d";
}
.icon-plane-up:before {
  content: "\ee5e";
}
.icon-plate-wheat:before {
  content: "\ee5f";
}
.icon-play:before {
  content: "\ee60";
}
.icon-pump:before {
  content: "\ee61";
}
.icon-puzzle-piece:before {
  content: "\ee62";
}
.icon-racquet:before {
  content: "\ee63";
}
.icon-raygun:before {
  content: "\ee64";
}
.icon-restroom:before {
  content: "\ee65";
}
.icon-sack-dollar:before {
  content: "\ee66";
}
.icon-sailboat:before {
  content: "\ee67";
}
.icon-scanner-gun:before {
  content: "\ee68";
}
.icon-sheep:before {
  content: "\ee69";
}
.icon-shop-slash:before {
  content: "\ee6a";
}
.icon-shower-down:before {
  content: "\ee6b";
}
.icon-shuffle:before {
  content: "\ee6c";
}
.icon-sidebar-flip:before {
  content: "\ee6d";
}
.icon-signal-stream-slash:before {
  content: "\ee6e";
}
.icon-solar-system:before {
  content: "\ee6f";
}
.icon-square-3:before {
  content: "\ee70";
}
.icon-square-dashed:before {
  content: "\ee71";
}
.icon-square-o:before {
  content: "\ee72";
}
.icon-square-q:before {
  content: "\ee73";
}
.icon-star-half:before {
  content: "\ee74";
}
.icon-stop:before {
  content: "\ee75";
}
.icon-suitcase:before {
  content: "\ee76";
}
.icon-table-cells-row-lock:before {
  content: "\ee77";
}
.icon-tags:before {
  content: "\ee78";
}
.icon-tally-3:before {
  content: "\ee79";
}
.icon-tenge-sign:before {
  content: "\ee7a";
}
.icon-tent-arrow-left-right:before {
  content: "\ee7b";
}
.icon-tickets:before {
  content: "\ee7c";
}
.icon-toggle-large-on:before {
  content: "\ee7d";
}
.icon-toilet-portable:before {
  content: "\ee7e";
}
.icon-train:before {
  content: "\ee7f";
}
.icon-train-subway:before {
  content: "\ee80";
}
.icon-trash-can:before {
  content: "\ee81";
}
.icon-trash-can-slash:before {
  content: "\ee82";
}
.icon-truck-ladder:before {
  content: "\ee83";
}
.icon-turn-down-right:before {
  content: "\ee84";
}
.icon-ufo-beam:before {
  content: "\ee85";
}
.icon-uniform-martial-arts:before {
  content: "\ee86";
}
.icon-user-police:before {
  content: "\ee87";
}
.icon-user-shakespeare:before {
  content: "\ee88";
}
.icon-volume-xmark:before {
  content: "\ee89";
}
.icon-warehouse:before {
  content: "\ee8a";
}
.icon-web-awesome:before {
  content: "\ee8b";
}
.icon-xmark-large:before {
  content: "\ee8c";
}
.icon-7:before {
  content: "\ee8d";
}
.icon-acorn:before {
  content: "\ee8e";
}
.icon-alarm-snooze:before {
  content: "\ee8f";
}
.icon-angle-90:before {
  content: "\ee90";
}
.icon-angles-up:before {
  content: "\ee91";
}
.icon-angles-up-down:before {
  content: "\ee92";
}
.icon-ankh:before {
  content: "\ee93";
}
.icon-aperture:before {
  content: "\ee94";
}
.icon-arrow-down-from-bracket:before {
  content: "\ee95";
}
.icon-arrow-down-from-line:before {
  content: "\ee96";
}
.icon-arrow-down-right:before {
  content: "\ee97";
}
.icon-arrow-left-long-to-line:before {
  content: "\ee98";
}
.icon-arrow-right-from-bracket:before {
  content: "\ee99";
}
.icon-arrow-rotate-right:before {
  content: "\ee9a";
}
.icon-arrows-spin:before {
  content: "\ee9b";
}
.icon-arrows-to-dot:before {
  content: "\ee9c";
}
.icon-arrows-turn-to-dots:before {
  content: "\ee9d";
}
.icon-arrow-turn-left:before {
  content: "\ee9e";
}
.icon-arrow-up-a-z:before {
  content: "\ee9f";
}
.icon-asterisk:before {
  content: "\eea0";
}
.icon-at:before {
  content: "\eea1";
}
.icon-bacon:before {
  content: "\eea2";
}
.icon-badge-dollar:before {
  content: "\eea3";
}
.icon-bagel:before {
  content: "\eea4";
}
.icon-ballot:before {
  content: "\eea5";
}
.icon-bars-progress:before {
  content: "\eea6";
}
.icon-bars-sort:before {
  content: "\eea7";
}
.icon-battery-bolt:before {
  content: "\eea8";
}
.icon-beer-mug-empty:before {
  content: "\eea9";
}
.icon-bin-bottles-recycle:before {
  content: "\eeaa";
}
.icon-bitcoin-sign:before {
  content: "\eeab";
}
.icon-blender:before {
  content: "\eeac";
}
.icon-book-circle-arrow-right:before {
  content: "\eead";
}
.icon-book-circle-arrow-up:before {
  content: "\eeae";
}
.icon-boxes-stacked:before {
  content: "\eeaf";
}
.icon-bracket-curly:before {
  content: "\eeb0";
}
.icon-bracket-round-right:before {
  content: "\eeb1";
}
.icon-brain-arrow-curved-right:before {
  content: "\eeb2";
}
.icon-bridge-circle-exclamation:before {
  content: "\eeb3";
}
.icon-bridge-lock:before {
  content: "\eeb4";
}
.icon-broom-wide:before {
  content: "\eeb5";
}
.icon-bug:before {
  content: "\eeb6";
}
.icon-building-memo:before {
  content: "\eeb7";
}
.icon-burger-fries:before {
  content: "\eeb8";
}
.icon-burger-soda:before {
  content: "\eeb9";
}
.icon-calendar-circle-exclamation:before {
  content: "\eeba";
}
.icon-calendar-lines:before {
  content: "\eebb";
}
.icon-camcorder:before {
  content: "\eebc";
}
.icon-car-circle-bolt:before {
  content: "\eebd";
}
.icon-caret-right:before {
  content: "\eebe";
}
.icon-car-mirrors:before {
  content: "\eebf";
}
.icon-cars:before {
  content: "\eec0";
}
.icon-cart-flatbed:before {
  content: "\eec1";
}
.icon-cart-plus:before {
  content: "\eec2";
}
.icon-cassette-vhs:before {
  content: "\eec3";
}
.icon-chart-line-up-down:before {
  content: "\eec4";
}
.icon-chart-mixed-up-circle-dollar:before {
  content: "\eec5";
}
.icon-chart-waterfall:before {
  content: "\eec6";
}
.icon-chess-bishop:before {
  content: "\eec7";
}
.icon-chess-bishop-piece:before {
  content: "\eec8";
}
.icon-chess-knight:before {
  content: "\eec9";
}
.icon-chevrons-left:before {
  content: "\eeca";
}
.icon-child:before {
  content: "\eecb";
}
.icon-chimney:before {
  content: "\eecc";
}
.icon-circle-4:before {
  content: "\eecd";
}
.icon-circle-arrow-right:before {
  content: "\eece";
}
.icon-circle-c:before {
  content: "\eecf";
}
.icon-circle-e:before {
  content: "\eed0";
}
.icon-circle-gf:before {
  content: "\eed1";
}
.icon-circle-quarter:before {
  content: "\eed2";
}
.icon-circle-sort:before {
  content: "\eed3";
}
.icon-circle-w:before {
  content: "\eed4";
}
.icon-circle-y:before {
  content: "\eed5";
}
.icon-city:before {
  content: "\eed6";
}
.icon-claw-marks:before {
  content: "\eed7";
}
.icon-clipboard-list-check:before {
  content: "\eed8";
}
.icon-cloud-arrow-up:before {
  content: "\eed9";
}
.icon-cloud-bolt-moon:before {
  content: "\eeda";
}
.icon-cloud-hail:before {
  content: "\eedb";
}
.icon-cloud-question:before {
  content: "\eedc";
}
.icon-code-branch:before {
  content: "\eedd";
}
.icon-coffee-bean:before {
  content: "\eede";
}
.icon-coffee-pot:before {
  content: "\eedf";
}
.icon-coin-front:before {
  content: "\eee0";
}
.icon-comment-code:before {
  content: "\eee1";
}
.icon-comment-xmark:before {
  content: "\eee2";
}
.icon-cookie-bite:before {
  content: "\eee3";
}
.icon-court-sport:before {
  content: "\eee4";
}
.icon-cow:before {
  content: "\eee5";
}
.icon-crutches:before {
  content: "\eee6";
}
.icon-cube:before {
  content: "\eee7";
}
.icon-cucumber:before {
  content: "\eee8";
}
.icon-diamond-half:before {
  content: "\eee9";
}
.icon-disc-drive:before {
  content: "\eeea";
}
.icon-dog-leashed:before {
  content: "\eeeb";
}
.icon-down-from-dotted-line:before {
  content: "\eeec";
}
.icon-dryer:before {
  content: "\eeed";
}
.icon-equals:before {
  content: "\eeee";
}
.icon-f:before {
  content: "\eeef";
}
.icon-face-grimace:before {
  content: "\eef0";
}
.icon-face-kiss-wink-heart:before {
  content: "\eef1";
}
.icon-face-melting:before {
  content: "\eef2";
}
.icon-face-nose-steam:before {
  content: "\eef3";
}
.icon-face-sad-tear:before {
  content: "\eef4";
}
.icon-face-smile-plus:before {
  content: "\eef5";
}
.icon-face-vomit:before {
  content: "\eef6";
}
.icon-family:before {
  content: "\eef7";
}
.icon-faucet-drip:before {
  content: "\eef8";
}
.icon-field-hockey-stick-ball:before {
  content: "\eef9";
}
.icon-file-check:before {
  content: "\eefa";
}
.icon-file-eps:before {
  content: "\eefb";
}
.icon-file-import:before {
  content: "\eefc";
}
.icon-file-mp4:before {
  content: "\eefd";
}
.icon-file-plus:before {
  content: "\eefe";
}
.icon-file-xmark:before {
  content: "\eeff";
}
.icon-film-simple:before {
  content: "\ef00";
}
.icon-film-slash:before {
  content: "\ef01";
}
.icon-filter-circle-xmark:before {
  content: "\ef02";
}
.icon-fire-extinguisher:before {
  content: "\ef03";
}
.icon-folder-closed:before {
  content: "\ef04";
}
.icon-folder-grid:before {
  content: "\ef05";
}
.icon-folder-medical:before {
  content: "\ef06";
}
.icon-folder-plus:before {
  content: "\ef07";
}
.icon-font-case:before {
  content: "\ef08";
}
.icon-forward-fast:before {
  content: "\ef09";
}
.icon-forward-step:before {
  content: "\ef0a";
}
.icon-french-fries:before {
  content: "\ef0b";
}
.icon-frog:before {
  content: "\ef0c";
}
.icon-garage-car:before {
  content: "\ef0d";
}
.icon-gauge:before {
  content: "\ef0e";
}
.icon-gavel:before {
  content: "\ef0f";
}
.icon-gear:before {
  content: "\ef10";
}
.icon-ghost:before {
  content: "\ef11";
}
.icon-grill:before {
  content: "\ef12";
}
.icon-h:before {
  content: "\ef13";
}
.icon-hand-holding-hand:before {
  content: "\ef14";
}
.icon-hand-holding-magic:before {
  content: "\ef15";
}
.icon-hands-clapping:before {
  content: "\ef16";
}
.icon-hanukiah:before {
  content: "\ef17";
}
.icon-hat-wizard:before {
  content: "\ef18";
}
.icon-head-side-headphones:before {
  content: "\ef19";
}
.icon-helicopter-symbol:before {
  content: "\ef1a";
}
.icon-hexagon-vertical-nft:before {
  content: "\ef1b";
}
.icon-highlighter-line:before {
  content: "\ef1c";
}
.icon-hospitals:before {
  content: "\ef1d";
}
.icon-house-medical-flag:before {
  content: "\ef1e";
}
.icon-house-turret:before {
  content: "\ef1f";
}
.icon-house-window:before {
  content: "\ef20";
}
.icon-i-cursor:before {
  content: "\ef21";
}
.icon-image-landscape:before {
  content: "\ef22";
}
.icon-images:before {
  content: "\ef23";
}
.icon-inhaler:before {
  content: "\ef24";
}
.icon-jet-fighter:before {
  content: "\ef25";
}
.icon-joystick:before {
  content: "\ef26";
}
.icon-knife-kitchen:before {
  content: "\ef27";
}
.icon-laptop:before {
  content: "\ef28";
}
.icon-laptop-binary:before {
  content: "\ef29";
}
.icon-layer-plus:before {
  content: "\ef2a";
}
.icon-left-to-bracket:before {
  content: "\ef2b";
}
.icon-lightbulb:before {
  content: "\ef2c";
}
.icon-lightbulb-dollar:before {
  content: "\ef2d";
}
.icon-lightbulb-slash:before {
  content: "\ef2e";
}
.icon-light-emergency:before {
  content: "\ef2f";
}
.icon-lights-holiday:before {
  content: "\ef30";
}
.icon-light-switch-on:before {
  content: "\ef31";
}
.icon-line-height:before {
  content: "\ef32";
}
.icon-list-timeline:before {
  content: "\ef33";
}
.icon-loader:before {
  content: "\ef34";
}
.icon-location-question:before {
  content: "\ef35";
}
.icon-lock-open:before {
  content: "\ef36";
}
.icon-manhole:before {
  content: "\ef37";
}
.icon-mars-stroke-right:before {
  content: "\ef38";
}
.icon-martini-glass:before {
  content: "\ef39";
}
.icon-memo-circle-info:before {
  content: "\ef3a";
}
.icon-menorah:before {
  content: "\ef3b";
}
.icon-message:before {
  content: "\ef3c";
}
.icon-message-middle-top:before {
  content: "\ef3d";
}
.icon-message-xmark:before {
  content: "\ef3e";
}
.icon-meter-fire:before {
  content: "\ef3f";
}
.icon-microscope:before {
  content: "\ef40";
}
.icon-minus:before {
  content: "\ef41";
}
.icon-money-check-dollar:before {
  content: "\ef42";
}
.icon-moon:before {
  content: "\ef43";
}
.icon-moon-cloud:before {
  content: "\ef44";
}
.icon-mosquito:before {
  content: "\ef45";
}
.icon-mound:before {
  content: "\ef46";
}
.icon-mug:before {
  content: "\ef47";
}
.icon-mug-hot:before {
  content: "\ef48";
}
.icon-note-sticky:before {
  content: "\ef49";
}
.icon-objects-align-center-horizontal:before {
  content: "\ef4a";
}
.icon-pan-frying:before {
  content: "\ef4b";
}
.icon-parachute-box:before {
  content: "\ef4c";
}
.icon-party-bell:before {
  content: "\ef4d";
}
.icon-passport:before {
  content: "\ef4e";
}
.icon-paste:before {
  content: "\ef4f";
}
.icon-people-dress-simple:before {
  content: "\ef50";
}
.icon-person-arrow-down-to-line:before {
  content: "\ef51";
}
.icon-person-arrow-up-from-line:before {
  content: "\ef52";
}
.icon-person-biking-mountain:before {
  content: "\ef53";
}
.icon-person-circle-plus:before {
  content: "\ef54";
}
.icon-person-simple:before {
  content: "\ef55";
}
.icon-person-to-door:before {
  content: "\ef56";
}
.icon-person-walking:before {
  content: "\ef57";
}
.icon-person-walking-with-cane:before {
  content: "\ef58";
}
.icon-phone-missed:before {
  content: "\ef59";
}
.icon-plane-engines:before {
  content: "\ef5a";
}
.icon-pool-8-ball:before {
  content: "\ef5b";
}
.icon-question:before {
  content: "\ef5c";
}
.icon-rectangle:before {
  content: "\ef5d";
}
.icon-rectangle-ad:before {
  content: "\ef5e";
}
.icon-rectangle-history-circle-user:before {
  content: "\ef5f";
}
.icon-reflect-vertical:before {
  content: "\ef60";
}
.icon-repeat-1:before {
  content: "\ef61";
}
.icon-reply:before {
  content: "\ef62";
}
.icon-reply-clock:before {
  content: "\ef63";
}
.icon-right:before {
  content: "\ef64";
}
.icon-right-left-large:before {
  content: "\ef65";
}
.icon-ring:before {
  content: "\ef66";
}
.icon-rings-wedding:before {
  content: "\ef67";
}
.icon-rocket:before {
  content: "\ef68";
}
.icon-rotate-exclamation:before {
  content: "\ef69";
}
.icon-rupiah-sign:before {
  content: "\ef6a";
}
.icon-satellite:before {
  content: "\ef6b";
}
.icon-school-lock:before {
  content: "\ef6c";
}
.icon-scribble:before {
  content: "\ef6d";
}
.icon-sensor-fire:before {
  content: "\ef6e";
}
.icon-server:before {
  content: "\ef6f";
}
.icon-shield-exclamation:before {
  content: "\ef70";
}
.icon-shield-minus:before {
  content: "\ef71";
}
.icon-shield-plus:before {
  content: "\ef72";
}
.icon-shower:before {
  content: "\ef73";
}
.icon-sidebar:before {
  content: "\ef74";
}
.icon-sign-hanging:before {
  content: "\ef75";
}
.icon-smoke:before {
  content: "\ef76";
}
.icon-snake:before {
  content: "\ef77";
}
.icon-solar-panel:before {
  content: "\ef78";
}
.icon-sparkles:before {
  content: "\ef79";
}
.icon-spider:before {
  content: "\ef7a";
}
.icon-spray-can:before {
  content: "\ef7b";
}
.icon-square-f:before {
  content: "\ef7c";
}
.icon-square-g:before {
  content: "\ef7d";
}
.icon-square-heart:before {
  content: "\ef7e";
}
.icon-square-left:before {
  content: "\ef7f";
}
.icon-square-pen:before {
  content: "\ef80";
}
.icon-square-rss:before {
  content: "\ef81";
}
.icon-square-this-way-up:before {
  content: "\ef82";
}
.icon-starship:before {
  content: "\ef83";
}
.icon-sun-plant-wilt:before {
  content: "\ef84";
}
.icon-t:before {
  content: "\ef85";
}
.icon-table-cells:before {
  content: "\ef86";
}
.icon-tablet-screen:before {
  content: "\ef87";
}
.icon-tablet-screen-button:before {
  content: "\ef88";
}
.icon-taco:before {
  content: "\ef89";
}
.icon-teddy-bear:before {
  content: "\ef8a";
}
.icon-temperature-empty:before {
  content: "\ef8b";
}
.icon-temperature-half:before {
  content: "\ef8c";
}
.icon-tent-double-peak:before {
  content: "\ef8d";
}
.icon-thermometer:before {
  content: "\ef8e";
}
.icon-tire-flat:before {
  content: "\ef8f";
}
.icon-toilet-paper-blank:before {
  content: "\ef90";
}
.icon-tombstone:before {
  content: "\ef91";
}
.icon-tower-control:before {
  content: "\ef92";
}
.icon-tower-observation:before {
  content: "\ef93";
}
.icon-train-subway-tunnel:before {
  content: "\ef94";
}
.icon-transporter:before {
  content: "\ef95";
}
.icon-trash-can-check:before {
  content: "\ef96";
}
.icon-trash-check:before {
  content: "\ef97";
}
.icon-trash-plus:before {
  content: "\ef98";
}
.icon-treasure-chest:before {
  content: "\ef99";
}
.icon-t-rex:before {
  content: "\ef9a";
}
.icon-truck-container:before {
  content: "\ef9b";
}
.icon-truck-fire:before {
  content: "\ef9c";
}
.icon-truck-flatbed:before {
  content: "\ef9d";
}
.icon-tty-answer:before {
  content: "\ef9e";
}
.icon-turkish-lira-sign:before {
  content: "\ef9f";
}
.icon-turn-down:before {
  content: "\efa0";
}
.icon-turn-left-down:before {
  content: "\efa1";
}
.icon-u:before {
  content: "\efa2";
}
.icon-up-from-dotted-line:before {
  content: "\efa3";
}
.icon-up-left:before {
  content: "\efa4";
}
.icon-user-bounty-hunter:before {
  content: "\efa5";
}
.icon-user-hair-buns:before {
  content: "\efa6";
}
.icon-user-plus:before {
  content: "\efa7";
}
.icon-user-secret:before {
  content: "\efa8";
}
.icon-users-line:before {
  content: "\efa9";
}
.icon-utility-pole-double:before {
  content: "\efaa";
}
.icon-video:before {
  content: "\efab";
}
.icon-virus-covid-slash:before {
  content: "\efac";
}
.icon-watermelon-slice:before {
  content: "\efad";
}
.icon-wine-glass:before {
  content: "\efae";
}
.icon-x-ray:before {
  content: "\efaf";
}
.icon-airplay:before {
  content: "\efb0";
}
.icon-alarm-plus:before {
  content: "\efb1";
}
.icon-album-circle-user:before {
  content: "\efb2";
}
.icon-align-left:before {
  content: "\efb3";
}
.icon-align-right:before {
  content: "\efb4";
}
.icon-angle:before {
  content: "\efb5";
}
.icon-angle-down:before {
  content: "\efb6";
}
.icon-angle-up:before {
  content: "\efb7";
}
.icon-arrow-down-to-bracket:before {
  content: "\efb8";
}
.icon-arrow-down-to-dotted-line:before {
  content: "\efb9";
}
.icon-arrow-left-from-line:before {
  content: "\efba";
}
.icon-arrow-left-to-arc:before {
  content: "\efbb";
}
.icon-arrow-right-to-line:before {
  content: "\efbc";
}
.icon-arrows-from-dotted-line:before {
  content: "\efbd";
}
.icon-arrows-retweet:before {
  content: "\efbe";
}
.icon-arrows-to-line:before {
  content: "\efbf";
}
.icon-arrow-trend-down:before {
  content: "\efc0";
}
.icon-arrow-trend-up:before {
  content: "\efc1";
}
.icon-arrow-up-long:before {
  content: "\efc2";
}
.icon-arrow-up-to-bracket:before {
  content: "\efc3";
}
.icon-arrow-up-triangle-square:before {
  content: "\efc4";
}
.icon-arrow-up-wide-short:before {
  content: "\efc5";
}
.icon-award:before {
  content: "\efc6";
}
.icon-bacteria:before {
  content: "\efc7";
}
.icon-badge-check:before {
  content: "\efc8";
}
.icon-bag-shopping-minus:before {
  content: "\efc9";
}
.icon-balloon:before {
  content: "\efca";
}
.icon-bars:before {
  content: "\efcb";
}
.icon-baseball:before {
  content: "\efcc";
}
.icon-battery-exclamation:before {
  content: "\efcd";
}
.icon-beer-mug:before {
  content: "\efce";
}
.icon-bell:before {
  content: "\efcf";
}
.icon-binary:before {
  content: "\efd0";
}
.icon-blanket:before {
  content: "\efd1";
}
.icon-blender-phone:before {
  content: "\efd2";
}
.icon-block:before {
  content: "\efd3";
}
.icon-bolt-lightning:before {
  content: "\efd4";
}
.icon-bone:before {
  content: "\efd5";
}
.icon-bone-break:before {
  content: "\efd6";
}
.icon-book-open:before {
  content: "\efd7";
}
.icon-book-skull:before {
  content: "\efd8";
}
.icon-border-none:before {
  content: "\efd9";
}
.icon-bottle-droplet:before {
  content: "\efda";
}
.icon-bowl-chopsticks-noodles:before {
  content: "\efdb";
}
.icon-bowl-hot:before {
  content: "\efdc";
}
.icon-bowling-ball-pin:before {
  content: "\efdd";
}
.icon-box:before {
  content: "\efde";
}
.icon-box-archive:before {
  content: "\efdf";
}
.icon-box-circle-check:before {
  content: "\efe0";
}
.icon-box-open:before {
  content: "\efe1";
}
.icon-box-open-full:before {
  content: "\efe2";
}
.icon-box-taped:before {
  content: "\efe3";
}
.icon-bridge:before {
  content: "\efe4";
}
.icon-briefcase-blank:before {
  content: "\efe5";
}
.icon-brush:before {
  content: "\efe6";
}
.icon-building-lock:before {
  content: "\efe7";
}
.icon-bulldozer:before {
  content: "\efe8";
}
.icon-buoy:before {
  content: "\efe9";
}
.icon-burger:before {
  content: "\efea";
}
.icon-business-time:before {
  content: "\efeb";
}
.icon-calculator:before {
  content: "\efec";
}
.icon-calendar:before {
  content: "\efed";
}
.icon-calendar-circle-minus:before {
  content: "\efee";
}
.icon-calendar-circle-plus:before {
  content: "\efef";
}
.icon-calendar-week:before {
  content: "\eff0";
}
.icon-camera-slash:before {
  content: "\eff1";
}
.icon-campfire:before {
  content: "\eff2";
}
.icon-candy-bar:before {
  content: "\eff3";
}
.icon-cannon:before {
  content: "\eff4";
}
.icon-car-rear:before {
  content: "\eff5";
}
.icon-cart-circle-arrow-down:before {
  content: "\eff6";
}
.icon-cart-circle-exclamation:before {
  content: "\eff7";
}
.icon-cart-flatbed-boxes:before {
  content: "\eff8";
}
.icon-cent-sign:before {
  content: "\eff9";
}
.icon-chart-kanban:before {
  content: "\effa";
}
.icon-chart-tree-map:before {
  content: "\effb";
}
.icon-circle-1:before {
  content: "\effc";
}
.icon-circle-b:before {
  content: "\effd";
}
.icon-circle-camera:before {
  content: "\effe";
}
.icon-circle-dollar-to-slot:before {
  content: "\efff";
}
.icon-circle-ellipsis-vertical:before {
  content: "\f000";
}
.icon-circle-exclamation:before {
  content: "\f001";
}
.icon-circle-j:before {
  content: "\f002";
}
.icon-circle-minus:before {
  content: "\f003";
}
.icon-circle-s:before {
  content: "\f004";
}
.icon-circles-overlap:before {
  content: "\f005";
}
.icon-circle-user:before {
  content: "\f006";
}
.icon-clapperboard-play:before {
  content: "\f007";
}
.icon-clipboard-prescription:before {
  content: "\f008";
}
.icon-clock-seven-thirty:before {
  content: "\f009";
}
.icon-clock-twelve:before {
  content: "\f00a";
}
.icon-clock-twelve-thirty:before {
  content: "\f00b";
}
.icon-cloud:before {
  content: "\f00c";
}
.icon-code-simple:before {
  content: "\f00d";
}
.icon-comment-medical:before {
  content: "\f00e";
}
.icon-comment-music:before {
  content: "\f00f";
}
.icon-compact-disc:before {
  content: "\f010";
}
.icon-compass-drafting:before {
  content: "\f011";
}
.icon-conveyor-belt-empty:before {
  content: "\f012";
}
.icon-cupcake:before {
  content: "\f013";
}
.icon-d:before {
  content: "\f014";
}
.icon-diagram-previous:before {
  content: "\f015";
}
.icon-dial-high:before {
  content: "\f016";
}
.icon-dial-med:before {
  content: "\f017";
}
.icon-dice-d4:before {
  content: "\f018";
}
.icon-dice-d20:before {
  content: "\f019";
}
.icon-dice-three:before {
  content: "\f01a";
}
.icon-distribute-spacing-vertical:before {
  content: "\f01b";
}
.icon-divide:before {
  content: "\f01c";
}
.icon-do-not-enter:before {
  content: "\f01d";
}
.icon-down-to-line:before {
  content: "\f01e";
}
.icon-drum-steelpan:before {
  content: "\f01f";
}
.icon-dumpster:before {
  content: "\f020";
}
.icon-egg:before {
  content: "\f021";
}
.icon-elevator:before {
  content: "\f022";
}
.icon-empty-set:before {
  content: "\f023";
}
.icon-expand-wide:before {
  content: "\f024";
}
.icon-explosion:before {
  content: "\f025";
}
.icon-eye-slash:before {
  content: "\f026";
}
.icon-face-disappointed:before {
  content: "\f027";
}
.icon-face-exhaling:before {
  content: "\f028";
}
.icon-face-frown-slight:before {
  content: "\f029";
}
.icon-face-grin:before {
  content: "\f02a";
}
.icon-face-sad-sweat:before {
  content: "\f02b";
}
.icon-face-smile-tongue:before {
  content: "\f02c";
}
.icon-face-woozy:before {
  content: "\f02d";
}
.icon-fence:before {
  content: "\f02e";
}
.icon-file-circle-minus:before {
  content: "\f02f";
}
.icon-file-pen:before {
  content: "\f030";
}
.icon-film:before {
  content: "\f031";
}
.icon-films:before {
  content: "\f032";
}
.icon-filter-circle-dollar:before {
  content: "\f033";
}
.icon-fire-flame-simple:before {
  content: "\f034";
}
.icon-fire-hydrant:before {
  content: "\f035";
}
.icon-flag-swallowtail:before {
  content: "\f036";
}
.icon-floppy-disk-pen:before {
  content: "\f037";
}
.icon-folder-arrow-up:before {
  content: "\f038";
}
.icon-folder-user:before {
  content: "\f039";
}
.icon-font-awesome:before {
  content: "\f03a";
}
.icon-fork-knife:before {
  content: "\f03b";
}
.icon-game-board:before {
  content: "\f03c";
}
.icon-gamepad-modern:before {
  content: "\f03d";
}
.icon-gauge-simple-high:before {
  content: "\f03e";
}
.icon-gauge-simple-low:before {
  content: "\f03f";
}
.icon-glass-water-droplet:before {
  content: "\f040";
}
.icon-grid-2:before {
  content: "\f041";
}
.icon-grip-lines-vertical:before {
  content: "\f042";
}
.icon-guitar:before {
  content: "\f043";
}
.icon-guitars:before {
  content: "\f044";
}
.icon-hand-back-point-ribbon:before {
  content: "\f045";
}
.icon-hand-heart:before {
  content: "\f046";
}
.icon-hand-holding:before {
  content: "\f047";
}
.icon-hand-holding-medical:before {
  content: "\f048";
}
.icon-hand-point-ribbon:before {
  content: "\f049";
}
.icon-handshake-angle:before {
  content: "\f04a";
}
.icon-hands-holding-circle:before {
  content: "\f04b";
}
.icon-hand-sparkles:before {
  content: "\f04c";
}
.icon-hat-cowboy:before {
  content: "\f04d";
}
.icon-head-side-cough-slash:before {
  content: "\f04e";
}
.icon-head-side-heart:before {
  content: "\f04f";
}
.icon-heart-circle-check:before {
  content: "\f050";
}
.icon-hexagon-image:before {
  content: "\f051";
}
.icon-hexagon-xmark:before {
  content: "\f052";
}
.icon-hockey-puck:before {
  content: "\f053";
}
.icon-house-fire:before {
  content: "\f054";
}
.icon-house-night:before {
  content: "\f055";
}
.icon-ice-cream:before {
  content: "\f056";
}
.icon-image-user:before {
  content: "\f057";
}
.icon-joint:before {
  content: "\f058";
}
.icon-lacrosse-stick:before {
  content: "\f059";
}
.icon-lasso:before {
  content: "\f05a";
}
.icon-left-long-to-line:before {
  content: "\f05b";
}
.icon-lightbulb-cfl-on:before {
  content: "\f05c";
}
.icon-lira-sign:before {
  content: "\f05d";
}
.icon-list-check:before {
  content: "\f05e";
}
.icon-location-pin-lock:before {
  content: "\f05f";
}
.icon-lock-hashtag:before {
  content: "\f060";
}
.icon-lungs:before {
  content: "\f061";
}
.icon-magnifying-glass-plus:before {
  content: "\f062";
}
.icon-map-location:before {
  content: "\f063";
}
.icon-mercury:before {
  content: "\f064";
}
.icon-message-arrow-down:before {
  content: "\f065";
}
.icon-message-check:before {
  content: "\f066";
}
.icon-microchip:before {
  content: "\f067";
}
.icon-microphone:before {
  content: "\f068";
}
.icon-mitten:before {
  content: "\f069";
}
.icon-mobile-notch:before {
  content: "\f06a";
}
.icon-mobile-retro:before {
  content: "\f06b";
}
.icon-money-bills-simple:before {
  content: "\f06c";
}
.icon-money-check:before {
  content: "\f06d";
}
.icon-moped:before {
  content: "\f06e";
}
.icon-music-magnifying-glass:before {
  content: "\f06f";
}
.icon-nfc-symbol:before {
  content: "\f070";
}
.icon-o:before {
  content: "\f071";
}
.icon-object-exclude:before {
  content: "\f072";
}
.icon-objects-align-left:before {
  content: "\f073";
}
.icon-page:before {
  content: "\f074";
}
.icon-page-caret-up:before {
  content: "\f075";
}
.icon-paintbrush:before {
  content: "\f076";
}
.icon-paperclip:before {
  content: "\f077";
}
.icon-paperclip-vertical:before {
  content: "\f078";
}
.icon-peapod:before {
  content: "\f079";
}
.icon-pear:before {
  content: "\f07a";
}
.icon-pen-nib-slash:before {
  content: "\f07b";
}
.icon-people-arrows:before {
  content: "\f07c";
}
.icon-people-robbery:before {
  content: "\f07d";
}
.icon-percent:before {
  content: "\f07e";
}
.icon-person-dolly-empty:before {
  content: "\f07f";
}
.icon-person-snowboarding:before {
  content: "\f080";
}
.icon-phone-volume:before {
  content: "\f081";
}
.icon-pickleball:before {
  content: "\f082";
}
.icon-pipe-collar:before {
  content: "\f083";
}
.icon-play-pause:before {
  content: "\f084";
}
.icon-plug-circle-bolt:before {
  content: "\f085";
}
.icon-plug-circle-plus:before {
  content: "\f086";
}
.icon-print-slash:before {
  content: "\f087";
}
.icon-projector:before {
  content: "\f088";
}
.icon-quotes:before {
  content: "\f089";
}
.icon-radar:before {
  content: "\f08a";
}
.icon-radio:before {
  content: "\f08b";
}
.icon-raindrops:before {
  content: "\f08c";
}
.icon-rectangle-terminal:before {
  content: "\f08d";
}
.icon-rectangle-vertical-history:before {
  content: "\f08e";
}
.icon-rectangle-wide:before {
  content: "\f08f";
}
.icon-recycle:before {
  content: "\f090";
}
.icon-road-barrier:before {
  content: "\f091";
}
.icon-road-lock:before {
  content: "\f092";
}
.icon-roller-coaster:before {
  content: "\f093";
}
.icon-rotate-reverse:before {
  content: "\f094";
}
.icon-ruler:before {
  content: "\f095";
}
.icon-ruler-combined:before {
  content: "\f096";
}
.icon-salad:before {
  content: "\f097";
}
.icon-scale-unbalanced:before {
  content: "\f098";
}
.icon-scalpel-line-dashed:before {
  content: "\f099";
}
.icon-scanner-keyboard:before {
  content: "\f09a";
}
.icon-screwdriver:before {
  content: "\f09b";
}
.icon-scroll-old:before {
  content: "\f09c";
}
.icon-seal:before {
  content: "\f09d";
}
.icon-seat-airline:before {
  content: "\f09e";
}
.icon-shield-heart:before {
  content: "\f09f";
}
.icon-shield-slash:before {
  content: "\f0a0";
}
.icon-shield-virus:before {
  content: "\f0a1";
}
.icon-shirt-long-sleeve:before {
  content: "\f0a2";
}
.icon-shirt-tank-top:before {
  content: "\f0a3";
}
.icon-shop:before {
  content: "\f0a4";
}
.icon-signal-bars-slash:before {
  content: "\f0a5";
}
.icon-siren-on:before {
  content: "\f0a6";
}
.icon-skeleton:before {
  content: "\f0a7";
}
.icon-sliders:before {
  content: "\f0a8";
}
.icon-smog:before {
  content: "\f0a9";
}
.icon-snowplow:before {
  content: "\f0aa";
}
.icon-square-5:before {
  content: "\f0ab";
}
.icon-square-8:before {
  content: "\f0ac";
}
.icon-square-caret-right:before {
  content: "\f0ad";
}
.icon-square-chevron-down:before {
  content: "\f0ae";
}
.icon-square-chevron-right:before {
  content: "\f0af";
}
.icon-square-down-left:before {
  content: "\f0b0";
}
.icon-square-envelope:before {
  content: "\f0b1";
}
.icon-square-i:before {
  content: "\f0b2";
}
.icon-square-kanban:before {
  content: "\f0b3";
}
.icon-square-n:before {
  content: "\f0b4";
}
.icon-square-nfi:before {
  content: "\f0b5";
}
.icon-square-phone-flip:before {
  content: "\f0b6";
}
.icon-square-w:before {
  content: "\f0b7";
}
.icon-square-xmark:before {
  content: "\f0b8";
}
.icon-stairs:before {
  content: "\f0b9";
}
.icon-standard-definition:before {
  content: "\f0ba";
}
.icon-store:before {
  content: "\f0bb";
}
.icon-sun-dust:before {
  content: "\f0bc";
}
.icon-sushi-roll:before {
  content: "\f0bd";
}
.icon-table-columns:before {
  content: "\f0be";
}
.icon-tablets:before {
  content: "\f0bf";
}
.icon-tally-1:before {
  content: "\f0c0";
}
.icon-temperature-arrow-up:before {
  content: "\f0c1";
}
.icon-temperature-low:before {
  content: "\f0c2";
}
.icon-tent-arrow-down-to-line:before {
  content: "\f0c3";
}
.icon-thumbs-down:before {
  content: "\f0c4";
}
.icon-ticket:before {
  content: "\f0c5";
}
.icon-tire-rugged:before {
  content: "\f0c6";
}
.icon-toggle-off:before {
  content: "\f0c7";
}
.icon-toilet:before {
  content: "\f0c8";
}
.icon-toilet-paper-slash:before {
  content: "\f0c9";
}
.icon-toilet-paper-xmark:before {
  content: "\f0ca";
}
.icon-train-track:before {
  content: "\f0cb";
}
.icon-transformer-bolt:before {
  content: "\f0cc";
}
.icon-trash-can-undo:before {
  content: "\f0cd";
}
.icon-umbrella:before {
  content: "\f0ce";
}
.icon-unicorn:before {
  content: "\f0cf";
}
.icon-up-down-left-right:before {
  content: "\f0d0";
}
.icon-up-to-line:before {
  content: "\f0d1";
}
.icon-user-alien:before {
  content: "\f0d2";
}
.icon-user-check:before {
  content: "\f0d3";
}
.icon-user-hair-mullet:before {
  content: "\f0d4";
}
.icon-users-rays:before {
  content: "\f0d5";
}
.icon-user-tie:before {
  content: "\f0d6";
}
.icon-user-vneck:before {
  content: "\f0d7";
}
.icon-utensils:before {
  content: "\f0d8";
}
.icon-utensils-slash:before {
  content: "\f0d9";
}
.icon-vial:before {
  content: "\f0da";
}
.icon-video-plus:before {
  content: "\f0db";
}
.icon-volleyball:before {
  content: "\f0dc";
}
.icon-volume-slash:before {
  content: "\f0dd";
}
.icon-w:before {
  content: "\f0de";
}
.icon-walker:before {
  content: "\f0df";
}
.icon-wand:before {
  content: "\f0e0";
}
.icon-water-arrow-up:before {
  content: "\f0e1";
}
.icon-waveform-lines:before {
  content: "\f0e2";
}
.icon-webhook:before {
  content: "\f0e3";
}
.icon-wheelchair:before {
  content: "\f0e4";
}
.icon-window-maximize:before {
  content: "\f0e5";
}
.icon-wind-warning:before {
  content: "\f0e6";
}
.icon-xmark-to-slot:before {
  content: "\f0e7";
}
.icon-0:before {
  content: "\f0e8";
}
.icon-degrees:before {
  content: "\f0e9";
}
.icon-alarm-clock:before {
  content: "\f0ea";
}
.icon-alien-8bit:before {
  content: "\f0eb";
}
.icon-angles-right:before {
  content: "\f0ec";
}
.icon-arrow-down:before {
  content: "\f0ed";
}
.icon-arrow-down-from-dotted-line:before {
  content: "\f0ee";
}
.icon-arrow-down-wide-short:before {
  content: "\f0ef";
}
.icon-arrows-split-up-and-left:before {
  content: "\f0f0";
}
.icon-arrows-up-to-line:before {
  content: "\f0f1";
}
.icon-arrow-up-big-small:before {
  content: "\f0f2";
}
.icon-axe:before {
  content: "\f0f3";
}
.icon-backward:before {
  content: "\f0f4";
}
.icon-badge:before {
  content: "\f0f5";
}
.icon-ban-bug:before {
  content: "\f0f6";
}
.icon-bell-on:before {
  content: "\f0f7";
}
.icon-bell-ring:before {
  content: "\f0f8";
}
.icon-bold:before {
  content: "\f0f9";
}
.icon-book-arrow-up:before {
  content: "\f0fa";
}
.icon-box-dollar:before {
  content: "\f0fb";
}
.icon-brake-warning:before {
  content: "\f0fc";
}
.icon-bridge-water:before {
  content: "\f0fd";
}
.icon-bucket:before {
  content: "\f0fe";
}
.icon-building-flag:before {
  content: "\f0ff";
}
.icon-cabin:before {
  content: "\f100";
}
.icon-calendar-day:before {
  content: "\f101";
}
.icon-camera-viewfinder:before {
  content: "\f102";
}
.icon-card-club:before {
  content: "\f103";
}
.icon-card-diamond:before {
  content: "\f104";
}
.icon-cassette-tape:before {
  content: "\f105";
}
.icon-chart-area:before {
  content: "\f106";
}
.icon-chart-simple:before {
  content: "\f107";
}
.icon-chart-user:before {
  content: "\f108";
}
.icon-check-to-slot:before {
  content: "\f109";
}
.icon-chess-queen:before {
  content: "\f10a";
}
.icon-chevrons-up:before {
  content: "\f10b";
}
.icon-circle-arrow-down-left:before {
  content: "\f10c";
}
.icon-circle-half-stroke:before {
  content: "\f10d";
}
.icon-circle-left:before {
  content: "\f10e";
}
.icon-circle-small:before {
  content: "\f10f";
}
.icon-circle-t:before {
  content: "\f110";
}
.icon-circle-trash:before {
  content: "\f111";
}
.icon-clipboard-user:before {
  content: "\f112";
}
.icon-cloud-rain:before {
  content: "\f113";
}
.icon-clouds-moon:before {
  content: "\f114";
}
.icon-coffin:before {
  content: "\f115";
}
.icon-coin-vertical:before {
  content: "\f116";
}
.icon-comment-arrow-up-right:before {
  content: "\f117";
}
.icon-comment-check:before {
  content: "\f118";
}
.icon-comment-image:before {
  content: "\f119";
}
.icon-comment-question:before {
  content: "\f11a";
}
.icon-cookie:before {
  content: "\f11b";
}
.icon-crab:before {
  content: "\f11c";
}
.icon-credit-card-front:before {
  content: "\f11d";
}
.icon-crown:before {
  content: "\f11e";
}
.icon-crystal-ball:before {
  content: "\f11f";
}
.icon-diagram-venn:before {
  content: "\f120";
}
.icon-dial-med-low:before {
  content: "\f121";
}
.icon-dice-six:before {
  content: "\f122";
}
.icon-display-slash:before {
  content: "\f123";
}
.icon-ditto:before {
  content: "\f124";
}
.icon-dolphin:before {
  content: "\f125";
}
.icon-download:before {
  content: "\f126";
}
.icon-ellipsis-stroke:before {
  content: "\f127";
}
.icon-eye-dropper-half:before {
  content: "\f128";
}
.icon-eye-evil:before {
  content: "\f129";
}
.icon-face-angry:before {
  content: "\f12a";
}
.icon-face-drooling:before {
  content: "\f12b";
}
.icon-face-grin-stars:before {
  content: "\f12c";
}
.icon-face-persevering:before {
  content: "\f12d";
}
.icon-face-tongue-money:before {
  content: "\f12e";
}
.icon-face-viewfinder:before {
  content: "\f12f";
}
.icon-file-circle-question:before {
  content: "\f130";
}
.icon-film-canister:before {
  content: "\f131";
}
.icon-flag-checkered:before {
  content: "\f132";
}
.icon-flower-tulip:before {
  content: "\f133";
}
.icon-football:before {
  content: "\f134";
}
.icon-glass-citrus:before {
  content: "\f135";
}
.icon-grid-2-plus:before {
  content: "\f136";
}
.icon-grid-round-2:before {
  content: "\f137";
}
.icon-grid-round-2-plus:before {
  content: "\f138";
}
.icon-grip:before {
  content: "\f139";
}
.icon-hand-holding-box:before {
  content: "\f13a";
}
.icon-hands-holding:before {
  content: "\f13b";
}
.icon-hands-holding-diamond:before {
  content: "\f13c";
}
.icon-hexagon-check:before {
  content: "\f13d";
}
.icon-highlighter:before {
  content: "\f13e";
}
.icon-house-flag:before {
  content: "\f13f";
}
.icon-house-user:before {
  content: "\f140";
}
.icon-hyphen:before {
  content: "\f141";
}
.icon-images-user:before {
  content: "\f142";
}
.icon-keyboard-brightness:before {
  content: "\f143";
}
.icon-kip-sign:before {
  content: "\f144";
}
.icon-left-long:before {
  content: "\f145";
}
.icon-link-horizontal-slash:before {
  content: "\f146";
}
.icon-location-arrow:before {
  content: "\f147";
}
.icon-magnifying-glass-arrow-right:before {
  content: "\f148";
}
.icon-masks-theater:before {
  content: "\f149";
}
.icon-message-dollar:before {
  content: "\f14a";
}
.icon-message-minus:before {
  content: "\f14b";
}
.icon-microphone-lines-slash:before {
  content: "\f14c";
}
.icon-money-bill-simple-wave:before {
  content: "\f14d";
}
.icon-money-check-pen:before {
  content: "\f14e";
}
.icon-mug-tea:before {
  content: "\f14f";
}
.icon-mushroom:before {
  content: "\f150";
}
.icon-nfc:before {
  content: "\f151";
}
.icon-option:before {
  content: "\f152";
}
.icon-pancakes:before {
  content: "\f153";
}
.icon-paper-plane-top:before {
  content: "\f154";
}
.icon-person-circle-question:before {
  content: "\f155";
}
.icon-person-skating:before {
  content: "\f156";
}
.icon-pipe-smoking:before {
  content: "\f157";
}
.icon-plane-circle-exclamation:before {
  content: "\f158";
}
.icon-planet-ringed:before {
  content: "\f159";
}
.icon-plug:before {
  content: "\f15a";
}
.icon-pompebled:before {
  content: "\f15b";
}
.icon-popcorn:before {
  content: "\f15c";
}
.icon-pump-medical:before {
  content: "\f15d";
}
.icon-rabbit:before {
  content: "\f15e";
}
.icon-rocket-launch:before {
  content: "\f15f";
}
.icon-rotate-left:before {
  content: "\f160";
}
.icon-route:before {
  content: "\f161";
}
.icon-ruble-sign:before {
  content: "\f162";
}
.icon-shield-check:before {
  content: "\f163";
}
.icon-shield-keyhole:before {
  content: "\f164";
}
.icon-signature-slash:before {
  content: "\f165";
}
.icon-skull:before {
  content: "\f166";
}
.icon-snowflakes:before {
  content: "\f167";
}
.icon-sort-up:before {
  content: "\f168";
}
.icon-square-arrow-down-left:before {
  content: "\f169";
}
.icon-square-down:before {
  content: "\f16a";
}
.icon-square-minus:before {
  content: "\f16b";
}
.icon-square-root:before {
  content: "\f16c";
}
.icon-square-s:before {
  content: "\f16d";
}
.icon-square-up-left:before {
  content: "\f16e";
}
.icon-star-sharp:before {
  content: "\f16f";
}
.icon-stroopwafel:before {
  content: "\f170";
}
.icon-subtitles:before {
  content: "\f171";
}
.icon-sunset:before {
  content: "\f172";
}
.icon-tally-4:before {
  content: "\f173";
}
.icon-teeth:before {
  content: "\f174";
}
.icon-toilet-paper:before {
  content: "\f175";
}
.icon-tractor:before {
  content: "\f176";
}
.icon-transporter-3:before {
  content: "\f177";
}
.icon-transporter-4:before {
  content: "\f178";
}
.icon-transporter-empty:before {
  content: "\f179";
}
.icon-trash-can-plus:before {
  content: "\f17a";
}
.icon-trash-can-xmark:before {
  content: "\f17b";
}
.icon-trash-undo:before {
  content: "\f17c";
}
.icon-trash-xmark:before {
  content: "\f17d";
}
.icon-tree:before {
  content: "\f17e";
}
.icon-tree-decorated:before {
  content: "\f17f";
}
.icon-up:before {
  content: "\f180";
}
.icon-upload:before {
  content: "\f181";
}
.icon-user-lock:before {
  content: "\f182";
}
.icon-users-slash:before {
  content: "\f183";
}
.icon-vacuum:before {
  content: "\f184";
}
.icon-wand-magic-sparkles:before {
  content: "\f185";
}
.icon-watch:before {
  content: "\f186";
}
.icon-wheat-awn-circle-exclamation:before {
  content: "\f187";
}
.icon-wifi-weak:before {
  content: "\f188";
}
.icon-window-restore:before {
  content: "\f189";
}
.icon-2:before {
  content: "\f18a";
}
.icon-accent-grave:before {
  content: "\f18b";
}
.icon-address-card:before {
  content: "\f18c";
}
.icon-album-circle-plus:before {
  content: "\f18d";
}
.icon-align-slash:before {
  content: "\f18e";
}
.icon-angle-left:before {
  content: "\f18f";
}
.icon-archway:before {
  content: "\f190";
}
.icon-arrow-down-arrow-up:before {
  content: "\f191";
}
.icon-arrow-left-from-bracket:before {
  content: "\f192";
}
.icon-arrow-left-long:before {
  content: "\f193";
}
.icon-arrow-right-to-bracket:before {
  content: "\f194";
}
.icon-arrows-from-line:before {
  content: "\f195";
}
.icon-arrows-to-dotted-line:before {
  content: "\f196";
}
.icon-badminton:before {
  content: "\f197";
}
.icon-baseball-bat-ball:before {
  content: "\f198";
}
.icon-battery-low:before {
  content: "\f199";
}
.icon-bee:before {
  content: "\f19a";
}
.icon-book-font:before {
  content: "\f19b";
}
.icon-brackets-square:before {
  content: "\f19c";
}
.icon-brightness-low:before {
  content: "\f19d";
}
.icon-bugs:before {
  content: "\f19e";
}
.icon-building-circle-arrow-right:before {
  content: "\f19f";
}
.icon-building-circle-check:before {
  content: "\f1a0";
}
.icon-burst:before {
  content: "\f1a1";
}
.icon-calendar-arrow-down:before {
  content: "\f1a2";
}
.icon-calendar-arrow-up:before {
  content: "\f1a3";
}
.icon-calendar-check:before {
  content: "\f1a4";
}
.icon-calendar-heart:before {
  content: "\f1a5";
}
.icon-camera-web:before {
  content: "\f1a6";
}
.icon-candy-corn:before {
  content: "\f1a7";
}
.icon-caravan-simple:before {
  content: "\f1a8";
}
.icon-caret-down:before {
  content: "\f1a9";
}
.icon-champagne-glass:before {
  content: "\f1aa";
}
.icon-chess-board:before {
  content: "\f1ab";
}
.icon-chess-king:before {
  content: "\f1ac";
}
.icon-chess-knight-piece:before {
  content: "\f1ad";
}
.icon-chevron-up:before {
  content: "\f1ae";
}
.icon-circle-8:before {
  content: "\f1af";
}
.icon-circle-a:before {
  content: "\f1b0";
}
.icon-circle-down-left:before {
  content: "\f1b1";
}
.icon-circle-half:before {
  content: "\f1b2";
}
.icon-circle-k:before {
  content: "\f1b3";
}
.icon-circle-video:before {
  content: "\f1b4";
}
.icon-clarinet:before {
  content: "\f1b5";
}
.icon-clipboard-question:before {
  content: "\f1b6";
}
.icon-clock-eight:before {
  content: "\f1b7";
}
.icon-cloud-moon:before {
  content: "\f1b8";
}
.icon-clover:before {
  content: "\f1b9";
}
.icon-coins:before {
  content: "\f1ba";
}
.icon-columns-3:before {
  content: "\f1bb";
}
.icon-comment-captions:before {
  content: "\f1bc";
}
.icon-computer:before {
  content: "\f1bd";
}
.icon-conveyor-belt:before {
  content: "\f1be";
}
.icon-credit-card:before {
  content: "\f1bf";
}
.icon-cubes-stacked:before {
  content: "\f1c0";
}
.icon-diagram-nested:before {
  content: "\f1c1";
}
.icon-dice-d6:before {
  content: "\f1c2";
}
.icon-down-to-bracket:before {
  content: "\f1c3";
}
.icon-dragon:before {
  content: "\f1c4";
}
.icon-droplet-slash:before {
  content: "\f1c5";
}
.icon-earth-americas:before {
  content: "\f1c6";
}
.icon-earth-oceania:before {
  content: "\f1c7";
}
.icon-eclipse:before {
  content: "\f1c8";
}
.icon-face-awesome:before {
  content: "\f1c9";
}
.icon-face-downcast-sweat:before {
  content: "\f1ca";
}
.icon-face-glasses:before {
  content: "\f1cb";
}
.icon-face-grin-tongue-squint:before {
  content: "\f1cc";
}
.icon-face-head-bandage:before {
  content: "\f1cd";
}
.icon-face-smile-halo:before {
  content: "\f1ce";
}
.icon-fan-table:before {
  content: "\f1cf";
}
.icon-feather-pointed:before {
  content: "\f1d0";
}
.icon-file-circle-plus:before {
  content: "\f1d1";
}
.icon-file-minus:before {
  content: "\f1d2";
}
.icon-fish:before {
  content: "\f1d3";
}
.icon-folder:before {
  content: "\f1d4";
}
.icon-folder-minus:before {
  content: "\f1d5";
}
.icon-gauge-circle-bolt:before {
  content: "\f1d6";
}
.icon-h4:before {
  content: "\f1d7";
}
.icon-hand-holding-dollar:before {
  content: "\f1d8";
}
.icon-hand-peace:before {
  content: "\f1d9";
}
.icon-hand-point-up:before {
  content: "\f1da";
}
.icon-handshake:before {
  content: "\f1db";
}
.icon-handshake-simple-slash:before {
  content: "\f1dc";
}
.icon-hashtag:before {
  content: "\f1dd";
}
.icon-hat-santa:before {
  content: "\f1de";
}
.icon-hat-witch:before {
  content: "\f1df";
}
.icon-headphones:before {
  content: "\f1e0";
}
.icon-hexagon-exclamation:before {
  content: "\f1e1";
}
.icon-hourglass-end:before {
  content: "\f1e2";
}
.icon-image-polaroid-user:before {
  content: "\f1e3";
}
.icon-italic:before {
  content: "\f1e4";
}
.icon-kazoo:before {
  content: "\f1e5";
}
.icon-keynote:before {
  content: "\f1e6";
}
.icon-key-skeleton:before {
  content: "\f1e7";
}
.icon-laptop-mobile:before {
  content: "\f1e8";
}
.icon-light-ceiling:before {
  content: "\f1e9";
}
.icon-link-slash:before {
  content: "\f1ea";
}
.icon-list-tree:before {
  content: "\f1eb";
}
.icon-location-check:before {
  content: "\f1ec";
}
.icon-location-smile:before {
  content: "\f1ed";
}
.icon-lock:before {
  content: "\f1ee";
}
.icon-lock-keyhole:before {
  content: "\f1ef";
}
.icon-lollipop:before {
  content: "\f1f0";
}
.icon-m:before {
  content: "\f1f1";
}
.icon-mask-snorkel:before {
  content: "\f1f2";
}
.icon-mattress-pillow:before {
  content: "\f1f3";
}
.icon-megaphone:before {
  content: "\f1f4";
}
.icon-message-arrow-up-right:before {
  content: "\f1f5";
}
.icon-message-smile:before {
  content: "\f1f6";
}
.icon-mobile-signal-out:before {
  content: "\f1f7";
}
.icon-money-bill-trend-up:before {
  content: "\f1f8";
}
.icon-money-bill-wheat:before {
  content: "\f1f9";
}
.icon-mp3-player:before {
  content: "\f1fa";
}
.icon-music-note-slash:before {
  content: "\f1fb";
}
.icon-narwhal:before {
  content: "\f1fc";
}
.icon-newspaper:before {
  content: "\f1fd";
}
.icon-nfc-magnifying-glass:before {
  content: "\f1fe";
}
.icon-nfc-signal:before {
  content: "\f1ff";
}
.icon-octagon-divide:before {
  content: "\f200";
}
.icon-octagon-exclamation:before {
  content: "\f201";
}
.icon-oil-well:before {
  content: "\f202";
}
.icon-overline:before {
  content: "\f203";
}
.icon-paw-simple:before {
  content: "\f204";
}
.icon-pen-clip:before {
  content: "\f205";
}
.icon-person-military-to-person:before {
  content: "\f206";
}
.icon-person-sledding:before {
  content: "\f207";
}
.icon-person-walking-luggage:before {
  content: "\f208";
}
.icon-phone-arrow-right:before {
  content: "\f209";
}
.icon-phone-slash:before {
  content: "\f20a";
}
.icon-plane-departure:before {
  content: "\f20b";
}
.icon-poll-people:before {
  content: "\f20c";
}
.icon-prescription-bottle:before {
  content: "\f20d";
}
.icon-prescription-bottle-medical:before {
  content: "\f20e";
}
.icon-prescription-bottle-pill:before {
  content: "\f20f";
}
.icon-presentation-screen:before {
  content: "\f210";
}
.icon-pump-soap:before {
  content: "\f211";
}
.icon-puzzle:before {
  content: "\f212";
}
.icon-q:before {
  content: "\f213";
}
.icon-r:before {
  content: "\f214";
}
.icon-rabbit-running:before {
  content: "\f215";
}
.icon-ramp-loading:before {
  content: "\f216";
}
.icon-rectangle-code:before {
  content: "\f217";
}
.icon-registered:before {
  content: "\f218";
}
.icon-reply-all:before {
  content: "\f219";
}
.icon-retweet:before {
  content: "\f21a";
}
.icon-rotate-right:before {
  content: "\f21b";
}
.icon-ruler-horizontal:before {
  content: "\f21c";
}
.icon-scarf:before {
  content: "\f21d";
}
.icon-scythe:before {
  content: "\f21e";
}
.icon-share-nodes:before {
  content: "\f21f";
}
.icon-shield-dog:before {
  content: "\f220";
}
.icon-signal-bars-fair:before {
  content: "\f221";
}
.icon-ski-boot-ski:before {
  content: "\f222";
}
.icon-skull-crossbones:before {
  content: "\f223";
}
.icon-slash:before {
  content: "\f224";
}
.icon-split:before {
  content: "\f225";
}
.icon-sprinkler-ceiling:before {
  content: "\f226";
}
.icon-square-6:before {
  content: "\f227";
}
.icon-square-ampersand:before {
  content: "\f228";
}
.icon-square-e:before {
  content: "\f229";
}
.icon-square-quote:before {
  content: "\f22a";
}
.icon-staff-snake:before {
  content: "\f22b";
}
.icon-sterling-sign:before {
  content: "\f22c";
}
.icon-strikethrough:before {
  content: "\f22d";
}
.icon-subtitles-slash:before {
  content: "\f22e";
}
.icon-sun-haze:before {
  content: "\f22f";
}
.icon-sushi:before {
  content: "\f230";
}
.icon-tag:before {
  content: "\f231";
}
.icon-text-size:before {
  content: "\f232";
}
.icon-thumbtack:before {
  content: "\f233";
}
.icon-timeline:before {
  content: "\f234";
}
.icon-tornado:before {
  content: "\f235";
}
.icon-trowel-bricks:before {
  content: "\f236";
}
.icon-typewriter:before {
  content: "\f237";
}
.icon-ufo:before {
  content: "\f238";
}
.icon-up-from-bracket:before {
  content: "\f239";
}
.icon-up-to-dotted-line:before {
  content: "\f23a";
}
.icon-user-astronaut:before {
  content: "\f23b";
}
.icon-user-helmet-safety:before {
  content: "\f23c";
}
.icon-user-music:before {
  content: "\f23d";
}
.icon-wand-sparkles:before {
  content: "\f23e";
}
.icon-wave:before {
  content: "\f23f";
}
.icon-wave-sine:before {
  content: "\f240";
}
.icon-window-minimize:before {
  content: "\f241";
}
.icon-z:before {
  content: "\f242";
}
.icon-3:before {
  content: "\f243";
}
.icon-6:before {
  content: "\f244";
}
.icon-alien:before {
  content: "\f245";
}
.icon-align-justify:before {
  content: "\f246";
}
.icon-amp-guitar:before {
  content: "\f247";
}
.icon-anchor-circle-exclamation:before {
  content: "\f248";
}
.icon-angel:before {
  content: "\f249";
}
.icon-ant:before {
  content: "\f24a";
}
.icon-apple-whole:before {
  content: "\f24b";
}
.icon-arrow-left-from-arc:before {
  content: "\f24c";
}
.icon-arrow-left-to-bracket:before {
  content: "\f24d";
}
.icon-arrow-right:before {
  content: "\f24e";
}
.icon-arrow-right-arrow-left:before {
  content: "\f24f";
}
.icon-arrows-repeat:before {
  content: "\f250";
}
.icon-arrows-turn-right:before {
  content: "\f251";
}
.icon-arrows-up-down:before {
  content: "\f252";
}
.icon-arrow-turn-down:before {
  content: "\f253";
}
.icon-arrow-up-1-9:before {
  content: "\f254";
}
.icon-arrow-up-9-1:before {
  content: "\f255";
}
.icon-arrow-up-left-from-circle:before {
  content: "\f256";
}
.icon-audio-description-slash:before {
  content: "\f257";
}
.icon-baby-carriage:before {
  content: "\f258";
}
.icon-badge-percent:before {
  content: "\f259";
}
.icon-badger-honey:before {
  content: "\f25a";
}
.icon-bags-shopping:before {
  content: "\f25b";
}
.icon-baht-sign:before {
  content: "\f25c";
}
.icon-balloons:before {
  content: "\f25d";
}
.icon-ballot-check:before {
  content: "\f25e";
}
.icon-barcode:before {
  content: "\f25f";
}
.icon-barcode-scan:before {
  content: "\f260";
}
.icon-basketball-hoop:before {
  content: "\f261";
}
.icon-binary-slash:before {
  content: "\f262";
}
.icon-block-quote:before {
  content: "\f263";
}
.icon-bolt:before {
  content: "\f264";
}
.icon-bomb:before {
  content: "\f265";
}
.icon-border-outer:before {
  content: "\f266";
}
.icon-border-top-left:before {
  content: "\f267";
}
.icon-bowl-chopsticks:before {
  content: "\f268";
}
.icon-bridge-circle-check:before {
  content: "\f269";
}
.icon-bridge-circle-xmark:before {
  content: "\f26a";
}
.icon-briefcase-arrow-right:before {
  content: "\f26b";
}
.icon-briefcase-medical:before {
  content: "\f26c";
}
.icon-bring-forward:before {
  content: "\f26d";
}
.icon-broccoli:before {
  content: "\f26e";
}
.icon-building-columns:before {
  content: "\f26f";
}
.icon-building-user:before {
  content: "\f270";
}
.icon-burger-lettuce:before {
  content: "\f271";
}
.icon-cabinet-filing:before {
  content: "\f272";
}
.icon-cable-car:before {
  content: "\f273";
}
.icon-cake-slice:before {
  content: "\f274";
}
.icon-calendar-circle-user:before {
  content: "\f275";
}
.icon-calendar-users:before {
  content: "\f276";
}
.icon-camera-rotate:before {
  content: "\f277";
}
.icon-can-food:before {
  content: "\f278";
}
.icon-car-bus:before {
  content: "\f279";
}
.icon-cards:before {
  content: "\f27a";
}
.icon-cart-circle-plus:before {
  content: "\f27b";
}
.icon-cat-space:before {
  content: "\f27c";
}
.icon-chalkboard-user:before {
  content: "\f27d";
}
.icon-charging-station:before {
  content: "\f27e";
}
.icon-chart-candlestick:before {
  content: "\f27f";
}
.icon-chart-mixed-up-circle-currency:before {
  content: "\f280";
}
.icon-chart-simple-horizontal:before {
  content: "\f281";
}
.icon-check:before {
  content: "\f282";
}
.icon-chess-rook:before {
  content: "\f283";
}
.icon-chess-rook-piece:before {
  content: "\f284";
}
.icon-chevrons-right:before {
  content: "\f285";
}
.icon-circle-arrow-up:before {
  content: "\f286";
}
.icon-circle-ellipsis:before {
  content: "\f287";
}
.icon-circle-info:before {
  content: "\f288";
}
.icon-circle-phone-hangup:before {
  content: "\f289";
}
.icon-circle-sterling:before {
  content: "\f28a";
}
.icon-clipboard-check:before {
  content: "\f28b";
}
.icon-clock-one-thirty:before {
  content: "\f28c";
}
.icon-clock-six:before {
  content: "\f28d";
}
.icon-clock-two-thirty:before {
  content: "\f28e";
}
.icon-cloud-arrow-down:before {
  content: "\f28f";
}
.icon-cloud-binary:before {
  content: "\f290";
}
.icon-cloud-music:before {
  content: "\f291";
}
.icon-clouds:before {
  content: "\f292";
}
.icon-command:before {
  content: "\f293";
}
.icon-comment-dollar:before {
  content: "\f294";
}
.icon-comment-dots:before {
  content: "\f295";
}
.icon-comment-exclamation:before {
  content: "\f296";
}
.icon-comments-question-check:before {
  content: "\f297";
}
.icon-corn:before {
  content: "\f298";
}
.icon-corner:before {
  content: "\f299";
}
.icon-cowbell:before {
  content: "\f29a";
}
.icon-crate-empty:before {
  content: "\f29b";
}
.icon-cricket-bat-ball:before {
  content: "\f29c";
}
.icon-crop:before {
  content: "\f29d";
}
.icon-cross:before {
  content: "\f29e";
}
.icon-crutch:before {
  content: "\f29f";
}
.icon-cup-straw:before {
  content: "\f2a0";
}
.icon-database:before {
  content: "\f2a1";
}
.icon-desktop:before {
  content: "\f2a2";
}
.icon-diagram-successor:before {
  content: "\f2a3";
}
.icon-dice-five:before {
  content: "\f2a4";
}
.icon-dog:before {
  content: "\f2a5";
}
.icon-door-open:before {
  content: "\f2a6";
}
.icon-dove:before {
  content: "\f2a7";
}
.icon-ear:before {
  content: "\f2a8";
}
.icon-eggplant:before {
  content: "\f2a9";
}
.icon-elephant:before {
  content: "\f2aa";
}
.icon-envelope-open-dollar:before {
  content: "\f2ab";
}
.icon-escalator:before {
  content: "\f2ac";
}
.icon-excavator:before {
  content: "\f2ad";
}
.icon-face-beam-hand-over-mouth:before {
  content: "\f2ae";
}
.icon-face-frown:before {
  content: "\f2af";
}
.icon-face-hand-yawn:before {
  content: "\f2b0";
}
.icon-face-holding-back-tears:before {
  content: "\f2b1";
}
.icon-face-icicles:before {
  content: "\f2b2";
}
.icon-face-kiss-beam:before {
  content: "\f2b3";
}
.icon-face-kiss-closed-eyes:before {
  content: "\f2b4";
}
.icon-face-party:before {
  content: "\f2b5";
}
.icon-face-raised-eyebrow:before {
  content: "\f2b6";
}
.icon-face-smile-horns:before {
  content: "\f2b7";
}
.icon-face-smile-tear:before {
  content: "\f2b8";
}
.icon-face-smile-upside-down:before {
  content: "\f2b9";
}
.icon-face-smirking:before {
  content: "\f2ba";
}
.icon-family-dress:before {
  content: "\f2bb";
}
.icon-fan:before {
  content: "\f2bc";
}
.icon-file-chart-column:before {
  content: "\f2bd";
}
.icon-file-circle-info:before {
  content: "\f2be";
}
.icon-file-code:before {
  content: "\f2bf";
}
.icon-file-csv:before {
  content: "\f2c0";
}
.icon-file-dashed-line:before {
  content: "\f2c1";
}
.icon-file-export:before {
  content: "\f2c2";
}
.icon-file-mp3:before {
  content: "\f2c3";
}
.icon-fill:before {
  content: "\f2c4";
}
.icon-fingerprint:before {
  content: "\f2c5";
}
.icon-fishing-rod:before {
  content: "\f2c6";
}
.icon-flask-vial:before {
  content: "\f2c7";
}
.icon-floppy-disk-circle-arrow-right:before {
  content: "\f2c8";
}
.icon-folder-bookmark:before {
  content: "\f2c9";
}
.icon-fort:before {
  content: "\f2ca";
}
.icon-game-board-simple:before {
  content: "\f2cb";
}
.icon-game-console-handheld-crank:before {
  content: "\f2cc";
}
.icon-gauge-simple-min:before {
  content: "\f2cd";
}
.icon-gear-complex:before {
  content: "\f2ce";
}
.icon-gear-complex-code:before {
  content: "\f2cf";
}
.icon-glasses:before {
  content: "\f2d0";
}
.icon-golf-club:before {
  content: "\f2d1";
}
.icon-gramophone:before {
  content: "\f2d2";
}
.icon-grid:before {
  content: "\f2d3";
}
.icon-grid-horizontal:before {
  content: "\f2d4";
}
.icon-grill-hot:before {
  content: "\f2d5";
}
.icon-grip-lines:before {
  content: "\f2d6";
}
.icon-hammer-war:before {
  content: "\f2d7";
}
.icon-hand-back-point-right:before {
  content: "\f2d8";
}
.icon-hand-point-right:before {
  content: "\f2d9";
}
.icon-hashtag-lock:before {
  content: "\f2da";
}
.icon-head-side-brain:before {
  content: "\f2db";
}
.icon-head-side-gear:before {
  content: "\f2dc";
}
.icon-heart-half:before {
  content: "\f2dd";
}
.icon-heat:before {
  content: "\f2de";
}
.icon-hourglass-clock:before {
  content: "\f2df";
}
.icon-house-building:before {
  content: "\f2e0";
}
.icon-house-circle-exclamation:before {
  content: "\f2e1";
}
.icon-house-laptop:before {
  content: "\f2e2";
}
.icon-house-tree:before {
  content: "\f2e3";
}
.icon-house-tsunami:before {
  content: "\f2e4";
}
.icon-icons:before {
  content: "\f2e5";
}
.icon-id-card-clip:before {
  content: "\f2e6";
}
.icon-image-portrait:before {
  content: "\f2e7";
}
.icon-inbox-in:before {
  content: "\f2e8";
}
.icon-infinity:before {
  content: "\f2e9";
}
.icon-khanda:before {
  content: "\f2ea";
}
.icon-kiwi-bird:before {
  content: "\f2eb";
}
.icon-link-horizontal:before {
  content: "\f2ec";
}
.icon-link-simple:before {
  content: "\f2ed";
}
.icon-list-ol:before {
  content: "\f2ee";
}
.icon-location-crosshairs:before {
  content: "\f2ef";
}
.icon-location-dot-slash:before {
  content: "\f2f0";
}
.icon-location-plus:before {
  content: "\f2f1";
}
.icon-luchador-mask:before {
  content: "\f2f2";
}
.icon-magnifying-glass-waveform:before {
  content: "\f2f3";
}
.icon-map:before {
  content: "\f2f4";
}
.icon-map-pin:before {
  content: "\f2f5";
}
.icon-mars-and-venus-burst:before {
  content: "\f2f6";
}
.icon-mars-stroke:before {
  content: "\f2f7";
}
.icon-maximize:before {
  content: "\f2f8";
}
.icon-memo:before {
  content: "\f2f9";
}
.icon-merge:before {
  content: "\f2fa";
}
.icon-message-code:before {
  content: "\f2fb";
}
.icon-message-image:before {
  content: "\f2fc";
}
.icon-microwave:before {
  content: "\f2fd";
}
.icon-mortar-pestle:before {
  content: "\f2fe";
}
.icon-mountains:before {
  content: "\f2ff";
}
.icon-mouse-field:before {
  content: "\f300";
}
.icon-music-slash:before {
  content: "\f301";
}
.icon-note-medical:before {
  content: "\f302";
}
.icon-not-equal:before {
  content: "\f303";
}
.icon-objects-column:before {
  content: "\f304";
}
.icon-object-union:before {
  content: "\f305";
}
.icon-octagon:before {
  content: "\f306";
}
.icon-om:before {
  content: "\f307";
}
.icon-oven:before {
  content: "\f308";
}
.icon-paintbrush-pencil:before {
  content: "\f309";
}
.icon-pallet-box:before {
  content: "\f30a";
}
.icon-panel-ews:before {
  content: "\f30b";
}
.icon-paw:before {
  content: "\f30c";
}
.icon-peach:before {
  content: "\f30d";
}
.icon-pegasus:before {
  content: "\f30e";
}
.icon-pen:before {
  content: "\f30f";
}
.icon-people:before {
  content: "\f310";
}
.icon-person-burst:before {
  content: "\f311";
}
.icon-person-cane:before {
  content: "\f312";
}
.icon-person-dress-fairy:before {
  content: "\f313";
}
.icon-person-from-portal:before {
  content: "\f314";
}
.icon-person-ski-jumping:before {
  content: "\f315";
}
.icon-person-walking-arrow-loop-left:before {
  content: "\f316";
}
.icon-pie:before {
  content: "\f317";
}
.icon-pinata:before {
  content: "\f318";
}
.icon-pinball:before {
  content: "\f319";
}
.icon-pipe-section:before {
  content: "\f31a";
}
.icon-plane-circle-check:before {
  content: "\f31b";
}
.icon-plane-prop:before {
  content: "\f31c";
}
.icon-plane-tail:before {
  content: "\f31d";
}
.icon-plug-circle-xmark:before {
  content: "\f31e";
}
.icon-podcast:before {
  content: "\f31f";
}
.icon-podium-star:before {
  content: "\f320";
}
.icon-poop:before {
  content: "\f321";
}
.icon-print:before {
  content: "\f322";
}
.icon-quote-right:before {
  content: "\f323";
}
.icon-rectangle-history-circle-plus:before {
  content: "\f324";
}
.icon-rhombus:before {
  content: "\f325";
}
.icon-right-from-line:before {
  content: "\f326";
}
.icon-right-long:before {
  content: "\f327";
}
.icon-right-to-line:before {
  content: "\f328";
}
.icon-road-circle-exclamation:before {
  content: "\f329";
}
.icon-rotate:before {
  content: "\f32a";
}
.icon-salt-shaker:before {
  content: "\f32b";
}
.icon-scanner-image:before {
  content: "\f32c";
}
.icon-seal-question:before {
  content: "\f32d";
}
.icon-shekel-sign:before {
  content: "\f32e";
}
.icon-shelves-empty:before {
  content: "\f32f";
}
.icon-shredder:before {
  content: "\f330";
}
.icon-shutters:before {
  content: "\f331";
}
.icon-signal:before {
  content: "\f332";
}
.icon-signal-bars-good:before {
  content: "\f333";
}
.icon-signal-bars-weak:before {
  content: "\f334";
}
.icon-ski-boot:before {
  content: "\f335";
}
.icon-slash-forward:before {
  content: "\f336";
}
.icon-slot-machine:before {
  content: "\f337";
}
.icon-sparkle:before {
  content: "\f338";
}
.icon-splotch:before {
  content: "\f339";
}
.icon-sportsball:before {
  content: "\f33a";
}
.icon-spray-can-sparkles:before {
  content: "\f33b";
}
.icon-sprinkler:before {
  content: "\f33c";
}
.icon-square-9:before {
  content: "\f33d";
}
.icon-square-caret-left:before {
  content: "\f33e";
}
.icon-square-info:before {
  content: "\f33f";
}
.icon-square-list:before {
  content: "\f340";
}
.icon-square-p:before {
  content: "\f341";
}
.icon-square-question:before {
  content: "\f342";
}
.icon-square-ring:before {
  content: "\f343";
}
.icon-square-sliders:before {
  content: "\f344";
}
.icon-square-terminal:before {
  content: "\f345";
}
.icon-stapler:before {
  content: "\f346";
}
.icon-starfighter-twin-ion-engine:before {
  content: "\f347";
}
.icon-subscript:before {
  content: "\f348";
}
.icon-synagogue:before {
  content: "\f349";
}
.icon-table-layout:before {
  content: "\f34a";
}
.icon-text-height:before {
  content: "\f34b";
}
.icon-text-width:before {
  content: "\f34c";
}
.icon-thumbs-up:before {
  content: "\f34d";
}
.icon-tickets-simple:before {
  content: "\f34e";
}
.icon-tower-cell:before {
  content: "\f34f";
}
.icon-transporter-1:before {
  content: "\f350";
}
.icon-transporter-5:before {
  content: "\f351";
}
.icon-tree-christmas:before {
  content: "\f352";
}
.icon-triangle-exclamation:before {
  content: "\f353";
}
.icon-trophy:before {
  content: "\f354";
}
.icon-trophy-star:before {
  content: "\f355";
}
.icon-truck-medical:before {
  content: "\f356";
}
.icon-tugrik-sign:before {
  content: "\f357";
}
.icon-turn-left-up:before {
  content: "\f358";
}
.icon-tv-retro:before {
  content: "\f359";
}
.icon-umbrella-simple:before {
  content: "\f35a";
}
.icon-unlock:before {
  content: "\f35b";
}
.icon-up-right:before {
  content: "\f35c";
}
.icon-user-chef:before {
  content: "\f35d";
}
.icon-user-crown:before {
  content: "\f35e";
}
.icon-user-doctor:before {
  content: "\f35f";
}
.icon-user-large-slash:before {
  content: "\f360";
}
.icon-user-nurse-hair-long:before {
  content: "\f361";
}
.icon-user-police-tie:before {
  content: "\f362";
}
.icon-user-shield:before {
  content: "\f363";
}
.icon-vacuum-robot:before {
  content: "\f364";
}
.icon-vector-polygon:before {
  content: "\f365";
}
.icon-venus-mars:before {
  content: "\f366";
}
.icon-vest:before {
  content: "\f367";
}
.icon-virus-slash:before {
  content: "\f368";
}
.icon-volume-low:before {
  content: "\f369";
}
.icon-wave-triangle:before {
  content: "\f36a";
}
.icon-wheat:before {
  content: "\f36b";
}
.icon-wheelchair-move:before {
  content: "\f36c";
}
.icon-window-frame-open:before {
  content: "\f36d";
}
.icon-wine-bottle:before {
  content: "\f36e";
}
.icon-wrench:before {
  content: "\f36f";
}
.icon-x:before {
  content: "\f370";
}
.icon-ampersand:before {
  content: "\f371";
}
.icon-anchor-lock:before {
  content: "\f372";
}
.icon-arrow-down-9-1:before {
  content: "\f373";
}
.icon-arrow-down-to-arc:before {
  content: "\f374";
}
.icon-arrow-right-long-to-line:before {
  content: "\f375";
}
.icon-arrow-right-to-city:before {
  content: "\f376";
}
.icon-arrow-rotate-left:before {
  content: "\f377";
}
.icon-arrows-down-to-people:before {
  content: "\f378";
}
.icon-arrows-left-right-to-line:before {
  content: "\f379";
}
.icon-arrows-minimize:before {
  content: "\f37a";
}
.icon-arrows-to-circle:before {
  content: "\f37b";
}
.icon-arrow-turn-down-right:before {
  content: "\f37c";
}
.icon-arrow-up-from-ground-water:before {
  content: "\f37d";
}
.icon-backpack:before {
  content: "\f37e";
}
.icon-ball-pile:before {
  content: "\f37f";
}
.icon-bars-staggered:before {
  content: "\f380";
}
.icon-battery-quarter:before {
  content: "\f381";
}
.icon-battery-slash:before {
  content: "\f382";
}
.icon-bed-front:before {
  content: "\f383";
}
.icon-bell-concierge:before {
  content: "\f384";
}
.icon-bell-exclamation:before {
  content: "\f385";
}
.icon-bell-slash:before {
  content: "\f386";
}
.icon-binary-circle-check:before {
  content: "\f387";
}
.icon-binary-lock:before {
  content: "\f388";
}
.icon-bin-recycle:before {
  content: "\f389";
}
.icon-biohazard:before {
  content: "\f38a";
}
.icon-block-brick:before {
  content: "\f38b";
}
.icon-book-medical:before {
  content: "\f38c";
}
.icon-book-open-reader:before {
  content: "\f38d";
}
.icon-book-tanakh:before {
  content: "\f38e";
}
.icon-border-center-v:before {
  content: "\f38f";
}
.icon-border-inner:before {
  content: "\f390";
}
.icon-bore-hole:before {
  content: "\f391";
}
.icon-bottle-baby:before {
  content: "\f392";
}
.icon-bowl-scoop:before {
  content: "\f393";
}
.icon-bracket-square:before {
  content: "\f394";
}
.icon-brazilian-real-sign:before {
  content: "\f395";
}
.icon-bread-slice-butter:before {
  content: "\f396";
}
.icon-building:before {
  content: "\f397";
}
.icon-building-circle-exclamation:before {
  content: "\f398";
}
.icon-bullhorn:before {
  content: "\f399";
}
.icon-bullseye:before {
  content: "\f39a";
}
.icon-bullseye-arrow:before {
  content: "\f39b";
}
.icon-caduceus:before {
  content: "\f39c";
}
.icon-calendar-lines-pen:before {
  content: "\f39d";
}
.icon-calendar-minus:before {
  content: "\f39e";
}
.icon-calendar-plus:before {
  content: "\f39f";
}
.icon-calendar-star:before {
  content: "\f3a0";
}
.icon-candy:before {
  content: "\f3a1";
}
.icon-caravan:before {
  content: "\f3a2";
}
.icon-car-battery:before {
  content: "\f3a3";
}
.icon-cart-circle-arrow-up:before {
  content: "\f3a4";
}
.icon-cart-flatbed-empty:before {
  content: "\f3a5";
}
.icon-cart-xmark:before {
  content: "\f3a6";
}
.icon-certificate:before {
  content: "\f3a7";
}
.icon-chart-bar:before {
  content: "\f3a8";
}
.icon-chart-gantt:before {
  content: "\f3a9";
}
.icon-chart-pyramid:before {
  content: "\f3aa";
}
.icon-check-double:before {
  content: "\f3ab";
}
.icon-chevron-left:before {
  content: "\f3ac";
}
.icon-church:before {
  content: "\f3ad";
}
.icon-circle:before {
  content: "\f3ae";
}
.icon-circle-3:before {
  content: "\f3af";
}
.icon-circle-arrow-up-left:before {
  content: "\f3b0";
}
.icon-circle-bookmark:before {
  content: "\f3b1";
}
.icon-circle-calendar:before {
  content: "\f3b2";
}
.icon-circle-caret-right:before {
  content: "\f3b3";
}
.icon-circle-chevron-right:before {
  content: "\f3b4";
}
.icon-circle-chevron-up:before {
  content: "\f3b5";
}
.icon-circle-i:before {
  content: "\f3b6";
}
.icon-circle-microphone-lines:before {
  content: "\f3b7";
}
.icon-circle-p:before {
  content: "\f3b8";
}
.icon-circle-q:before {
  content: "\f3b9";
}
.icon-circle-u:before {
  content: "\f3ba";
}
.icon-clock-five-thirty:before {
  content: "\f3bb";
}
.icon-clock-nine-thirty:before {
  content: "\f3bc";
}
.icon-clock-one:before {
  content: "\f3bd";
}
.icon-clock-rotate-left:before {
  content: "\f3be";
}
.icon-clock-seven:before {
  content: "\f3bf";
}
.icon-clock-six-thirty:before {
  content: "\f3c0";
}
.icon-clock-three-thirty:before {
  content: "\f3c1";
}
.icon-cloud-exclamation:before {
  content: "\f3c2";
}
.icon-cloud-showers:before {
  content: "\f3c3";
}
.icon-cloud-slash:before {
  content: "\f3c4";
}
.icon-clouds-sun:before {
  content: "\f3c5";
}
.icon-code-merge:before {
  content: "\f3c6";
}
.icon-code-pull-request-draft:before {
  content: "\f3c7";
}
.icon-coin:before {
  content: "\f3c8";
}
.icon-colon:before {
  content: "\f3c9";
}
.icon-comment-middle-top:before {
  content: "\f3ca";
}
.icon-container-storage:before {
  content: "\f3cb";
}
.icon-conveyor-belt-arm:before {
  content: "\f3cc";
}
.icon-conveyor-belt-boxes:before {
  content: "\f3cd";
}
.icon-crow:before {
  content: "\f3ce";
}
.icon-deer:before {
  content: "\f3cf";
}
.icon-diagram-next:before {
  content: "\f3d0";
}
.icon-dial-low:before {
  content: "\f3d1";
}
.icon-dice-d10:before {
  content: "\f3d2";
}
.icon-dice-one:before {
  content: "\f3d3";
}
.icon-display:before {
  content: "\f3d4";
}
.icon-display-medical:before {
  content: "\f3d5";
}
.icon-dong-sign:before {
  content: "\f3d6";
}
.icon-drone-front:before {
  content: "\f3d7";
}
.icon-duck:before {
  content: "\f3d8";
}
.icon-earth-asia:before {
  content: "\f3d9";
}
.icon-envelope-circle-check:before {
  content: "\f3da";
}
.icon-ethernet:before {
  content: "\f3db";
}
.icon-face-angry-horns:before {
  content: "\f3dc";
}
.icon-face-anguished:before {
  content: "\f3dd";
}
.icon-face-grin-hearts:before {
  content: "\f3de";
}
.icon-face-grin-squint:before {
  content: "\f3df";
}
.icon-face-hand-over-mouth:before {
  content: "\f3e0";
}
.icon-face-kiss:before {
  content: "\f3e1";
}
.icon-face-laugh-beam:before {
  content: "\f3e2";
}
.icon-face-laugh-squint:before {
  content: "\f3e3";
}
.icon-face-saluting:before {
  content: "\f3e4";
}
.icon-face-sleepy:before {
  content: "\f3e5";
}
.icon-face-smile-wink:before {
  content: "\f3e6";
}
.icon-face-thinking:before {
  content: "\f3e7";
}
.icon-face-tongue-sweat:before {
  content: "\f3e8";
}
.icon-face-weary:before {
  content: "\f3e9";
}
.icon-family-pants:before {
  content: "\f3ea";
}
.icon-farm:before {
  content: "\f3eb";
}
.icon-faucet:before {
  content: "\f3ec";
}
.icon-file-circle-exclamation:before {
  content: "\f3ed";
}
.icon-file-gif:before {
  content: "\f3ee";
}
.icon-file-lines:before {
  content: "\f3ef";
}
.icon-file-vector:before {
  content: "\f3f0";
}
.icon-fire:before {
  content: "\f3f1";
}
.icon-fire-burner:before {
  content: "\f3f2";
}
.icon-fish-bones:before {
  content: "\f3f3";
}
.icon-fish-cooked:before {
  content: "\f3f4";
}
.icon-flag:before {
  content: "\f3f5";
}
.icon-flask-round-potion:before {
  content: "\f3f6";
}
.icon-flatbread:before {
  content: "\f3f7";
}
.icon-floppy-disk:before {
  content: "\f3f8";
}
.icon-flower-daffodil:before {
  content: "\f3f9";
}
.icon-flying-disc:before {
  content: "\f3fa";
}
.icon-folder-xmark:before {
  content: "\f3fb";
}
.icon-gamepad:before {
  content: "\f3fc";
}
.icon-gauge-circle-minus:before {
  content: "\f3fd";
}
.icon-gauge-min:before {
  content: "\f3fe";
}
.icon-gear-code:before {
  content: "\f3ff";
}
.icon-genderless:before {
  content: "\f400";
}
.icon-glass-water:before {
  content: "\f401";
}
.icon-globe:before {
  content: "\f402";
}
.icon-golf-flag-hole:before {
  content: "\f403";
}
.icon-graduation-cap:before {
  content: "\f404";
}
.icon-grid-4:before {
  content: "\f405";
}
.icon-grid-5:before {
  content: "\f406";
}
.icon-grid-round-4:before {
  content: "\f407";
}
.icon-group-arrows-rotate:before {
  content: "\f408";
}
.icon-guarani-sign:before {
  content: "\f409";
}
.icon-h3:before {
  content: "\f40a";
}
.icon-h6:before {
  content: "\f40b";
}
.icon-hammer-crash:before {
  content: "\f40c";
}
.icon-hand-back-fist:before {
  content: "\f40d";
}
.icon-hand-back-point-down:before {
  content: "\f40e";
}
.icon-hand-holding-circle-dollar:before {
  content: "\f40f";
}
.icon-hand-holding-heart:before {
  content: "\f410";
}
.icon-hands-bound:before {
  content: "\f411";
}
.icon-hand-scissors:before {
  content: "\f412";
}
.icon-hands-holding-child:before {
  content: "\f413";
}
.icon-head-side-goggles:before {
  content: "\f414";
}
.icon-heart-circle-minus:before {
  content: "\f415";
}
.icon-heart-circle-plus:before {
  content: "\f416";
}
.icon-hill-avalanche:before {
  content: "\f417";
}
.icon-hockey-mask:before {
  content: "\f418";
}
.icon-hockey-sticks:before {
  content: "\f419";
}
.icon-holly-berry:before {
  content: "\f41a";
}
.icon-hose:before {
  content: "\f41b";
}
.icon-house-chimney:before {
  content: "\f41c";
}
.icon-house-chimney-blank:before {
  content: "\f41d";
}
.icon-house-chimney-crack:before {
  content: "\f41e";
}
.icon-house-circle-check:before {
  content: "\f41f";
}
.icon-igloo:before {
  content: "\f420";
}
.icon-image-slash:before {
  content: "\f421";
}
.icon-inbox-out:before {
  content: "\f422";
}
.icon-knife:before {
  content: "\f423";
}
.icon-landmark:before {
  content: "\f424";
}
.icon-lari-sign:before {
  content: "\f425";
}
.icon-left-from-line:before {
  content: "\f426";
}
.icon-less-than-equal:before {
  content: "\f427";
}
.icon-lightbulb-exclamation:before {
  content: "\f428";
}
.icon-lightbulb-on:before {
  content: "\f429";
}
.icon-lighthouse:before {
  content: "\f42a";
}
.icon-lips:before {
  content: "\f42b";
}
.icon-location-pin-slash:before {
  content: "\f42c";
}
.icon-locust:before {
  content: "\f42d";
}
.icon-manat-sign:before {
  content: "\f42e";
}
.icon-map-location-dot:before {
  content: "\f42f";
}
.icon-mask:before {
  content: "\f430";
}
.icon-memo-circle-check:before {
  content: "\f431";
}
.icon-message-plus:before {
  content: "\f432";
}
.icon-mobile-screen:before {
  content: "\f433";
}
.icon-money-bill-1:before {
  content: "\f434";
}
.icon-money-bill-simple:before {
  content: "\f435";
}
.icon-money-check-dollar-pen:before {
  content: "\f436";
}
.icon-money-from-bracket:before {
  content: "\f437";
}
.icon-money-simple-from-bracket:before {
  content: "\f438";
}
.icon-mosquito-net:before {
  content: "\f439";
}
.icon-mountain:before {
  content: "\f43a";
}
.icon-mountain-city:before {
  content: "\f43b";
}
.icon-mountain-sun:before {
  content: "\f43c";
}
.icon-mustache:before {
  content: "\f43d";
}
.icon-neuter:before {
  content: "\f43e";
}
.icon-nose:before {
  content: "\f43f";
}
.icon-note:before {
  content: "\f440";
}
.icon-notes-medical:before {
  content: "\f441";
}
.icon-objects-align-bottom:before {
  content: "\f442";
}
.icon-octagon-minus:before {
  content: "\f443";
}
.icon-olive-branch:before {
  content: "\f444";
}
.icon-omega:before {
  content: "\f445";
}
.icon-paint-roller:before {
  content: "\f446";
}
.icon-palette:before {
  content: "\f447";
}
.icon-peanut:before {
  content: "\f448";
}
.icon-pen-fancy:before {
  content: "\f449";
}
.icon-pen-to-square:before {
  content: "\f44a";
}
.icon-people-line:before {
  content: "\f44b";
}
.icon-people-pulling:before {
  content: "\f44c";
}
.icon-person-breastfeeding:before {
  content: "\f44d";
}
.icon-person-chalkboard:before {
  content: "\f44e";
}
.icon-person-drowning:before {
  content: "\f44f";
}
.icon-person-pregnant:before {
  content: "\f450";
}
.icon-person-sign:before {
  content: "\f451";
}
.icon-person-swimming:before {
  content: "\f452";
}
.icon-person-to-portal:before {
  content: "\f453";
}
.icon-phone-arrow-down-left:before {
  content: "\f454";
}
.icon-phone-arrow-up-right:before {
  content: "\f455";
}
.icon-phone-office:before {
  content: "\f456";
}
.icon-phone-rotary:before {
  content: "\f457";
}
.icon-phone-xmark:before {
  content: "\f458";
}
.icon-photo-film:before {
  content: "\f459";
}
.icon-piano-keyboard:before {
  content: "\f45a";
}
.icon-pig:before {
  content: "\f45b";
}
.icon-piggy-bank:before {
  content: "\f45c";
}
.icon-pipe-valve:before {
  content: "\f45d";
}
.icon-pizza-slice:before {
  content: "\f45e";
}
.icon-plus-minus:before {
  content: "\f45f";
}
.icon-popsicle:before {
  content: "\f460";
}
.icon-print-magnifying-glass:before {
  content: "\f461";
}
.icon-radiation:before {
  content: "\f462";
}
.icon-right-to-bracket:before {
  content: "\f463";
}
.icon-road-circle-check:before {
  content: "\f464";
}
.icon-road-spikes:before {
  content: "\f465";
}
.icon-school:before {
  content: "\f466";
}
.icon-screwdriver-wrench:before {
  content: "\f467";
}
.icon-sensor-cloud:before {
  content: "\f468";
}
.icon-shapes:before {
  content: "\f469";
}
.icon-ship:before {
  content: "\f46a";
}
.icon-shop-lock:before {
  content: "\f46b";
}
.icon-shrimp:before {
  content: "\f46c";
}
.icon-signal-slash:before {
  content: "\f46d";
}
.icon-signature-lock:before {
  content: "\f46e";
}
.icon-sign-posts-wrench:before {
  content: "\f46f";
}
.icon-slash-back:before {
  content: "\f470";
}
.icon-slider:before {
  content: "\f471";
}
.icon-snowman:before {
  content: "\f472";
}
.icon-soap:before {
  content: "\f473";
}
.icon-socks:before {
  content: "\f474";
}
.icon-speakers:before {
  content: "\f475";
}
.icon-spinner:before {
  content: "\f476";
}
.icon-spinner-scale:before {
  content: "\f477";
}
.icon-square-7:before {
  content: "\f478";
}
.icon-square-a-lock:before {
  content: "\f479";
}
.icon-square-arrow-right:before {
  content: "\f47a";
}
.icon-square-m:before {
  content: "\f47b";
}
.icon-square-poll-vertical:before {
  content: "\f47c";
}
.icon-square-sliders-vertical:before {
  content: "\f47d";
}
.icon-square-star:before {
  content: "\f47e";
}
.icon-square-x:before {
  content: "\f47f";
}
.icon-squid:before {
  content: "\f480";
}
.icon-staff:before {
  content: "\f481";
}
.icon-star-of-david:before {
  content: "\f482";
}
.icon-stars:before {
  content: "\f483";
}
.icon-star-sharp-half:before {
  content: "\f484";
}
.icon-steering-wheel:before {
  content: "\f485";
}
.icon-stopwatch:before {
  content: "\f486";
}
.icon-store-slash:before {
  content: "\f487";
}
.icon-swap:before {
  content: "\f488";
}
.icon-swatchbook:before {
  content: "\f489";
}
.icon-swords-laser:before {
  content: "\f48a";
}
.icon-syringe:before {
  content: "\f48b";
}
.icon-table:before {
  content: "\f48c";
}
.icon-table-cells-lock:before {
  content: "\f48d";
}
.icon-table-tennis-paddle-ball:before {
  content: "\f48e";
}
.icon-tally:before {
  content: "\f48f";
}
.icon-tamale:before {
  content: "\f490";
}
.icon-tank-water:before {
  content: "\f491";
}
.icon-tarp:before {
  content: "\f492";
}
.icon-teeth-open:before {
  content: "\f493";
}
.icon-temperature-arrow-down:before {
  content: "\f494";
}
.icon-temperature-quarter:before {
  content: "\f495";
}
.icon-tent-arrow-turn-left:before {
  content: "\f496";
}
.icon-thought-bubble:before {
  content: "\f497";
}
.icon-ticket-perforated:before {
  content: "\f498";
}
.icon-tickets-perforated:before {
  content: "\f499";
}
.icon-timeline-arrow:before {
  content: "\f49a";
}
.icon-timer:before {
  content: "\f49b";
}
.icon-tire-pressure-warning:before {
  content: "\f49c";
}
.icon-toilet-paper-check:before {
  content: "\f49d";
}
.icon-tomato:before {
  content: "\f49e";
}
.icon-trademark:before {
  content: "\f49f";
}
.icon-traffic-light:before {
  content: "\f4a0";
}
.icon-train-tram:before {
  content: "\f4a1";
}
.icon-transporter-7:before {
  content: "\f4a2";
}
.icon-trash-arrow-up:before {
  content: "\f4a3";
}
.icon-trash-can-list:before {
  content: "\f4a4";
}
.icon-triangle:before {
  content: "\f4a5";
}
.icon-trowel:before {
  content: "\f4a6";
}
.icon-truck-field:before {
  content: "\f4a7";
}
.icon-truck-plow:before {
  content: "\f4a8";
}
.icon-truck-ramp:before {
  content: "\f4a9";
}
.icon-turntable:before {
  content: "\f4aa";
}
.icon-up-down:before {
  content: "\f4ab";
}
.icon-up-right-from-square:before {
  content: "\f4ac";
}
.icon-up-to-bracket:before {
  content: "\f4ad";
}
.icon-user-cowboy:before {
  content: "\f4ae";
}
.icon-user-doctor-message:before {
  content: "\f4af";
}
.icon-users-between-lines:before {
  content: "\f4b0";
}
.icon-user-tie-hair-long:before {
  content: "\f4b1";
}
.icon-user-xmark:before {
  content: "\f4b2";
}
.icon-van-shuttle:before {
  content: "\f4b3";
}
.icon-venus-double:before {
  content: "\f4b4";
}
.icon-vials:before {
  content: "\f4b5";
}
.icon-washing-machine:before {
  content: "\f4b6";
}
.icon-weight-hanging:before {
  content: "\f4b7";
}
.icon-whale:before {
  content: "\f4b8";
}
.icon-wheat-awn:before {
  content: "\f4b9";
}
.icon-wheat-slash:before {
  content: "\f4ba";
}
.icon-window:before {
  content: "\f4bb";
}
.icon-window-frame:before {
  content: "\f4bc";
}
.icon-wrench-simple:before {
  content: "\f4bd";
}
.icon-a:before {
  content: "\f4be";
}
.icon-address-book:before {
  content: "\f4bf";
}
.icon-arrow-down-long:before {
  content: "\f4c0";
}
.icon-arrow-down-to-square:before {
  content: "\f4c1";
}
.icon-arrow-right-to-arc:before {
  content: "\f4c2";
}
.icon-arrows-up-down-left-right:before {
  content: "\f4c3";
}
.icon-arrow-turn-down-left:before {
  content: "\f4c4";
}
.icon-arrow-turn-left-up:before {
  content: "\f4c5";
}
.icon-arrow-up-from-line:before {
  content: "\f4c6";
}
.icon-arrow-up-left:before {
  content: "\f4c7";
}
.icon-arrow-up-small-big:before {
  content: "\f4c8";
}
.icon-backward-fast:before {
  content: "\f4c9";
}
.icon-banjo:before {
  content: "\f4ca";
}
.icon-basket-shopping-plus:before {
  content: "\f4cb";
}
.icon-bed-pulse:before {
  content: "\f4cc";
}
.icon-blinds-open:before {
  content: "\f4cd";
}
.icon-book:before {
  content: "\f4ce";
}
.icon-box-ballot:before {
  content: "\f4cf";
}
.icon-bring-front:before {
  content: "\f4d0";
}
.icon-building-magnifying-glass:before {
  content: "\f4d1";
}
.icon-building-wheat:before {
  content: "\f4d2";
}
.icon-bus-simple:before {
  content: "\f4d3";
}
.icon-calendar-xmark:before {
  content: "\f4d4";
}
.icon-camera-polaroid:before {
  content: "\f4d5";
}
.icon-car-bump:before {
  content: "\f4d6";
}
.icon-cards-blank:before {
  content: "\f4d7";
}
.icon-cart-arrow-up:before {
  content: "\f4d8";
}
.icon-cauldron:before {
  content: "\f4d9";
}
.icon-chair-office:before {
  content: "\f4da";
}
.icon-chart-scatter:before {
  content: "\f4db";
}
.icon-chess-pawn-piece:before {
  content: "\f4dc";
}
.icon-chess-queen-piece:before {
  content: "\f4dd";
}
.icon-chopsticks:before {
  content: "\f4de";
}
.icon-circle-7:before {
  content: "\f4df";
}
.icon-circle-bolt:before {
  content: "\f4e0";
}
.icon-circle-dollar:before {
  content: "\f4e1";
}
.icon-circle-g:before {
  content: "\f4e2";
}
.icon-circle-h:before {
  content: "\f4e3";
}
.icon-circle-l:before {
  content: "\f4e4";
}
.icon-circle-pause:before {
  content: "\f4e5";
}
.icon-circle-up:before {
  content: "\f4e6";
}
.icon-citrus:before {
  content: "\f4e7";
}
.icon-clipboard:before {
  content: "\f4e8";
}
.icon-clock:before {
  content: "\f4e9";
}
.icon-clock-two:before {
  content: "\f4ea";
}
.icon-clothes-hanger:before {
  content: "\f4eb";
}
.icon-cloud-check:before {
  content: "\f4ec";
}
.icon-cloud-snow:before {
  content: "\f4ed";
}
.icon-code-fork:before {
  content: "\f4ee";
}
.icon-coffin-cross:before {
  content: "\f4ef";
}
.icon-comment-heart:before {
  content: "\f4f0";
}
.icon-comments:before {
  content: "\f4f1";
}
.icon-comments-dollar:before {
  content: "\f4f2";
}
.icon-computer-classic:before {
  content: "\f4f3";
}
.icon-crop-simple:before {
  content: "\f4f4";
}
.icon-cubes:before {
  content: "\f4f5";
}
.icon-desktop-arrow-down:before {
  content: "\f4f6";
}
.icon-diamond-exclamation:before {
  content: "\f4f7";
}
.icon-dice-four:before {
  content: "\f4f8";
}
.icon-display-arrow-down:before {
  content: "\f4f9";
}
.icon-distribute-spacing-horizontal:before {
  content: "\f4fa";
}
.icon-down:before {
  content: "\f4fb";
}
.icon-down-right:before {
  content: "\f4fc";
}
.icon-ear-deaf:before {
  content: "\f4fd";
}
.icon-earth-africa:before {
  content: "\f4fe";
}
.icon-envelope-dot:before {
  content: "\f4ff";
}
.icon-face-disguise:before {
  content: "\f500";
}
.icon-face-flushed:before {
  content: "\f501";
}
.icon-face-grin-tears:before {
  content: "\f502";
}
.icon-face-grin-tongue:before {
  content: "\f503";
}
.icon-face-grin-wink:before {
  content: "\f504";
}
.icon-face-pensive:before {
  content: "\f505";
}
.icon-face-smile-beam:before {
  content: "\f506";
}
.icon-falafel:before {
  content: "\f507";
}
.icon-file-arrow-up:before {
  content: "\f508";
}
.icon-file-audio:before {
  content: "\f509";
}
.icon-file-heart:before {
  content: "\f50a";
}
.icon-file-magnifying-glass:before {
  content: "\f50b";
}
.icon-file-mov:before {
  content: "\f50c";
}
.icon-file-shield:before {
  content: "\f50d";
}
.icon-file-zipper:before {
  content: "\f50e";
}
.icon-filter-list:before {
  content: "\f50f";
}
.icon-gauge-max:before {
  content: "\f510";
}
.icon-gauge-simple-max:before {
  content: "\f511";
}
.icon-gem:before {
  content: "\f512";
}
.icon-glass-empty:before {
  content: "\f513";
}
.icon-gopuram:before {
  content: "\f514";
}
.icon-grate:before {
  content: "\f515";
}
.icon-grip-dots:before {
  content: "\f516";
}
.icon-grip-dots-vertical:before {
  content: "\f517";
}
.icon-hand-holding-skull:before {
  content: "\f518";
}
.icon-handshake-slash:before {
  content: "\f519";
}
.icon-heart-pulse:before {
  content: "\f51a";
}
.icon-hill-rockslide:before {
  content: "\f51b";
}
.icon-house-medical:before {
  content: "\f51c";
}
.icon-house-medical-circle-check:before {
  content: "\f51d";
}
.icon-house-signal:before {
  content: "\f51e";
}
.icon-icicles:before {
  content: "\f51f";
}
.icon-image:before {
  content: "\f520";
}
.icon-inbox-full:before {
  content: "\f521";
}
.icon-input-text:before {
  content: "\f522";
}
.icon-kit-medical:before {
  content: "\f523";
}
.icon-lambda:before {
  content: "\f524";
}
.icon-land-mine-on:before {
  content: "\f525";
}
.icon-leaf:before {
  content: "\f526";
}
.icon-lemon:before {
  content: "\f527";
}
.icon-light-switch-off:before {
  content: "\f528";
}
.icon-mace:before {
  content: "\f529";
}
.icon-magnet:before {
  content: "\f52a";
}
.icon-magnifying-glass:before {
  content: "\f52b";
}
.icon-magnifying-glass-chart:before {
  content: "\f52c";
}
.icon-mars-stroke-up:before {
  content: "\f52d";
}
.icon-message-heart:before {
  content: "\f52e";
}
.icon-message-question:before {
  content: "\f52f";
}
.icon-meteor:before {
  content: "\f530";
}
.icon-mobile-screen-button:before {
  content: "\f531";
}
.icon-money-bill:before {
  content: "\f532";
}
.icon-money-bill-1-wave:before {
  content: "\f533";
}
.icon-monitor-waveform:before {
  content: "\f534";
}
.icon-moon-stars:before {
  content: "\f535";
}
.icon-music:before {
  content: "\f536";
}
.icon-object-intersect:before {
  content: "\f537";
}
.icon-oil-can:before {
  content: "\f538";
}
.icon-oil-can-drip:before {
  content: "\f539";
}
.icon-otter:before {
  content: "\f53a";
}
.icon-pen-line:before {
  content: "\f53b";
}
.icon-people-dress:before {
  content: "\f53c";
}
.icon-people-simple:before {
  content: "\f53d";
}
.icon-person-circle-xmark:before {
  content: "\f53e";
}
.icon-person-seat-reclined:before {
  content: "\f53f";
}
.icon-phone-intercom:before {
  content: "\f540";
}
.icon-pineapple:before {
  content: "\f541";
}
.icon-place-of-worship:before {
  content: "\f542";
}
.icon-plane-lock:before {
  content: "\f543";
}
.icon-plane-up-slash:before {
  content: "\f544";
}
.icon-plug-circle-exclamation:before {
  content: "\f545";
}
.icon-police-box:before {
  content: "\f546";
}
.icon-qrcode:before {
  content: "\f547";
}
.icon-receipt:before {
  content: "\f548";
}
.icon-road-circle-xmark:before {
  content: "\f549";
}
.icon-rupee-sign:before {
  content: "\f54a";
}
.icon-scanner-touchscreen:before {
  content: "\f54b";
}
.icon-send-backward:before {
  content: "\f54c";
}
.icon-share:before {
  content: "\f54d";
}
.icon-share-from-square:before {
  content: "\f54e";
}
.icon-shield-quartered:before {
  content: "\f54f";
}
.icon-shovel:before {
  content: "\f550";
}
.icon-sickle:before {
  content: "\f551";
}
.icon-sitemap:before {
  content: "\f552";
}
.icon-spider-black-widow:before {
  content: "\f553";
}
.icon-square-phone:before {
  content: "\f554";
}
.icon-square-user:before {
  content: "\f555";
}
.icon-stamp:before {
  content: "\f556";
}
.icon-sunrise:before {
  content: "\f557";
}
.icon-superscript:before {
  content: "\f558";
}
.icon-temperature-list:before {
  content: "\f559";
}
.icon-traffic-light-stop:before {
  content: "\f55a";
}
.icon-tree-city:before {
  content: "\f55b";
}
.icon-tree-large:before {
  content: "\f55c";
}
.icon-truck-plane:before {
  content: "\f55d";
}
.icon-umbrella-beach:before {
  content: "\f55e";
}
.icon-underline:before {
  content: "\f55f";
}
.icon-unlock-keyhole:before {
  content: "\f560";
}
.icon-usb-drive:before {
  content: "\f561";
}
.icon-user-pilot-tie:before {
  content: "\f562";
}
.icon-venus:before {
  content: "\f563";
}
.icon-virus:before {
  content: "\f564";
}
.icon-wagon-covered:before {
  content: "\f565";
}
.icon-water:before {
  content: "\f566";
}
.icon-wifi-exclamation:before {
  content: "\f567";
}
.icon-xmark:before {
  content: "\f568";
}
.icon-00:before {
  content: "\f569";
}
.icon-album-collection-circle-user:before {
  content: "\f56a";
}
.icon-apostrophe:before {
  content: "\f56b";
}
.icon-arrows-cross:before {
  content: "\f56c";
}
.icon-arrows-rotate:before {
  content: "\f56d";
}
.icon-arrow-turn-left-down:before {
  content: "\f56e";
}
.icon-arrow-up-to-line:before {
  content: "\f56f";
}
.icon-arrow-up-z-a:before {
  content: "\f570";
}
.icon-ban-smoking:before {
  content: "\f571";
}
.icon-basket-shopping:before {
  content: "\f572";
}
.icon-bell-plus:before {
  content: "\f573";
}
.icon-bell-school-slash:before {
  content: "\f574";
}
.icon-bowl-scoops:before {
  content: "\f575";
}
.icon-bridge-suspension:before {
  content: "\f576";
}
.icon-car-bolt:before {
  content: "\f577";
}
.icon-caret-left:before {
  content: "\f578";
}
.icon-cart-circle-xmark:before {
  content: "\f579";
}
.icon-cedi-sign:before {
  content: "\f57a";
}
.icon-chart-network:before {
  content: "\f57b";
}
.icon-cheese:before {
  content: "\f57c";
}
.icon-circle-arrow-down-right:before {
  content: "\f57d";
}
.icon-circle-d:before {
  content: "\f57e";
}
.icon-circle-envelope:before {
  content: "\f57f";
}
.icon-circle-phone-flip:before {
  content: "\f580";
}
.icon-circle-xmark:before {
  content: "\f581";
}
.icon-clipboard-medical:before {
  content: "\f582";
}
.icon-clock-five:before {
  content: "\f583";
}
.icon-closed-captioning:before {
  content: "\f584";
}
.icon-cloud-xmark:before {
  content: "\f585";
}
.icon-coconut:before {
  content: "\f586";
}
.icon-code-pull-request-closed:before {
  content: "\f587";
}
.icon-comment-text:before {
  content: "\f588";
}
.icon-copy:before {
  content: "\f589";
}
.icon-croissant:before {
  content: "\f58a";
}
.icon-delete-left:before {
  content: "\f58b";
}
.icon-diagram-sankey:before {
  content: "\f58c";
}
.icon-diploma:before {
  content: "\f58d";
}
.icon-display-chart-up:before {
  content: "\f58e";
}
.icon-dna:before {
  content: "\f58f";
}
.icon-draw-circle:before {
  content: "\f590";
}
.icon-drum:before {
  content: "\f591";
}
.icon-drumstick:before {
  content: "\f592";
}
.icon-eye-low-vision:before {
  content: "\f593";
}
.icon-face-confounded:before {
  content: "\f594";
}
.icon-face-frown-open:before {
  content: "\f595";
}
.icon-face-grin-beam:before {
  content: "\f596";
}
.icon-face-pouting:before {
  content: "\f597";
}
.icon-face-relieved:before {
  content: "\f598";
}
.icon-face-zany:before {
  content: "\f599";
}
.icon-file-chart-pie:before {
  content: "\f59a";
}
.icon-file-doc:before {
  content: "\f59b";
}
.icon-file-invoice:before {
  content: "\f59c";
}
.icon-file-slash:before {
  content: "\f59d";
}
.icon-file-xml:before {
  content: "\f59e";
}
.icon-futbol:before {
  content: "\f59f";
}
.icon-garlic:before {
  content: "\f5a0";
}
.icon-gauge-high:before {
  content: "\f5a1";
}
.icon-gauge-low:before {
  content: "\f5a2";
}
.icon-grid-round-5:before {
  content: "\f5a3";
}
.icon-gun-slash:before {
  content: "\f5a4";
}
.icon-hand-back-point-left:before {
  content: "\f5a5";
}
.icon-hand-lizard:before {
  content: "\f5a6";
}
.icon-hand-point-down:before {
  content: "\f5a7";
}
.icon-hexagon:before {
  content: "\f5a8";
}
.icon-horizontal-rule:before {
  content: "\f5a9";
}
.icon-key-skeleton-left-right:before {
  content: "\f5aa";
}
.icon-laptop-medical:before {
  content: "\f5ab";
}
.icon-leaf-maple:before {
  content: "\f5ac";
}
.icon-leafy-green:before {
  content: "\f5ad";
}
.icon-location-crosshairs-slash:before {
  content: "\f5ae";
}
.icon-message-slash:before {
  content: "\f5af";
}
.icon-microchip-ai:before {
  content: "\f5b0";
}
.icon-naira-sign:before {
  content: "\f5b1";
}
.icon-objects-align-center-vertical:before {
  content: "\f5b2";
}
.icon-objects-align-right:before {
  content: "\f5b3";
}
.icon-olive:before {
  content: "\f5b4";
}
.icon-p:before {
  content: "\f5b5";
}
.icon-page-caret-down:before {
  content: "\f5b6";
}
.icon-panorama:before {
  content: "\f5b7";
}
.icon-pedestal:before {
  content: "\f5b8";
}
.icon-person-carry-box:before {
  content: "\f5b9";
}
.icon-person-falling:before {
  content: "\f5ba";
}
.icon-person-walking-dashed-line-arrow-right:before {
  content: "\f5bb";
}
.icon-plane:before {
  content: "\f5bc";
}
.icon-rug:before {
  content: "\f5bd";
}
.icon-rv:before {
  content: "\f5be";
}
.icon-sack:before {
  content: "\f5bf";
}
.icon-scale-balanced:before {
  content: "\f5c0";
}
.icon-sensor-on:before {
  content: "\f5c1";
}
.icon-shirt-running:before {
  content: "\f5c2";
}
.icon-signal-strong:before {
  content: "\f5c3";
}
.icon-snowman-head:before {
  content: "\f5c4";
}
.icon-space-station-moon-construction:before {
  content: "\f5c5";
}
.icon-spade:before {
  content: "\f5c6";
}
.icon-spoon:before {
  content: "\f5c7";
}
.icon-square-caret-up:before {
  content: "\f5c8";
}
.icon-square-divide:before {
  content: "\f5c9";
}
.icon-square-ellipsis-vertical:before {
  content: "\f5ca";
}
.icon-square-fragile:before {
  content: "\f5cb";
}
.icon-square-l:before {
  content: "\f5cc";
}
.icon-square-poll-horizontal:before {
  content: "\f5cd";
}
.icon-square-up-right:before {
  content: "\f5ce";
}
.icon-starfighter:before {
  content: "\f5cf";
}
.icon-steak:before {
  content: "\f5d0";
}
.icon-suitcase-rolling:before {
  content: "\f5d1";
}
.icon-sun-cloud:before {
  content: "\f5d2";
}
.icon-table-pivot:before {
  content: "\f5d3";
}
.icon-tooth:before {
  content: "\f5d4";
}
.icon-tower-broadcast:before {
  content: "\f5d5";
}
.icon-tree-deciduous:before {
  content: "\f5d6";
}
.icon-triangle-instrument:before {
  content: "\f5d7";
}
.icon-truck:before {
  content: "\f5d8";
}
.icon-truck-front:before {
  content: "\f5d9";
}
.icon-truck-utensils:before {
  content: "\f5da";
}
.icon-turtle:before {
  content: "\f5db";
}
.icon-universal-access:before {
  content: "\f5dc";
}
.icon-up-right-and-down-left-from-center:before {
  content: "\f5dd";
}
.icon-user-nurse:before {
  content: "\f5de";
}
.icon-user-tag:before {
  content: "\f5df";
}
.icon-vial-virus:before {
  content: "\f5e0";
}
.icon-viruses:before {
  content: "\f5e1";
}
.icon-wallet:before {
  content: "\f5e2";
}
.icon-watch-smart:before {
  content: "\f5e3";
}
.icon-wave-pulse:before {
  content: "\f5e4";
}
