.swiper-pagination-bullet {
  background-color: #fff !important;
}
.swiper-pagination-bullet-active {
  transition: all 0.1s;
  background-color: #ff9052 !important;
  width: 16px !important;
  border-radius: 4px !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 32px !important;
  height: 32px !important;
  border-radius: 100%;
  background-color: #fff;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: bold !important;
}
